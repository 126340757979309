import React, { useEffect, useState } from "react";
import left from "./icons/left.svg";

export default function Pagination({ projectsLength, setPageNumber, pageNumber }) {
  let numbers = [];
  for (let i = 0; i <= projectsLength / 10; i++) {
    numbers.push(i);
  }

  const [currentIndex, setCurrentIndex] = useState(pageNumber-1);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);


  useEffect(()=>{
    if(localStorage.getItem('page')){
      setCurrentIndex(localStorage.getItem('page'));
    }
  }, [])
  useEffect(() => {
    if (currentIndex === numbers.length - 1) {
      setDisableNext(true);
      setDisablePrev(false);
    } else if (currentIndex === 0) {
      setDisablePrev(true);
      setDisableNext(false);
    } else {
      setDisablePrev(false);
      setDisableNext(false);
    }
  }, [currentIndex]);

  const handlePaginationClick = (index) => {
    setCurrentIndex(index);
    setPageNumber(index + 1);
  };

  const handleNextCLick = () => {
    setCurrentIndex((prev) => prev + 1);
    setPageNumber((prev) => prev + 1);
  };
  const handlePrevCLick = () => {
    setCurrentIndex((prev) => prev - 1);
    setPageNumber((prev) => prev - 1);
  };
  return (
    <div className="w-full px-4 py-3 flex items-center justify-between sm:px-6 mt-7 mb-16">
      <div className="flex-1 flex justify-between sm:hidden"></div>
      <div className=" flex items-center justify-center w-full">
        <div className="overflow-x-scroll">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              disabled={disablePrev}
              href="#"
              onClick={handlePrevCLick}
              className={`text-sm font-medium text-graytext hover:bg-graydivider relative inline-flex items-center px-2 py-2 rounded-r-md  bg-white ${
                disablePrev && "cursor-not-allowed"
              }`}
            >
              <span className="sr-only">Next</span>
              <img className="w-5 h-5 -rotate-90" src={left} alt="arrow" />
            </button>
            {numbers.map((element, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handlePaginationClick(index)}
                  href="#"
                  className={`relative inline-flex items-center px-4 py-2  text-sm font-medium ${
                    index === currentIndex
                      ? "z-10 bg-darkBlue bg-opacity-30 text-textblack hover:bg-darkBlue hover:bg-opacity-60"
                      : "bg-white text-graytext hover:bg-graydivider"
                  }`}
                >
                  {index + 1}
                </button>
              );
            })}
            <button
              disabled={disableNext}
              onClick={handleNextCLick}
              href="#"
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md hover:bg-graydivider bg-white text-sm font-medium text-graytext ${
                disableNext && "cursor-not-allowed"
              }`}
            >
              <span className="sr-only">Previous</span>
              <img className="w-5 h-5 rotate-90" src={left} alt="arrow" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
