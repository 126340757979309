import React, { createContext, useContext, useEffect, useState } from "react";

export const RoleProviderContext = createContext();

export const RoleProvider = ({ children }) => {
  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  let data;
  if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
    data = {
      id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
      id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
    };
  }

  const [loggedInUserInfo, setLoggedInUserInfo] = useState(null);
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = async () => {
    try {
      const response = await fetch(
        "../../nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        setLoggedInUserInfo(
          userData.filter((user) => user.kodemelli === data.id1)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <RoleProviderContext.Provider value={{ loggedInUserInfo }}>
      {children}
    </RoleProviderContext.Provider>
  );
};

export const useRole = () => useContext(RoleProviderContext);
