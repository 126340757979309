import React, { useEffect, useState } from "react";
import { convertRole } from "../General/Functions";
import Loading from "../General/Loading";
import LoadingButton from "../General/LoadingButton";
import { successNotify, warningNotify } from "../General/notifications";
import PopUp from "../General/PopUp";
// import { useAuth } from "../contexts/AuthProvider";

const SubGroup = () => {
  const [users, setUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [subGroups, setSubGroups] = useState(null);
  const [subUsersFullInfo, setSubUsersFullInfo] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [checkedSubGroups, setCheckedSubGroups] = useState([]);
  const [head, setHead] = useState(null);

  // let data = { id: "1111111111111", id1: "5" };
  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  // const LOCAL_STORAGE_AUTH_KEY_TWO = "lgn-name";
  // const { userstate, setUserState } = useAuth();
  let userNationalCode = localStorage
    .getItem(LOCAL_STORAGE_AUTH_KEY)
    .split("=")[1];
  // let id = (userstate.lgn).split("&")[0];
  let data = {
    id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
    id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
  };

  useEffect(() => {
    if (subGroups && subGroups.length) {
      const subUsers = users.filter((user) => {
        return Number(user.kodemelli) === Number(subGroups[0].sub.substr(0, 1));
      });
      setSubUsersFullInfo(subUsers);
    }
  }, [subGroups]);

  useEffect(() => {
    const getSubGroups = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apigtrel.php?code=${userNationalCode}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          let data = await response.json();
          console.log(data, "subgroups data");
          setSubGroups(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getUsers = async () => {
      try {
        const response = await fetch(
          "../../nstd_site/usapgt.php",
          {
            method: "POST",
            cash: 'no-store',
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          let userData = await response.json();
          console.log(userData, "userData");
          setUsers(userData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
    getSubGroups();
  }, []);

  const getSub = async (nationalCode) => {
    try {
      const response = await fetch(
        `../../nstd_site/apigtrel.php?code=${nationalCode}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setCheckedSubGroups(
            data[0].sub
              .split(",")
              .splice(
                0,
                data[0].sub.split(",").length - 1,
                data[0].sub.split(",").length
              )
          );
        }
        else {
          return
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditSubGroup = async (nationalCode, headName) => {
    setCheckedSubGroups([]);
    setHead({ code: nationalCode, name: headName });
    setShowPopUp((prev) => !prev);
    getSub(nationalCode);
  };

  const addSubGroup = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let data = [];
    users.forEach((user) => {
      if (formData.get(user.kodemelli) !== null) data.push(user.kodemelli);
    });
    let sub = data.join(",");
    try {
      const response = await fetch(
        `../../nstd_site/apisvrel.php?head=${head.code}&sub=${sub},`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت اعمال شد");
        setShowPopUp(false);
        getSub(head.code);
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn(false);
    }
  };

  const SubGroupContent = () => {
    return (
      <form
        onSubmit={addSubGroup}
        className="flex flex-col pr-5 items-center justify-center flex-wrap mx-5 md:mx-10"
        action=""
      >
        <div className="flex flex-wrap justify-start">
          {users && subUsersFullInfo ? (
            users.filter(user => user.naghsh === "Visitor")
            .map((user, index) => {
              return (
                <div
                  key={user.kodemelli}
                  className="flex w-[180px] md:w-[250px] items-center justify-start"
                >
                  <input
                    className="w-5 h-5"
                    name={user.kodemelli}
                    id={user.kodemelli}
                    type="checkBox"
                    defaultChecked={
                      checkedSubGroups.length
                        ? checkedSubGroups.includes(user.kodemelli)
                        : ""
                    }
                  />
                  <label className="p-2" htmlFor="subUserName">
                    {user.name}
                  </label>
                </div>
              );
            })
          ) : (
            <p>کاربری ثبت نشده است</p>
          )}
        </div>
        <LoadingButton
          title={"ذخیره"}
          Tstyle={
            "inline-flex items-center my-6 text-center justify-center max-w-[200px] px-6 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-darkBlue transition ease-in-out duration-150"
          }
          type={"submit"}
          loadingBtn={loadingBtn}
        />
      </form>
    );
  };
  return (
    <div className="mt-32 flex justify-center max-w-[1380px] mx-auto">
      {users.length ? (
        <>
          <table className="hidden w-[85%] md:inline-table">
            <thead>
              <tr className="bg-darkBlue text-white py-2 rounded-t-md">
                <td className="text-center p-2 rounded-tr-md">نام</td>
                <td className="text-center p-2">کد ملی</td>
                <td className="text-center p-2">شماره موبایل</td>
                <td className="text-center p-2">نقش</td>
                <td className="text-center p-2">نام کاربری</td>
                <td className="text-center p-2">رمز عبور</td>
                <td className="text-center p-2 rounded-tl-md"></td>
              </tr>
            </thead>
            <tbody className="bg-white">
              {users
                .filter((user) => user.naghsh === "sale")
                .map((user) => {
                  return (
                    <tr className="" key={user.kodemelli}>
                      <td className="p-2 text-center">{user.name}</td>
                      <td className="p-2 text-center">{user.kodemelli}</td>
                      <td className="p-2 text-center">{user.shemaremobile}</td>
                      <td className="p-2 text-center">
                        {convertRole(user.naghsh)}
                      </td>
                      <td className="p-2 text-center">{user.username}</td>
                      <td className="p-2 text-center">{user.passwo}</td>
                      <td className="p-2 text-center">
                        <button
                          className="rounded-md bg-lightgray bg-opacity-50 py-1 px-4"
                          onClick={() =>
                            handleEditSubGroup(user.kodemelli, user.name)
                          }
                        >
                          تنظیم زیرگروه
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="md:hidden flex flex-col w-[90%] mb-40">
            {users
              .filter((user) => user.naghsh === "sale")
              .map((user) => {
                return (
                  <div
                    key={user.kodemelli}
                    className="rounded-md bg-white mb-2"
                  >
                    <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p>نام :</p>
                      <p className="text-left w-2/3"> {user.name}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p>کدملی :</p>
                      <p className="text-left w-2/3">{user.kodemelli}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p>شماره موبایل :</p>
                      <p className="text-left w-2/3">{user.shemaremobile}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p>نقش :</p>
                      <p className="text-left w-2/3">
                        {convertRole(user.naghsh)}
                      </p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p>نام کاربری:</p>
                      <p className="text-left w-2/3">{user.username}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue  text-right flex px-2 justify-between">
                      <p>رمزعبور :</p>
                      <p className="text-left w-2/3">{user.passwo}</p>
                    </div>
                    <div className="mx-3 py-4 flex justify-end">
                      <button
                        onClick={() =>
                          handleEditSubGroup(user.kodemelli, user.name)
                        }
                        className=" rounded-md bg-lightgray bg-opacity-50 px-5 py-2"
                      >
                        تنظیم زیرگروه
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <Loading />
      )}
      <PopUp
        title={`${
          head
            ? ` زیرگروه‌های ${head && head.name} را مشخص نمائید`
            : `زیرگروه‌ها را مشخص نمائید`
        }`}
        content={<SubGroupContent />}
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
      />
    </div>
  );
};

export default SubGroup;
