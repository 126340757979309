import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import JalaliDate from "../dateConvertor";
import { successNotify, warningNotify } from "../General/notifications";
import LoadingButton from "../General/LoadingButton";

const AddAlaram = ({projectId, id1, mode, setAlarmRefresh, alarmRefresh }) => {
  console.log(mode);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedDayForAlarm, setselectedDayForAlarm] = useState(
    new DateObject()
  );

  useEffect(() => {
    let selectedDatum;
    if (selectedDayForAlarm) {
      selectedDatum =
        selectedDayForAlarm.year +
        "-" +
        selectedDayForAlarm.month.number +
        "-" +
        selectedDayForAlarm.day;
    }

    let tr = JalaliDate.jalaliToGregorian(
      selectedDayForAlarm.year,
      selectedDayForAlarm.month.number,
      selectedDayForAlarm.day
    ).join("-");
    setAlarmData((prev) => ({ ...prev, dt: tr }));
  }, [selectedDayForAlarm]);

  const [alarmData, setAlarmData] = useState({
    dt: "",
    exp: "",
    time: "",
  });

  const handleAddAlarm = async (e) => {
    let id = projectId;
    console.log(id);
    if (!alarmData.dt || !alarmData.exp || !alarmData.time) {
      warningNotify("لطفا اطلاعات آلارم را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn(true);
    try {
      const response = await fetch(
        `../../nstd_site/apisvalr.php?no=${id}&dt=${
          alarmData.dt + " " + alarmData.time
        }&exp=${alarmData.exp}&kr=${id1}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("آلارم با موفقیت ثبت شد");
        setAlarmData((prev) => ({ time: "", date: new DateObject(), exp: "" }));
        setAlarmData({ time: "", date: "", exp: "" });
        setselectedDayForAlarm(new DateObject());
        setLoadingBtn(false);
        setAlarmRefresh(!alarmRefresh)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlarmTime = (e) => {
    setAlarmData((prev) => ({ ...prev, time: e.target.value }));
  };

  const handleAlarmExp = (e) => {
    setAlarmData((prev) => ({ ...prev, exp: e.target.value }));
  };
  return (
    <div className="pt-4 border-b-4 pb-6 border-white">
      <h1>تنظیمات آلارم</h1>
      <div className="flex items-end flex-col md:flex-row">
        <div className="flex flex-col w-full md:w-1/4">
          <label className="py-4" htmlFor="datum">
            تاریخ
          </label>
          <div className=" w-full overflow-hidden flex justify-center items-center h-[42px] rounded-md bg-white">
            <DatePicker
              value={selectedDayForAlarm}
              onChange={setselectedDayForAlarm}
              calendar={persian}
              locale={persian_fa}
              calendarPosition="bottom-right"
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/4">
          <label className="py-4" htmlFor="time">
            ساعت
          </label>
          <input
            onChange={handleAlarmTime}
            name="time"
            className="md:mx-[2.5%] p-2 rounded-md"
            type="time"
            value={alarmData.time}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/4">
          <label className="py-4" htmlFor="explanation">
            توضیحات
          </label>
          <textarea
            onChange={handleAlarmExp}
            rows="1"
            name="explanation"
            className="md:ml-[2.5%] p-2 rounded-md mb-5 md:mb-0"
            type="time"
            value={alarmData.exp}
          />
        </div>
        {/* <LoadingButton
          editMode={mode}
          loadingBtn={loadingBtn}
          action={handleAddAlarm}
          type={"button"}
          title={"+ افزودن آلارم"}
          Tstyle={
            "mt-4 bg-yellow py-2 w-full md:w-1/4 h-[40px] rounded-md flex justify-center items-center"
          }
        /> */}
        <button
          disabled={loadingBtn}
          onClick={mode && handleAddAlarm}
          type="button"
          className={`${
            loadingBtn && "cursor-not-allowed bg-opacity-40"
          } "mt-4 bg-yellow py-2 w-full md:w-1/4 h-[40px] rounded-md flex justify-center items-center"
      `}
        >
          {loadingBtn ? (
            <>
              {"+ افزودن آلارم" !== "تائید مدارک" && "در حال پردازش"}
              <svg
                className="animate-spin ml-2 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </>
          ) : (
            <p>{"+ افزودن آلارم"}</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default AddAlaram;
