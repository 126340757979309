import React, { useEffect, useState } from "react";
import Loading from "./General/Loading";
import { dateChange } from "./dateConvertor";
import { checkProgress } from "./General/Functions";
import { checkColor } from "./General/Functions";
import { useNavigate } from "react-router-dom";
import { RoleProviderContext, useRole } from "./context/roleProvider";

const ProjectsTable = ({ pageNumber, projects }) => {
  const { loggedInUserInfo } = useRole();
  const [colors, setColors] = useState([]);
  const [loadedProjects, setLoadedProjects] = useState(null);
  const navigate = useNavigate();
  console.log(projects);
  // console.log(projects.slice(0, 10));
  // console.log(loadedProjects,"loadedProjects");
  useEffect(() => {
    console.log(loadedProjects, "loadedp");
  }, [loadedProjects]);

  // useEffect(() => {
  //   console.log([...projects.slice(0, 10)],"line 17");
  //   if (projects){
  //     console.log("here ejrra shod");
  //       // setLoadedProjects([...projects.slice(0, 10)])
  //       setLoadedProjects("2")
  //   }
  // },[projects])

  useEffect(() => {
    let colors = [];
    if (loadedProjects) {
      loadedProjects.forEach((project) => {
        colors.push(checkColor(project.perc));
        setColors(colors);
      });
    }
  }, [projects, loadedProjects]);

  useEffect(() => {
    //   const getProjects = async () => {
    //   try {
    //     const response = await fetch("./nstd_site/api3.php", {
    //       method: "GET",
    //     });
    //     let data = await response.json();
    //     // setProjects(data);
    //     setLoadedProjects(data.slice((pageNumber - 1) * 10, pageNumber * 10))
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // getProjects()
    if (projects) {
      console.log("fff");
      let sliced = [...projects];
      if (projects.length < 10) {
        setLoadedProjects(projects);
      } else {
        setLoadedProjects(sliced.slice((pageNumber - 1) * 10, pageNumber * 10));
      }
    }
  }, [pageNumber, projects]);

  const handleNavigateToDetails = async (id) => {
    localStorage.setItem('page', pageNumber);
    let arr=Array();
    projects.map((project, index)=>{
      arr.push(project.id1);
    });
    localStorage.setItem('pdgs', JSON.stringify(arr));
    try {
      const response = await fetch(
        `./nstd_site/apisvseen.php?kd=${loggedInUserInfo[0].kodemelli}&sn=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      // let data = await response.json();
    } catch (error) {
      console.log(error);
    }
    navigate(`/project-details/${id}`);
  };

  return (
    <>
      <table className="hidden md:inline-table rounded-md w-[96%] mx-[2%]">
        <thead className="rounded-t-md">
          <tr className="bg-darkBlue text-white font-iranYekanRegular w-full rounded-t-md">
            <th className="py-2 rounded-tr-md">ردیف</th>
            <th className="py-2">پروژه</th>
            <th className="py-2">نام ویزیتور</th>
            <th className="py-2">تاریخ شروع</th>
            <th className="py-2">وضعیت پروژه</th>
            <th className="py-2">میزان پیشرفت</th>
            <th className="py-2 rounded-tl-md"></th>
          </tr>
        </thead>
        <tbody>
          {loggedInUserInfo && loadedProjects ?
            (loggedInUserInfo[0] ? (
              !loadedProjects.length ? (
                <tr className="bg-white text-center">
                  <td className="py-3" colSpan={6}>
                    نتیجه‌ای منطبق با جستجوی شما یافت نشد
                  </td>
                </tr>
              ) : (
                loadedProjects.map((project, index) => {
                  return (
                    project.id1 ? (
                      <tr
                        className="border-b border-graytext"
                        style={{ backgroundColor: colors[index] }}
                        key={index}
                      >
                        <td className="py-2 text-center">
                          <p /*className={!loggedInUserInfo[0].seen.includes(project.id1) ? 'text-red' : 'text-black'}*/>
                            {index + 1 + (pageNumber - 1) * 10}
                          </p>
                          {!loggedInUserInfo[0].seen.includes(project.id1) ? (
                            <span className="absolute flex h-2 w-2">
                              <span className=" absolute inline-flex h-2 w-2 rounded-full bg-red opacity-75"></span>
                              <span className="animate-ping relative inline-flex rounded-full h-2 w-2 bg-red"></span>
                            </span>
                          ) : null}
                        </td>
                        <td className="py-2 text-center">{project.family}</td>
                        <td className="py-2 text-center">{project.nazer}</td>
                        <td className="py-2 text-center">
                          <p className="mx-2">{dateChange(project.date)}</p>
                          <span>{project.date && project.date.split(" ")[1]}</span>
                        </td>
                        <td className="py-2 text-center">
                          {project.level && project.level.split("$")[0].substring(0,9)+"..."}
                        </td>
                        <td className="py-2 flex justify-center">
                          {/*project.perc && */checkProgress(project.perc)}
                        </td>
                        <td className="py-2 min-w-[80px] text-center">
                          {loggedInUserInfo ? (
                            <button
                              onClick={() => handleNavigateToDetails(project.id1, localStorage.getItem('lgn').split("=")[1])}
                              className="rounded-md bg-white w-3/4 text-sm py-2 px-2"
                            >
                              جزئیات
                            </button>) : null
                          }

                        </td>
                      </tr>
                    ) : ''

                  );
                })
              )
            ) : (
              <tr>
                <td className="py-3" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            )) : null}
        </tbody>
      </table>
      <div className="md:hidden mb-4 w-[90%] mx-auto">
        {loadedProjects ? (
          !loadedProjects.length ? (
            <p className="bg-white py-2 text-center rounded-md">
              نتیجه‌ای منطبق با جستجوی شما یافت نشد
            </p>
          ) : (
            loadedProjects.map((project, index) => {
              return (
                project.id1 ? (
                  <div className="bg-white mb-4 rounded-md" key={index}>
                    <div
                      style={{ backgroundColor: colors[index] }}
                      className="rounded-t-md flex p-2"
                    >
                      <span>پروژه :</span>
                      <span>{project.family}</span>
                    </div>
                    <div className="flex p-2">
                      <span>نام ویزیتور :</span>
                      <span>{project.nazer}</span>
                    </div>
                    <div
                      style={{ backgroundColor: colors[index] }}
                      className="flex p-2"
                    >
                      <span>تاریخ شروع :</span>
                      <div className="flex justify-between">
                        <p className="mx-2">{dateChange(project.date)}</p>
                        <p>{project.date && project.date.split(" ")[1]}</p>
                      </div>
                    </div>
                    <div className="flex p-2">
                      <span>وضعیت پروژه :</span>
                      <span>{project.level && project.level.split("$")[0]}</span>
                    </div>
                    <div
                      style={{
                        backgroundColor: colors[index],
                        backgroundOpacity: "20%",
                      }}
                      className="rounded-b-md flex justify-between items-center p-2"
                    >
                      <div className="flex items-center">
                        <span>میزان پیشرفت :</span>
                        <span>{/*project.perc && */checkProgress(project.perc, "shit")}</span>
                      </div>
                      {project.id1 ? (
                        <button
                          onClick={() => handleNavigateToDetails(project.id1, localStorage.getItem('lgn').split("=")[1])}
                          className="bg-white rounded-md p-2"
                        >
                          جزئیات
                        </button>

                      ) : ''}
                    </div>
                  </div>
                ) : ''

              );
            })
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default ProjectsTable;
