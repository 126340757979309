import React from "react";
import { useState } from "react";
import LoadingButton from "./General/LoadingButton";

const Request = () => {
  const [request, setRequest] = useState({
    guarantee: true,
    addWindow: false,
    askProject: false,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const saveRequestDetails = () => {
    setLoadingBtn(true);
    setTimeout(() => {
      setLoadingBtn(false);
    }, [500]);
  };
  return (
    <>
      <div className="w-full mx-5">
        <div className="flex justify-start">
          <div
            onClick={() =>
              setRequest({
                askProject: false,
                guarantee: true,
                addWindow: false,
              })
            }
            className={`${
              request.guarantee ? "bg-darkBlue text-white duration-200" : ""
            } w-full text-center md:max-w-[100px] py-2 border rounded-tr-md border-gray hover:bg-darkBlue hover:text-white cursor-pointer transition-colors`}
          >
            گارانتی
          </div>
          <div
            onClick={() =>
              setRequest({
                askProject: false,
                guarantee: false,
                addWindow: true,
              })
            }
            className={`${
              request.addWindow ? "bg-darkBlue text-white duration-200" : ""
            } w-full md:max-w-[100px] text-center py-2 border border-gray hover:bg-darkBlue hover:text-white cursor-pointer transition-colors`}
          >
            افزودن پنجره
          </div>
          <div
            onClick={() =>
              setRequest({
                askProject: true,
                guarantee: false,
                addWindow: false,
              })
            }
            className={`${
              request.askProject ? "bg-darkBlue text-white duration-200" : ""
            } w-full md:max-w-[100px] text-center py-2 border rounded-tl-md border-gray hover:bg-darkBlue hover:text-white cursor-pointer transition-colors`}
          >
            پروژه
          </div>
        </div>
        <textarea
          className="p-5 border border-gray w-full focus:outline-none"
          placeholder={`${
            request.askProject
              ? "جزئیات مربوط به درخواست پروژه را وارد نمائید..."
              : request.guarantee
              ? "جزئیات مربوط به درخواست گارانتی را وارد نمائید..."
              : request.addWindow
              ? "جزئیات مربوط به درخواست افزودن پنجره را وارد نمائید..."
              : ""
          }`}
          name=""
          id=""
          cols="30"
          rows="5"
        ></textarea>
        <div className="flex justify-end">
          <LoadingButton
            Tstyle={
              "bg-darkBlue min-w-[150px] px-3 text-white rounded-md flex justify-center items-center  py-2"
            }
            action={saveRequestDetails}
            loadingBtn={loadingBtn}
            title={"ارسال"}
            type={"button"}
          />
        </div>
      </div>
    </>
  );
};

export default Request;
