import React, { useState } from "react";

const IntroPage = ({ show, setShow, setInformationView, informationView }) => {

    const [one, setOne] = useState(true);
    const [two, setTwo] = useState(false);
    const [three, setThree] = useState(false);

    const saveNotShowGuide = async (uId) => {
        try {
            const response = await fetch(
                `./nstd_site/apisvntshguide.php?i=${uId}`,
                {
                    method: "GET",
                    cash: 'no-store',
                }
            );
            if (response.ok) {
                let userData = await response.json();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const firstButton = (e) => {
        setOne(false);
        setTwo(true);
    }
    const firstButtonBack = (e) => {
        setOne(true);
        setTwo(false);
    }
    const secondButton = (e) => {
        setTwo(false);
        setThree(true);
    }
    const secondButtonMobile = (e) => {
        window.scrollTo(0, 200);
        setTwo(false);
        setThree(true);
    }
    const secondButtonBack = (e) => {
        setTwo(true);
        setThree(false);
    }
    const thirdButton = (e) => {
        setThree(false);
        setShow(false);
        setInformationView(false);
        closeTip();
    }
    const closeTip = () => {
        setShow(false);
        setInformationView(false);
        saveNotShowGuide(localStorage.getItem('lgn').split("=")[1]);
        console.log(show, informationView, '********');
        if(informationView==='true'){
            window.location.reload();
        }
    }
    return (
        informationView&&show ? (
            <div className="fixed bg-black left-0 top-0 w-full h-full opacity-80 z-[501]">
                {
                    one ? (
                        <>
                            <div
                                className="absolute bg-white left-[12px] top-[12px] h-12 w-12 rounded p-2  text-center border-4 border-red border-double bounce-top"
                            >
                            </div>
                            <div className="opacity-100 absolute bg-teal-200-accent left-[60px] top-[100px] rounded p-2 border text-center border-2 border-red border-double">
                                <span className="block">
                                    برای دسترسی به امکانات برنامه از این دکمه اقدام کنید.
                                </span>
                                <span className="block">
                                    منوی برنامه اینجاست
                                </span>
                                <div className="text-right mt-2">
                                    <button className="bg-blue p-2 text-white rounded" onClick={firstButton}>
                                        بعدی
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    two ? (
                        <>
                            <div
                                className="absolute bg-white right-[12px] top-[65px] h-10 w-10 rounded p-2  text-center border-4 border-red border-double bounce-top"
                            >
                            </div>
                            <div className="absolute bg-teal-200-accent right-[60px] top-[100px] rounded p-2 border text-center border-2 border-red border-double">
                                <span className="block">
                                    برای دسترسی به چت ها، تیکت ها و آلارم ها
                                </span>
                                <span className="block">
                                    از این قسمت اقدام کنید.
                                </span>
                                <div className="flex justify-between mt-2">
                                    <button className="hidden md:block bg-blue p-2 text-white rounded" onClick={secondButton}>
                                        بعدی
                                    </button>
                                    <button className="md:hidden bg-blue p-2 text-white rounded" onClick={secondButtonMobile}>
                                        بعدی
                                    </button>
                                    <button className="bg-yellow p-2 text-black rounded" onClick={firstButtonBack}>
                                        قبلی
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    three ? (
                        <>
                            <div
                                className="hidden md:block opacity-10 absolute bg-white left-[17.4%] top-[475px] h-10 w-[155px] rounded p-2  text-center border-4 border-red border-double  bounce-top"
                            >
                            </div>
                            <div
                                className="md:hidden opacity-10 absolute bg-white left-[20px] top-[710px] h-10 w-[80px] rounded p-2  text-center border-4 border-red border-double bounce-top"
                            >
                            </div>
                            <div className="hidden md:block absolute bg-teal-200-accent right-[40%] top-[50%] rounded p-2 border text-center border-2 border-red border-double">
                                <span className="block">
                                    در این قسمت می تونید پروژه هاتون رو ببینید
                                </span>
                                <span className="block">
                                    و بازدن دکمه جزئیات اطلاعات دقیق آنرا ببینید
                                </span>
                                <div className="flex justify-between mt-2">
                                    <button className="bg-blue p-2 text-white rounded" onClick={thirdButton}>
                                        بستن
                                    </button>
                                    <button className="bg-yellow p-2 text-black rounded" onClick={secondButtonBack}>
                                        قبلی
                                    </button>
                                </div>
                            </div>
                            <div className="md:hidden absolute bg-teal-200-accent right-[10%] top-[40%] rounded p-2 border text-center border-2 border-red border-double">
                                <span className="block">
                                    در این قسمت می تونید پروژه هاتون رو ببینید
                                </span>
                                <span className="block">
                                    و بازدن دکمه جزئیات اطلاعات دقیق آنرا ببینید
                                </span>
                                <div className="flex justify-between mt-2">
                                    <button className="bg-blue p-2 text-white rounded" onClick={thirdButton}>
                                        بستن
                                    </button>
                                    <button className="bg-yellow p-2 text-black rounded" onClick={secondButtonBack}>
                                        قبلی
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null
                }
            </div>
        ) : null

    );
};
export default IntroPage;
