import React, { useEffect, useState } from "react";
import Loading from "../General/Loading";
import del from "../icons/Delete.svg";
import { successNotify } from "../General/notifications";
import Modal from "../General/Modal";
import { useNavigate } from "react-router-dom";
import { dateChange } from "../dateConvertor";

const ProjectAlarms = ({pid, alarmRefresh}) => {
  const [alarms, setAlarms] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [oldAlarms, setOldAlarms] = useState(null);
  const [showOld, setShowOld] = useState(null);

  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  const navigate = useNavigate();
  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  useEffect(()=>{
    console.log('the alarmrefresh worked properly.');
    setAlarms(null);
    setOldAlarms(null);
    getAlarms();
  }, [alarmRefresh])

  const getAlarms = async () => {
    let data = {
      id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
      id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
    };
    try {
      const response = await fetch(
        `../../nstd_site/apigtalprj.php?id=${data.id}&id1=${data.id1}&pid=${pid && pid}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let mydata = await response.json();
      console.log(response);
      console.log(mydata, 'the alarm data');
      mydata.map((datum, index) => {
        console.log(dateDiffInDays(new Date(), new Date(datum.target_date.split(" ")[0])) > -5, 'the subtract');
      })
      console.log(mydata.filter((datum) => new Date() - new Date(datum.target_date.split(" ")[0]) / (1000 * 60 * 60 * 24) > 5), 'the alarm data2');

      setAlarms(mydata.filter((datum) => dateDiffInDays(new Date(), new Date(datum.target_date.split(" ")[0])) > -5));

      // setAlarms(mydata);
      setOldAlarms(mydata.filter((datum) => dateDiffInDays(new Date(), new Date(datum.target_date.split(" ")[0])) < -5));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAlarms();
  }, []);

  const deleteAlarm = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apidlal.php?id=${idForDelete}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت حذف شد");
        setOpenModal(false);
        getAlarms();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteAlarm = (id) => {
    setOpenModal(true);
    setIdForDelete(id);
  };
  const handleNavigateToDetails = async (id) => {
    localStorage.setItem('page', '1');
    let user = localStorage.getItem('lgn').split("=")[1];
    try {
      const response = await fetch(
        `../../nstd_site/apisvseen.php?kd=${user}&sn=${id}`,
        {
          method: "GET",
          cash: 'no-store',

        }
      );
      // let data = await response.json();
    } catch (error) {
      console.log(error);
    }
    navigate(`/project-details/${id}`);
  };
  return (
    <>
      {
        showOld ? (
          <>
            <button onClick={(e) => setShowOld(null)} className="mt-24 bg-black text-white rounded p-2 text-center">آلارم ها جاری</button>
            <div className="mt-8 flex justify-center max-w-[1380px] mx-auto">
              {oldAlarms ? (
                oldAlarms.length ? (
                  <>
                    <table className="hidden md:inline-table w-[85%]">
                      <thead>
                        <tr className="bg-darkBlue text-white py-2 rounded-t-md">
                          <td className="text-center p-2 rounded-tr-md">شناسه</td>
                          <td className="text-center p-2">شماره پروژه</td>
                          <td className="text-center p-2">تاریخ و ساعت آلارم</td>
                          <td className="text-center p-2">تاریخ و ساعت ثبت آلارم</td>
                          <td className="text-center p-2">توضیحات</td>
                          <td className="text-center p-2 rounded-tl-md"></td>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {oldAlarms.map((alarm) => {
                          return (
                            <tr className="" key={alarm.id1}>
                              <td className="p-2 text-center">{alarm.id1}</td>
                              <td className="p-2 text-center cursor-pointer" onClick={() => handleNavigateToDetails(alarm.project_id, localStorage.getItem('lgn').split("=")[1])}>{alarm.project_id}</td>
                              <td className="p-2 text-center">
                                <p className="mx-2">
                                  {dateChange(alarm.target_date)}
                                </p>
                                <p>{alarm.target_date.split(" ")[1]}</p>
                              </td>
                              <td className="p-2 text-center">
                                <p className="mx-2">{dateChange(alarm._date)}</p>
                                <p>{alarm._date.split(" ")[1]}</p>
                              </td>
                              <td className="p-2 text-center">
                                <p className="bg-gray">ثبت شده توسط {alarm.name}: </p>
                                {alarm._explanation}
                              </td>
                              <td
                                onClick={() => handleDeleteAlarm(alarm.id1)}
                                className="p-2 text-center cursor-pointer"
                              >
                                <img src={del} alt="delete" />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                      {oldAlarms.map((alarm, index) => {
                        return (
                          <div key={alarm.id1} className="rounded-md bg-white mb-3">
                            <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>شناسه :</p>
                              <p className="text-left w-2/3">{alarm.id1}</p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>شماره پروژه :</p>
                              <p className="text-left w-2/3" onClick={() => handleNavigateToDetails(alarm.project_id, localStorage.getItem('lgn').split("=")[1])}>
                                {alarm.project_id}
                              </p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>تاریخ و ساعت آلارم :</p>
                              <div className="text-left w-2/3">
                                <p>{dateChange(alarm.target_date)}</p>
                                <p>{alarm.target_date.split(" ")[1]}</p>
                              </div>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>تاریخ و ساعت ثبت آلارم :</p>
                              <div className="text-left w-2/3">
                                <p>{dateChange(alarm._date)}</p>
                                <p>{alarm._date.split(" ")[1]}</p>
                              </div>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>ثبت شده توسط :</p>
                              <p className="text-left w-2/3">{alarm.name}</p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>توضیحات :</p>
                              <p className="text-left w-2/3">{alarm._explanation}</p>
                            </div>
                            <div className="mx-3 py-4 flex justify-end">
                              <button
                                onClick={() => handleDeleteAlarm(alarm.id1)}
                                className=" rounded-md bg-lightgray bg-opacity-50 px-5 py-2"
                              >
                                حذف
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <p className="text-center">آلارمی برای نمایش وجود ندارد</p>
                )
              ) : (
                <Loading />
              )}
            </div></>
        ) : (
          <>
            {
              localStorage.getItem('lgn').split('&')[0] === '1111111111111' ? (
                <button onClick={(e) => setShowOld(1)} className="mt-24 bg-black text-white rounded p-2 text-center">آلارم ها منقضی شده</button>
              ) : null
            }
            <div className="mt-8 flex justify-center max-w-[1380px] mx-auto">
              {alarms ? (
                alarms.length ? (
                  <>
                    <table className="hidden md:inline-table w-[85%]">
                      <thead>
                        <tr className="bg-darkBlue text-white py-2 rounded-t-md">
                          <td className="text-center p-2 rounded-tr-md">شناسه</td>
                          <td className="text-center p-2">شماره پروژه</td>
                          <td className="text-center p-2">تاریخ و ساعت آلارم</td>
                          <td className="text-center p-2">تاریخ و ساعت ثبت آلارم</td>
                          <td className="text-center p-2">توضیحات</td>
                          <td className="text-center p-2 rounded-tl-md"></td>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {alarms.map((alarm) => {
                          return (
                            <tr className="" key={alarm.id1}>
                              <td className="p-2 text-center">{alarm.id1}</td>
                              <td className="p-2 text-center cursor-pointer" onClick={() => handleNavigateToDetails(alarm.project_id, localStorage.getItem('lgn').split("=")[1])}>{alarm.project_id}</td>
                              <td className="p-2 text-center">
                                <p className="mx-2">
                                  {dateChange(alarm.target_date)}
                                </p>
                                <p>{alarm.target_date.split(" ")[1]}</p>
                              </td>
                              <td className="p-2 text-center">
                                <p className="mx-2">{dateChange(alarm._date)}</p>
                                <p>{alarm._date.split(" ")[1]}</p>
                              </td>
                              <td className="p-2 text-center">
                                <p className="bg-gray">ثبت شده توسط {alarm.name}: </p>
                                {alarm._explanation}
                              </td>
                              <td
                                onClick={() => handleDeleteAlarm(alarm.id1)}
                                className="p-2 text-center cursor-pointer"
                              >
                                <img src={del} alt="delete" />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                      {alarms.map((alarm, index) => {
                        return (
                          <div key={alarm.id1} className="rounded-md bg-white mb-3">
                            <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>شناسه :</p>
                              <p className="text-left w-2/3">{alarm.id1}</p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>شماره پروژه :</p>
                              <p className="text-left w-2/3" onClick={() => handleNavigateToDetails(alarm.project_id, localStorage.getItem('lgn').split("=")[1])}>
                                {alarm.project_id}
                              </p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>تاریخ و ساعت آلارم :</p>
                              <div className="text-left w-2/3">
                                <p>{dateChange(alarm.target_date)}</p>
                                <p>{alarm.target_date.split(" ")[1]}</p>
                              </div>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>تاریخ و ساعت ثبت آلارم :</p>
                              <div className="text-left w-2/3">
                                <p>{dateChange(alarm._date)}</p>
                                <p>{alarm._date.split(" ")[1]}</p>
                              </div>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>ثبت شده توسط :</p>
                              <p className="text-left w-2/3">{alarm.name}</p>
                            </div>
                            <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                              <p>توضیحات :</p>
                              <p className="text-left w-2/3">{alarm._explanation}</p>
                            </div>
                            <div className="mx-3 py-4 flex justify-end">
                              <button
                                onClick={() => handleDeleteAlarm(alarm.id1)}
                                className=" rounded-md bg-lightgray bg-opacity-50 px-5 py-2"
                              >
                                حذف
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <p className="text-center">آلارمی برای نمایش وجود ندارد</p>
                )
              ) : (
                <Loading />
              )}
            </div></>
        )
      }

      {openModal && (
        <Modal
          title={"حذف آلارم"}
          description={"آیا از حذف این آلارم اطمینان دارید؟"}
          deleteAction={deleteAlarm}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default ProjectAlarms;
