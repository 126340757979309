import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./General/ScrollToTop";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./General/ProtectedRoute";
import { AuthProvider } from "./contexts/AuthProvider";
import AppLogout from "./General/AppLogout";
import { RoleProvider } from "./context/roleProvider";

const Layout = ({ children }) => {
  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  const LOCAL_STORAGE_AUTH_KEY_TWO = "lgn-name";
  return (
    <>
      <ProtectedRoute
        authenticated={
          localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) &&
          localStorage.getItem(LOCAL_STORAGE_AUTH_KEY_TWO)
        }
      >
        <RoleProvider>
          <AppLogout>
            <AuthProvider>
              <Header />
              <ScrollToTop />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {children}
              <Footer />
            </AuthProvider>
          </AppLogout>
        </RoleProvider>
      </ProtectedRoute>
    </>
  );
};
export default Layout;
