import React, { useEffect, useState } from 'react';
import UserProfile from './UserProfile';
import newThing from '../icons/icons8_create_document.svg';

function UserList({ setUserSelected, setOpenModal3 }) {
  const users = [
    {
      id: '1',
      name: 'Bob',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      status: 'Online',
    },
    {
      id: '2',
      name: 'Alice',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      status: 'Away',
    },
    {
      id: '3',
      name: 'John',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      status: 'Offline',
    },
  ];
  const [users2, setUsers2] = useState(null);
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apigtusch.php`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let data = await response.json();
        let arr = Array();
        data.map((datum, index) => {
          let newVal = '';
          if (datum.label.split("*")[1] === 'installer') {
            newVal = '(نصاب)';
          }
          else if (datum.label.split("*")[1] === 'transport') {
            newVal = '(مسئول حمل)';
          }
          else if (datum.label.split("*")[1] === 'Visitor') {
            newVal = '(ویزیتور)';
          }
          else if (datum.label.split("*")[1] === 'accountant') {
            newVal = '(مالی)';
          }
          else if (datum.label.split("*")[1] === 'sale') {
            newVal = '(مدیر فروش)';
          }
          else if (datum.label.split("*")[1] === 'qc') {
            newVal = '(کنترل کیفی)';
          }
          else if (datum.label.split("*")[1] === 'product_manager') {
            newVal = '(مدیر تولید)';
          }
          else if (datum.label.split("*")[1] === 'coord') {
            newVal = '(هماهنگی)';
          }
          else if (datum.label.split("*")[1] === 'Manager') {
            newVal = '(مدیر)';
          }
          arr.push({
            'value': datum.value,
            'id': datum.value,
            'name': datum.label.split("*")[0] + " " + newVal,
            'avatar': 'https://randomuser.me/api/portraits/men/1.jpg',
            'status': 'Offline',
          }
          )
        })
        setUsers2(arr.filter((item) => item.value !== localStorage.getItem('lgn').split('=')[1]));
        // console.log(arr.filter((item) => item.value !== localStorage.getItem('lgn').split('=')[1]), 'all the user received');
      } catch (error) {
        console.log(error);
      }
    }
    getUsers();
  }, [])
  return (
    <div>
      {
        users2 ? (
          users2.map((user1, index) => {
            return (
              <UserProfile
                idkey={user1.value}
                name={user1.name}
                avatar={user1.avatar}
                status={user1.status}
                setUserSelected={setUserSelected}
                setOpenModal3={setOpenModal3}
              />
            )
          })
        ) : ''
      }
    </div>
  );
}

export default UserList;
