import './DrawingCanvas.css';
import { useEffect, useRef, useState } from 'react';
var offsetX;
var offsetY;
const DrawingCanvas = ({ setFiles }) => {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const [isDrawing, setIsDrawing] = useState(false);

    // 2 of function adding
    const ongoingTouches = [];

    const handleStart = (evt) => {
        evt.preventDefault();
        const touches = evt.changedTouches;
        const { offsetX, offsetY } = evt;
        // offsetX = canvasRef.getBoundingClientRect().left;
        // offsetY = canvasRef.getBoundingClientRect().top;
        console.log(offsetX, offsetY)
        for (let i = 0; i < touches.length; i++) {
            ongoingTouches.push(copyTouch(touches[i]));
        }
    }

    const handleMove = (evt) => {
        evt.preventDefault();
        const touches = evt.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const color = '#fff';
            const idx = ongoingTouchIndexById(touches[i].identifier);
            if (idx >= 0) {
                canvasRef.beginPath();
                canvasRef.moveTo(ongoingTouches[idx].clientX - offsetX, ongoingTouches[idx].clientY - offsetY);
                canvasRef.lineTo(touches[i].clientX - offsetX, touches[i].clientY - offsetY);
                canvasRef.lineWidth = '10';
                canvasRef.strokeStyle = color;
                canvasRef.lineJoin = "round";
                canvasRef.closePath();
                canvasRef.stroke();
                ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
            }
        }
    }

    const handleEnd = (evt) => {
        evt.preventDefault();
        const touches = evt.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const color = '#000000';
            let idx = ongoingTouchIndexById(touches[i].identifier);
            if (idx >= 0) {
                canvasRef.lineWidth = '10';
                canvasRef.fillStyle = color;
                ongoingTouches.splice(idx, 1);  // remove it; we're done
            }
        }
    }

    const handleCancel = (evt) => {
        evt.preventDefault();
        const touches = evt.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            let idx = ongoingTouchIndexById(touches[i].identifier);
            ongoingTouches.splice(idx, 1);  // remove it; we're done
        }
    }

    const copyTouch = ({ identifier, clientX, clientY }) => {
        return { identifier, clientX, clientY };
    }

    const ongoingTouchIndexById = (idToFind) => {
        for (let i = 0; i < ongoingTouches.length; i++) {
            const id = ongoingTouches[i].identifier;
            if (id === idToFind) {
                return i;
            }
        }
        return -1;    // not found
    }

    const clearArea = () => {
        canvasRef.setTransform(1, 0, 0, 1, 0, 0);
        canvasRef.clearRect(0, 0, canvasRef.canvas.width, canvasRef.canvas.height);
    }
    // end of 2
    useEffect(() => {
        const canvas = canvasRef.current;
        // canvas.width = 500;
        // canvas.height = 500;

        const context = canvas.getContext("2d");
        context.lineCap = "round";
        context.strokeStyle = "black";
        context.lineWidth = 5;
        contextRef.current = context;
    }, []);

    const startDrawing = ({ nativeEvent }) => {
        nativeEvent.preventDefault();
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
        setIsDrawing(true);
    };

    const draw = ({ nativeEvent }) => {
        nativeEvent.preventDefault();
        if (!isDrawing) {
            return;
        }

        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const stopDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };
    const getTouchPos = (canvasDom, touchEvent) => {
        var rect = canvasDom.getBoundingClientRect();
        return {
            x: touchEvent.touches[0].clientX - rect.left,
            y: touchEvent.touches[0].clientY - rect.top
        };
    }
    const startDrawingTouch = ({ nativeEvent }) => {
        // nativeEvent.preventDefault();
        console.log(nativeEvent, 'the native event');
        setIsDrawing(true);

        const canvas = canvasRef.current;
        // const { offsetX, offsetY } = nativeEvent;
        const offsetX = nativeEvent.targetTouches[0].pageX;
        const offsetY = nativeEvent.targetTouches[0].pageY;
        console.log(offsetX, 'the x offset');
        var mouseEvent = new MouseEvent("mousedown", {
            clientX: offsetX,
            clientY: offsetY
        });
        canvas.dispatchEvent(mouseEvent);

        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const drawTouch = ({ nativeEvent }) => {
        nativeEvent.preventDefault();
        const canvas = canvasRef.current;
        if (!isDrawing) {
            return;
        }

        const offsetX = nativeEvent.targetTouches[0].pageX;
        const offsetY = nativeEvent.targetTouches[0].pageY;
        contextRef.lineTo(offsetX, offsetY);
        // var mouseEvent = new MouseEvent("mousemove", {
        //     clientX: offsetX,
        //     clientY: offsetY
        // });
        // canvas.dispatchEvent(mouseEvent);


        // contextRef.current.lineTo(offsetX, offsetY);
        // contextRef.current.stroke();
    };

    const stopDrawingTouch = () => {
        const canvas = canvasRef.current;
        var mouseEvent = new MouseEvent("mouseup", {});
        canvas.dispatchEvent(mouseEvent);
        // contextRef.current.closePath();
        setIsDrawing(false);
    };

    const setToDraw = () => {
        contextRef.current.globalCompositeOperation = 'source-over';
    };

    const setToErase = () => {
        // const context = contextRef.getContext('2d');
        // context.clearRect(0, 0, contextRef.width, contextRef.height);

        contextRef.current.globalCompositeOperation = 'destination-out';
    };

    const saveImageToLocal = (event) => {
        setFiles(null);
        // let link = event.currentTarget;
        // link.setAttribute('download', 'canvas.png');
        // let image = canvasRef.current.toDataURL('image/png');
        // link.setAttribute('href', image);
        let image = canvasRef.current.toDataURL('image/png');
        setFiles([image]);
    };

    return (
        <div className='verflow-y-hidden'>
            <canvas className="canvas-container w-full h-[50%]"
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                onTouchStart={handleStart}
                onTouchEnd={handleEnd}
                onTouchCancel={handleCancel}
                onTouchMove={handleMove}
            >
            </canvas>
            <div>
                <button className='bg-blue m-4 p-2' onClick={setToDraw}>
                    رسم
                </button>
                <button className='bg-red m-4 p-2' onClick={setToErase}>
                    پاک کردن
                </button>
                <button className='bg-green m-4 p-2' onClick={saveImageToLocal}>درج در پروژه</button>
            </div>
        </div>
    )
}

export default DrawingCanvas;