import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import ChatMessage from './ChatMessage';

const ChatMessages = ({ chat, userSelected, sendNew, setSendNew }) => {
  const bottomRef = useRef(null);
  const [count, setCounter] = useState(2);
  useEffect(() => {
    // console.log(chat, 'message arrived');
    if (count === 0) {
      bottomRef.current?.scrollIntoView();
    } else if (count === 1) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // setInterval(() => {
    setCounter(2);
    // }, 300);
  }, [chat])
  useEffect(() => {
    setCounter(0);
  }, [userSelected])
  useEffect(()=>{
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      setSendNew(null);
      console.log('send new exec');
    }, 2000);
  }, [sendNew])
  const handleScroll = event => {
    // setScrollTop(event.currentTarget.scrollTop);
    console.log('scroll detected');
    setCounter(2);
  };
  return (
    <div className="flex-1 h-[300px] overflow-y-scroll scrollbar-hide flex-grow mx-1 mt-20" onScroll={handleScroll}>
      {chat.messages.map((msg) => (
        <ChatMessage key={msg.id} message={msg} />
      ))}
      <div ref={bottomRef} />
    </div>
  );
}

export default ChatMessages;