import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, faSmile } from '@fortawesome/free-solid-svg-icons'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
// import { Smile } from 'react-feather';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import FileUploadDialog from '../FileUploadDialog';


const ChatInput = ({ setText, chat, user, changeText, sendChat, text, setSendNew, sendNew }) => {
  const [message, setMessage] = React.useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showFileDialog, setShowploadDialog] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
  }
  const addEmoji = (emoji) => {
    setText(text + emoji.native);
    setShowEmojiPicker(false);
  }
  const handleInput = (e) => {
    const { value, name } = e.target;
    console.log(value, name, 'handle input');
  }
  const sendd = () => {
    setSendNew(true);
    sendChat();
    // alert('1');
  }
  const handleKeyPress = (e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendd();
    }
  })


  return (
    <div className="py-3 px-4 bg-telegramInput border-t border-gray-300 mb-2">
      {showEmojiPicker ? (
        <div className='flex text-center m-4'>
          <Picker data={data} onEmojiSelect={addEmoji} />
        </div>
      ) : null}
      {showFileDialog ? (
        <FileUploadDialog
          showDialog={showFileDialog}
          setShowDialog={setShowploadDialog}
          setText={setText}
          sendChat={sendChat}
        // fileMessage={fileMessage}
        // handleInput={this.handleInput}
        // uploadAttachment={this.uploadAttachment}
        // fileAttachment={this.fileAttachment}
        />
      ) : null}
      <form onSubmit={sendMessage} className="flex items-center">

        <div className="w-full rounded overflow-hidden bg-gray-100 flex-1 mr-3">
          {/* <input type="text"
            value={text}
            onChange={changeText}
            placeholder="پیام خود را تایپ کنید ...."
            className="w-full py-2 px-4 bg-gray-100 focus:outline-none focus:bg-white" /> */}
          <ReactTextareaAutocomplete
            className="message-input my-textarea"
            name="newMessage"
            value={text}
            loadingComponent={() => <span>Loading</span>}
            onKeyPress={handleKeyPress}
            onChange={(e) => { changeText(e) }}
            placeholder="پیام خود را تایپ کنید ...."
            trigger={{}}
          />
        </div>
        <button onClick={() => { sendd() }} type="submit" className="text-telegramText flex items-center justify-center bg-telegramBack hover:bg-green rounded-full px-4 py-2 mr-1">
          ارسال
        </button>
        <button onClick={(e) => { e.preventDefault(); setShowEmojiPicker(!showEmojiPicker) }} className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full px-2 py-2 ml-3">
          <FontAwesomeIcon icon={faSmile} className="text-telegramText" />
        </button>
        <button className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full py-2 ml-3">
          <FontAwesomeIcon onLostPointerCapture={() => { setShowploadDialog(!showFileDialog) }} icon={faPaperclip} className="text-telegramText" />
        </button>
      </form>
    </div>
  );
}

export default ChatInput;