import React, { useEffect, useState } from "react";
const ProgressCh = ({ percent }) => {
  const [dashOffstet, setDashOffset] = useState(440 - (440 * percent) / 300);

  useEffect(() => {
    setDashOffset(440 - (440 * percent) / 300);
  }, [percent]);

  return (
    <>
      <div className="relative w-[60px] h-[60px] flex justify-center items-center flex-col">
        <span className="relative w-[60px] h-[60px]">
          <svg className="relative w-[60px] h-[60px]">
            <circle
              className="w-[60px] h-[60px] translate-x-[5px] translate-y-[5px]"
              cx="23"
              cy="23"
              fill="none"
              strokeWidth={5}
              stroke=""
              strokeDasharray={440}
              strokeDashoffset={0}
              r="23"
            ></circle>
            <circle
              className="w-[60px] h-[60px] translate-x-[5px] translate-y-[5px]"
              cx="23"
              cy="23"
              fill="white"
              strokeWidth={5}
              stroke="darkBlue"
              strokeDasharray={440}
              strokeDashoffset={dashOffstet}
              r="23"
              strokeLinecap="round"
            ></circle>
          </svg>
          <span className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <h2>{percent}%</h2>
          </span>
        </span>
      </div>
    </>
  );
};

export default ProgressCh;
