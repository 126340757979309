import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useNavigate } from "react-router-dom";
import JalaliDate from "./dateConvertor";
import LoadingButton from "./General/LoadingButton";
import { optionsTwo } from "./General/Config";
import Pagination from "./Pagination";
import ProjectsTable from "./ProjectsTable";
import Loading from "./General/Loading";
import { useRole } from "./context/roleProvider";
import { dateChange } from "./dateConvertor";
import CountDown from "./pDetails/CountDown";

const History = () => {
    const { loggedInUserInfo } = useRole();
    loggedInUserInfo && console.log(loggedInUserInfo[0].naghsh);
    const navigate = useNavigate();
    const [historyList, setHistoryList] = useState([]);
    const [date, setDate] = useState(new Date());
    const [addit, setAddit] = useState(0);

    const LOCAL_STORAGE_AUTH_KEY = "lgn";
    var SERVER_URL_GETPRJSRCH = "./nstd_site/api7.php?id=";


    useEffect(() => {
        const getCallList = async () => {
            let separator = '-';
            let newDate = new Date();
            setDate(newDate);
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            try {
                const response = await fetch(`./nstd_site/apigthst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                    method: "GET",
                    cash: 'no-store',
                });
                let data = await response.json();
                setHistoryList(data);
            } catch (error) {
                console.log(error);
            }
        }
        getCallList();
    }, [])

    const previousDay = async (a) => {
        let separator = '-';
        let newDate = new Date(new Date().getTime() + (a * 24 * 60 * 60 * 1000));
        setDate(newDate);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        try {
            const response = await fetch(`./nstd_site/apigthst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                method: "GET",
                cash: 'no-store',
            });
            let data = await response.json();
            setHistoryList(data);
        } catch (error) {
            console.log(error);
        }
    }

    const nextDay = async (b) => {
        let separator = '-';
        let newDate = new Date(new Date().getTime() + (b * 24 * 60 * 60 * 1000));
        setDate(newDate);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        try {
            const response = await fetch(`./nstd_site/apigthst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                method: "GET",
                cash: 'no-store',
            });
            let data = await response.json();
            setHistoryList(data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
            <div className="flex justify-center">
                <button className="rounded bg-white m-4 p-2" onClick={() => {
                    let a = addit - 1;
                    setAddit(addit - 1);
                    previousDay(a);
                }}>روز قبل</button>
                {date ? (<span className="font-bold mt-6">{dateChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)}</span>) : ''}
                <button className="rounded bg-white m-4 p-2" onClick={() => {
                    let b = addit + 1;
                    setAddit(addit + 1);
                    nextDay(b);
                }}>روز بعد</button>
            </div>
            {historyList ? (
                historyList.map((hist, index) => {
                    return (
                        <div id={index} className="bg-blue rounded overflow-hidden shadow-md m-4 p-2">
                            <p className="font-bold">نام شخص: {hist.name}</p>
                            <p className="font-bold">فعالیت انجام شده: {hist.task}</p>
                            <p className="text-gray-400">تاریخ: {dateChange(hist.date)}</p><span>{hist.date && hist.date.split(" ")[1]}</span>
                        </div>
                    )
                })
            )
                :
                ''
            }
        </div>
    );
};

export default History;
