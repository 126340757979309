import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useNavigate } from "react-router-dom";
import JalaliDate from "./dateConvertor";
import LoadingButton from "./General/LoadingButton";
import { optionsTwo } from "./General/Config";
import Pagination from "./Pagination";
import ProjectsTable from "./ProjectsTable";
import Loading from "./General/Loading";
import { useRole } from "./context/roleProvider";
import CountDown from "./pDetails/CountDown";

const UProjectList = () => {
  const { loggedInUserInfo } = useRole();
  loggedInUserInfo && console.log(loggedInUserInfo[0].naghsh);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new DateObject());
  const [convertedSelectedDay, setConvertedSelectedDay] = useState(null);
  const [projects, setProjects] = useState(null);
  const [showAllproject, setShowAllProject] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [projectsTabs, setProjectsTabs] = useState({
    tabOne: true,
    tabTwo: false,
    tabThree: false,
  });

  useEffect(() => {
    if (localStorage.getItem('page') !== null) {
      setPageNumber(localStorage.getItem('page'));
    }
  }, [])

  // useEffect(() => {
  //   const func = async () => {
  //     if (loggedInUserInfo[0].naghsh !== 'Manager') {
  //       try {
  //         const response = await fetch("./nstd_site/apiunsuccprj.php", {
  //           method: "GET",
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }
  //   func();
  // }, [loggedInUserInfo])

  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  var SERVER_URL_GETPRJSRCH = "./nstd_site/api7unsucc.php?id=";

  useEffect(() => {
    if (selectedDay) {
      console.log(selectedDay);
      let miladiDate = JalaliDate.jalaliToGregorian(
        selectedDay.year,
        Number(selectedDay.month.number),
        selectedDay.day
      );
      setConvertedSelectedDay(
        miladiDate[0] + "-" + miladiDate[1] + "-" + miladiDate[2]
      );
    }
  }, [selectedDay]);


  const SelectStyle = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      "&:focus-within": {
        borderColor: "white",
      },
    }),
  };

  const getProjects = async () => {
    try {
      const response = await fetch("./nstd_site/api3unsuccessfull.php", {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsRejected = async () => {
    try {
      const response = await fetch("./nstd_site/api3rejected.php", {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsCustomer = async (k) => {
    try {
      let kod = k;
      const response = await fetch(`./nstd_site/api3cus.php?id=${kod}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsEnd = async () => {
    try {
      const response = await fetch("./nstd_site/api3end.php", {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsVs = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?v=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsSl = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?sl=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsKeif = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?k=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsPro = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?p=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsAcc = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?acc=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsCoord = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?h=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsTrs = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?t=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsNsb1 = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?c=${c}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsAcc1 = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?acc=${c}&v=0`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsAcc2 = async () => {
    try {
      let c = localStorage.getItem('lgn').split("=")[1];
      const response = await fetch(`./nstd_site/api3nsb.php?acc=${c}&v=1`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsTr1 = async () => {
    getProjectsTrs();
  };
  const getProjectstr2 = async () => {
    try {
      const response = await fetch(`./nstd_site/api3tr.php?k=${localStorage.getItem('lgn').split("=")[1]}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsNsb2 = async () => {
    try {
      const response = await fetch(`./nstd_site/api3nsbme.php?k=${localStorage.getItem('lgn').split("=")[1]}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectsEndNsb3 = async () => {
    try {
      const response = await fetch("./nstd_site/api3end.php", {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setProjects(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectByUser = async (input) => {
    console.log(input, 'ttttttttttttttttttttttttt');
    setProjects(null);
    try {
      const response = await fetch(
        `./nstd_site/ldprbyslunsucc.php?id=${input.id}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        let data = await response.json();
        setProjects(data.reverse());
        setPageNumber(1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUsers = async () => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let data = this.responseText.split("#");
        let userArray = [];
        for (let i = 0; i < data.length - 1; i++) {
          userArray.push({
            label: data[i].split("*")[1],
            value: data[i].split("*")[0],
            id: data[i].split("*")[0],
          });
        }
        userArray.unshift({ label: "همه", value: 100, id: 100 });
        setOptions(userArray);
      } else {
        return null;
      }
    };
    let code = localStorage.getItem('lgn').split("&")[0];
    let uid = localStorage.getItem('lgn').split("=")[1];
    xhttp.open("GET", `./nstd_site/ldslus.php?c=${code}&u=${uid}`, true);
    xhttp.send();
  };

  useEffect(() => {
    if (localStorage.getItem('lgn').split("&")[0] === '9999999999999') {
      getProjectsNsb1()
    } else if (localStorage.getItem('lgn').split("&")[0] === '0000000000000') {
      getProjectsCustomer(localStorage.getItem('lgn').split("=")[1]);
    } else if (localStorage.getItem('lgn').split("&")[0] === '8888888888888') {
      getProjectsTrs();
    } else if (localStorage.getItem('lgn').split("&")[0] === '4444444444444') {
      getProjectsAcc1();
    } else if (localStorage.getItem('lgn').split("&")[0] === '5555555555555') {//هماهنگی
      getProjectsCoord();
    } else if (localStorage.getItem('lgn').split("&")[0] === '6666666666666') {// تولید
      getProjectsPro();
    } else if (localStorage.getItem('lgn').split("&")[0] === '7777777777777') {// کیفی
      getProjectsKeif();
    } else if (localStorage.getItem('lgn').split("&")[0] === '3333333333333') {// فروش
      getProjectsSl();
    } else if (localStorage.getItem('lgn').split("&")[0] === '2222222222222') {// ویزیتور
      getProjectsVs();
    }
    else {
      getProjects();
    }
    getUsers();
    getAllUsers();
    if (localStorage.getItem('page') !== null) {
      setPageNumber(localStorage.getItem('page'));
      localStorage.removeItem('page');
    }
  }, []);

  const getProjectsBySearch = (e) => {
    setLoadingBtn(true);
    setProjects(null);
    setShowAllProject(true);
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    let employerName = formData.get("employerName");
    let category = formData.get("category");
    let projectFilter = formData.get("projectFilter") ? formData.get("projectFilter") : 'همه';
    let datechecked = formData.get("date");
    let applyDate;
    if (datechecked) {
      applyDate = true;
    } else {
      applyDate = false;
    }
    let userNationalCode = localStorage
      .getItem(LOCAL_STORAGE_AUTH_KEY)
      .split("=")[1];
    let searchParams =
      SERVER_URL_GETPRJSRCH +
      employerName +
      "&st=ناموفق" +
      "&usr=" +
      userNationalCode +
      "&dt=" +
      convertedSelectedDay +
      "&dts=" +
      applyDate +
      "&fld=" +
      projectFilter;

    const getProjectsBySearchParams = async () => {
      try {
        const response = await fetch(searchParams, {
          method: "GET",
          cash: 'no-store',
          
        });
        let data = await response.json();
        // console.log(data, "data by search");
        setProjects(data.reverse());
        setLoadingBtn(false);
      } catch (error) {
        console.log(error);
      }
    };
    getProjectsBySearchParams();
  };

  const handleShowAllProjects = () => {
    setProjects(null);
    setShowAllProject(false);
    if (localStorage.getItem('lgn').split("&")[0] === '9999999999999') {
      getProjectsNsb1()
    } else if (localStorage.getItem('lgn').split("&")[0] === '0000000000000') {
      getProjectsCustomer(localStorage.getItem('lgn').split("=")[1]);
    } else if (localStorage.getItem('lgn').split("&")[0] === '8888888888888') {
      getProjectsTrs();
    } else if (localStorage.getItem('lgn').split("&")[0] === '4444444444444') {
      getProjectsAcc();
    } else if (localStorage.getItem('lgn').split("&")[0] === '5555555555555') {//هماهنگی
      getProjectsCoord();
    } else if (localStorage.getItem('lgn').split("&")[0] === '6666666666666') {// تولید
      getProjectsPro();
    } else if (localStorage.getItem('lgn').split("&")[0] === '7777777777777') {// کیفی
      getProjectsKeif();
    } else if (localStorage.getItem('lgn').split("&")[0] === '3333333333333') {// فروش
      getProjectsSl();
    } else if (localStorage.getItem('lgn').split("&")[0] === '2222222222222') {// ویزیتور
      getProjectsVs();
    }
    else {
      getProjects();
    }
    setPageNumber(1);
  };
  const setProjectsTabs2 = (jjjss) => {
    getProjects();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabs3 = (jjjss) => {
    getProjectsRejected();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabs4 = (jjjss) => {
    getProjectsEndNsb3();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsTr1 = (jjjss) => {
    getProjectsTr1();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsNsb1 = (jjjss) => {
    getProjectsNsb1();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsNsb2 = (jjjss) => {
    getProjectsNsb2();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsAcc1 = (jjjss) => {
    getProjectsAcc1();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsAcc2 = (jjjss) => {
    getProjectsAcc2();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsTr2 = (jjjss) => {
    getProjectstr2();
    setProjectsTabs(jjjss);
  };
  const setProjectsTabsNsb3 = (jjjss) => {
    getProjectsEndNsb3();
    setProjectsTabs(jjjss);
  };

  let data;
  if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
    data = {
      id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
      id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
    };
  }

  const getAllUsers = async () => {
    try {
      const response = await fetch(
        "./nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        // console.log(userData, "userData");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
      <div className="m-5">
        <form onSubmit={getProjectsBySearch}>

          {
            loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh !== 'sale' && loggedInUserInfo[0].naghsh !== 'Manager'
                ? (
                  <Select
                    onChange={handleProjectByUser}
                    isClearable
                    name="projectFilter"
                    options={options}
                    defaultValue={{ label: "همه", value: "همه", id: 100 }}
                    isDisabled="true"
                    placeholder="لطفا انتخاب کنید ..."
                  />
                ) : (
                  <Select
                    onChange={handleProjectByUser}
                    isClearable
                    name="projectFilter"
                    options={options}
                    placeholder="لطفا انتخاب کنید ..."
                    defaultValue={{ label: "همه", value: "همه", id: 100 }}
                  />
                )
            ) : null
          }
          <h1 className="py-5">لیست پروژه‌ 
          های ناموفق</h1>
          {/* <button
            type="button"
            onClick={() =>
              navigate("/new-project", {
                state: { eidtMode: false },
              })
            }
            className="bg-darkBlue w-full rounded-md py-2 text-white"
          >
            + پروژه جدید
          </button> */}

          <div className="flex flex-col md:flex-row my-8 w-full justify-between">
            <input
              className="p-2 rounded-md ml-2 w-full mb-4 md-m-0 md:w-[24%]"
              placeholder="نام و نام‌خانوادگی کارفرما"
              type="text"
              name="employerName"
            />
            <div className="min-h-[42px] md:min-h-[46px] ml-2 w-full md:w-[24%] mb-4 md-m-0 rounded-md">
              <Select
                styles={SelectStyle}
                isClearable
                name="category"
                options={optionsTwo}
                placeholder="لطفا انتخاب کنید ..."
                isDisabled="true"
              />
            </div>

            <div className="flex bg-white justify-center items-center ml-2 mb-4 md-m-0 w-full md:w-[24%] rounded-md border border-lightgray">
              <div className="border-l border-l-lightgray p-2 xl:p-3 flex items-center justify-center">
                <label htmlFor="date"></label>
                <input
                  className="mx-2 w-5 h-5 border "
                  type="checkbox"
                  name="date"
                  id=""
                />
              </div>
              <div className="flex justify-center items-center text-center w-full">
                <DatePicker
                  className="custom-input"
                  value={selectedDay}
                  onChange={setSelectedDay}
                  calendar={persian}
                  locale={persian_fa}
                  calendarPosition="bottom-right"
                />
              </div>
            </div>
            <LoadingButton
              title={"جستجو"}
              Tstyle={
                "w-full md:w-[24%] bg-darkBlue rounded-md mb-4 md-m-0 py-2 text-white flex justify-center items-center"
              }
              type={"submit"}
              loadingBtn={loadingBtn}
            />
          </div>
          <div className="flex justify-center">
            {showAllproject && (
              <button
                onClick={handleShowAllProjects}
                className="w-full self-center mx-auto md:w-1/5 bg-[#FFA500] text-darkBlue rounded-md mb-4 md-m-0 py-2"
                type="button"
              >
                مشاهده همه پروژه‌ها
              </button>
            )}
          </div>
        </form>
      </div>
      {loggedInUserInfo ? (
        // loggedInUserInfo[0].naghsh === "sale" ||
        loggedInUserInfo[0].naghsh === "qc1" ||
          loggedInUserInfo[0].naghsh === "product_manager1" ? (
          <div className="mx-[5%] md:mx-[2%] md:w-[96%] my-5 flex max-w-[1320px] font-iranYekanRegular text-xs md:text-sm justify-start">
            <div
              onClick={() =>
                setProjectsTabs({
                  tabOne: true,
                  tabTwo: false,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabOne
                ? "bg-darkBlue text-white"
                : "bg-white border-l-2"
                }  cursor-pointer transition-colors hover:bg-darkBlue text-center hover:text-white p-2 rounded-tr-md`}
            >
              پروژه‌های جاری
            </div>
            <div
              onClick={() =>
                setProjectsTabs({
                  tabOne: false,
                  tabTwo: true,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabTwo ? "bg-darkBlue text-white" : "bg-white"
                } cursor-pointer hover:bg-darkBlue hover:text-white transition-colors p-2 text-center`}
            >
              پروژه‌های برگشتی
            </div>
            <div
              onClick={() =>
                setProjectsTabs({
                  tabOne: false,
                  tabTwo: false,
                  tabThree: true,
                })
              }
              className={`${projectsTabs.tabThree
                ? "bg-darkBlue text-white"
                : "bg-white border-r-2"
                } cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tl-md`}
            >
              پروژه‌های اتمام‌یافته
            </div>
          </div>
        ) : loggedInUserInfo[0].naghsh === "installer" ? (
          <div className="mx-[5%] md:mx-[2%] my-5 md:w-[96%] flex max-w-[1320px] font-iranYekanRegular text-xs md:text-sm justify-start">
            <div
              onClick={() =>
                setProjectsTabsNsb1({
                  tabOne: true,
                  tabTwo: false,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabOne
                ? "bg-darkBlue text-white"
                : "bg-white border-l-2"
                }  cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tr-md`}
            >
              پروژه‌های جاری
            </div>
            <div
              onClick={() =>
                setProjectsTabsNsb2({
                  tabOne: false,
                  tabTwo: true,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabTwo ? "bg-darkBlue text-white" : "bg-white"
                } cursor-pointer text-center hover:bg-darkBlue hover:text-white transition-colors p-2`}
            >
              پروژه‌های اخذ شده
            </div>
            <div
              onClick={() =>
                setProjectsTabsNsb3({
                  tabOne: false,
                  tabTwo: false,
                  tabThree: true,
                })
              }
              className={`${projectsTabs.tabThree
                ? "bg-darkBlue text-white"
                : "bg-white border-r-2"
                } cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tl-md`}
            >
              پروژه‌های رد شده
            </div>
          </div>
        ) : loggedInUserInfo[0].naghsh === "accountant" ? (
          <div className="mx-[5%] md:mx-[2%] my-5 md:w-[96%] flex max-w-[1320px] font-iranYekanRegular text-xs md:text-sm justify-start">
            <div
              onClick={() =>
                setProjectsTabsAcc1({
                  tabOne: true,
                  tabTwo: false,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabOne
                ? "bg-darkBlue text-white"
                : "bg-white border-l-2"
                }  cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tr-md`}
            >
              پروژه‌های جاری
            </div>
            <div
              onClick={() =>
                setProjectsTabsAcc2({
                  tabOne: false,
                  tabTwo: true,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabTwo ? "bg-darkBlue text-white" : "bg-white"
                } cursor-pointer text-center hover:bg-darkBlue hover:text-white transition-colors p-2`}
            >
              پروژه‌های تائید شده
            </div>
          </div>
        ) : loggedInUserInfo[0].naghsh === "transport" ? (
          <div className="mx-[5%] md:mx-[2%] my-5 md:w-[96%] flex max-w-[1320px] font-iranYekanRegular text-xs md:text-sm justify-start">
            <div
              onClick={() =>
                setProjectsTabsTr1({
                  tabOne: true,
                  tabTwo: false,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabOne
                ? "bg-darkBlue text-white"
                : "bg-white border-l-2"
                }  cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tr-md`}
            >
              پروژه‌های جاری
            </div>
            <div
              onClick={() =>
                setProjectsTabsTr2({
                  tabOne: false,
                  tabTwo: true,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabTwo ? "bg-darkBlue text-white" : "bg-white"
                } cursor-pointer text-center hover:bg-darkBlue hover:text-white transition-colors p-2`}
            >
              پروژه‌های اخذ شده
            </div>
          </div>
        ) : loggedInUserInfo[0].naghsh === "Manager1" ?
          <div className="mx-[5%] md:mx-[2%] my-5 md:w-[96%] flex max-w-[1320px] font-iranYekanRegular text-xs md:text-sm justify-start">
            <div
              onClick={() =>
                setProjectsTabs2({
                  tabOne: true,
                  tabTwo: false,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabOne
                ? "bg-darkBlue text-white"
                : "bg-white border-l-2"
                }  cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tr-md`}
            >
              پروژه‌های جاری
            </div>
            <div
              onClick={() =>
                setProjectsTabs3({
                  tabOne: false,
                  tabTwo: true,
                  tabThree: false,
                })
              }
              className={`${projectsTabs.tabTwo ? "bg-darkBlue text-white" : "bg-white"
                } cursor-pointer text-center hover:bg-darkBlue hover:text-white transition-colors p-2`}
            >
              پروژه‌های برگشتی
            </div>
            <div
              onClick={() =>
                setProjectsTabs4({
                  tabOne: false,
                  tabTwo: false,
                  tabThree: true,
                })
              }
              className={`${projectsTabs.tabThree
                ? "bg-darkBlue text-white"
                : "bg-white border-r-2"
                } cursor-pointer text-center transition-colors hover:bg-darkBlue hover:text-white p-2 rounded-tl-md`}
            >
              پروژه‌های تمام شده
            </div>
          </div>
          : loggedInUserInfo[0].naghsh === "visitor" || loggedInUserInfo[0].naghsh === "coord" ? null : null
      ) : null}
      {projects ? (
        projects.length ? (
          <ProjectsTable projects={projects} pageNumber={pageNumber} />
        ) : (
          <p className="text-center">نتیجه‌ای منطبق با جستجوی شما یافت نشد</p>
        )
      ) : (
        <Loading />
      )}
      {projects && projects.length > 10 && (
        <Pagination
          projectsLength={projects.length}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
};

export default UProjectList;
