import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import Loading from "../General/Loading";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

const MyChart = () => {
  const [chartData, setChartData] = useState(null);
  const [firstData, setFirstData] = useState(null);
  const [secData, setSecData] = useState(null);
  const [chartData1, setChartData1] = useState(null);
  const [firstData1, setFirstData1] = useState(null);
  const [secData1, setSecData1] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [firstData2, setFirstData2] = useState(null);
  const [secData2, setSecData2] = useState(null);
  const [chartData3, setChartData3] = useState(null);
  const [firstData3, setFirstData3] = useState(null);
  const [secData3, setSecData3] = useState(null);
  const [chartData4, setChartData4] = useState(null);
  const [firstData4, setFirstData4] = useState(null);
  const [secData4, setSecData4] = useState(null);
  const [chartData5, setChartData5] = useState(null);
  const [firstData5, setFirstData5] = useState(null);
  const [secData5, setSecData5] = useState(null);
  const [chartData6, setChartData6] = useState(null);
  const [firstData6, setFirstData6] = useState(null);
  const [secData6, setSecData6] = useState(null);
  useEffect(() => {
    const getChartData = async () => {
      try {
        const response = await fetch("../../nstd_site/api9.php", {
          method: "GET",
          cash: 'no-store',
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setChartData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getChartData();
    Chart.register(CategoryScale);
  }, []);
  useEffect(()=>{
    const getChartData = async () => {
      try {
        const response = await fetch("../../nstd_site/apighchdta.php", {
          method: "GET",
          cash: 'no-store',
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setChartData1(data.ch1);
          setChartData2(data.ch2);
          setChartData3(data.ch3);
          setChartData4(data.ch4);
          setChartData5(data.ch5);
          setChartData6(data.ch6);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getChartData();
    Chart.register(CategoryScale);
  }, [])

  useEffect(() => {
    if (chartData) {
      let dataOne = chartData && chartData.map((data) => data.name);
      let dataTwo = chartData && chartData.map((data) => data.count);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"

        }
      }
      setFirstData(dataOne);
      setSecData(dataTwo);

    }
  }, [chartData]);
  useEffect(() => {
    console.log(chartData1, 'chart1');
    if (chartData1) {
      let dataOne = chartData1 && 'مالی';
      let dataTwo = chartData1 && chartData1.map((data) => data.sum);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"

        }
      }
      setFirstData1(dataOne);
      setSecData1(dataTwo);

    }
  }, [chartData1]);
  useEffect(() => {
    console.log(chartData2, 'chart2');
    if (chartData2) {
      let dataOne = chartData2 && chartData2.map((data) => data.name);
      let dataTwo = chartData2 && chartData2.map((data) => data.sum);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"

        }
      }
      setFirstData2(dataOne);
      setSecData2(dataTwo);

    }
  }, [chartData2]);
  useEffect(() => {
    console.log(chartData3, 'chart3');
    if (chartData3) {
      let dataOne = chartData3 && chartData3.map((data) => data.name);
      let dataTwo = chartData3 && chartData3.map((data) => data.sum);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"

        }
      }
      setFirstData3(dataOne);
      setSecData3(dataTwo);

    }
  }, [chartData3]);
  useEffect(() => {
    console.log(chartData4, 'chart4');
    if (chartData4) {
      let dataOne = chartData4 && 'تولید کل';
      let dataTwo = chartData4 && chartData4.map((data) => data.sum);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"
        }
      }
      setFirstData4(dataOne);
      setSecData4(dataTwo);

    }
  }, [chartData4]);
  useEffect(() => {
    console.log(chartData5, 'chart5');
    if (chartData) {
      let dataOne = chartData && chartData.map((data) => data.name);
      let dataTwo = chartData && chartData.map((data) => data.sum);
      for (let i = 0; i < dataTwo.length; i++) {
        if (dataTwo[i] === null) {
          dataTwo[i] = "0"

        }
      }
      setFirstData5(dataOne);
      setSecData5(dataTwo);

    }
  }, [chartData5]);
  // useEffect(() => {
  //   console.log(chartData6, 'chart6');
  //   if (chartData) {
  //     let dataOne = chartData && chartData.map((data) => data.name);
  //     let dataTwo = chartData && chartData.map((data) => data.count);
  //     for (let i = 0; i < dataTwo.length; i++) {
  //       if (dataTwo[i] === null) {
  //         dataTwo[i] = "0"

  //       }
  //     }
  //     setFirstData(dataOne);
  //     setSecData(dataTwo);

  //   }
  // }, [chartData6]);

  const labels = firstData && firstData;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "نمودار کاربران",
        data: secData && secData,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config = {
    type: "line",
    data: data,
  };
  const labels1 = firstData1 && firstData1;
  const data1 = {
    labels: labels1,
    datasets: [
      {
        label: "نمودار مالی",
        data: secData1 && secData1,
        fill: true,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config1 = {
    type: "line",
    data: data1,
  };
  const labels2 = firstData2 && firstData2;
  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: "نمودار نصاب ها",
        data: secData2 && secData2,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config2 = {
    type: "line",
    data: data2,
  };
  const labels3 = firstData3 && firstData3;
  const data3 = {
    labels: labels3,
    datasets: [
      {
        label: "نمودار مسئولان حمل",
        data: secData3 && secData3,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config3 = {
    type: "line",
    data: data3,
  };
  const labels4 = firstData4 && firstData4;
  const data4 = {
    labels: labels4,
    datasets: [
      {
        label: "مدیر تولید",
        data: secData4 && secData4,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config4 = {
    type: "line",
    data: data4,
  };
  const labels5 = firstData5 && firstData5;
  const data5 = {
    labels: labels,
    datasets: [
      {
        label: "نمودار کاربران",
        data: secData5 && secData5,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config5 = {
    type: "line",
    data: data5,
  };
  const labels6 = firstData6 && firstData6;
  const data6 = {
    labels: labels6,
    datasets: [
      {
        label: "نمودار کاربران",
        data: secData6 && secData6,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const config6 = {
    type: "line",
    data: data6,
  };

  return (
    <div className="my-32 max-w-[1320px] mx-auto">
      {chartData ? <Line options={config} data={data} /> : <Loading />}
      {chartData1 ? <Bar options={config1} data={data1} /> : <Loading />}
      {chartData2 ? <Bar options={config2} data={data2} /> : <Loading />}
      {chartData3 ? <Bar options={config3} data={data3} /> : <Loading />}
      {chartData4 ? <Bar options={config4} data={data4} /> : <Loading />}
      {chartData5 ? <Bar options={config5} data={data5} /> : <Loading />}
      {chartData6 ? <Bar options={config6} data={data6} /> : <Loading />}
    </div>
  );
};

export default MyChart;
