import React, { useState } from "react";
import { useEffect } from "react";
import { DateObject } from "react-multi-date-picker";
import { warningNotify } from "../General/notifications";
import TextLoading from "../General/TextLoading";
import hourGlass from "../icons/hourGlass.svg";

const CountDown = ({ endDate }) => {
  const [daysLeft, setDaysLeft] = useState(null);
  const [overEndDate, setOverEndDate] = useState(false);
  console.log(endDate, "endDate");
  useEffect(() => {
    if (endDate) {
      let dateOne = new DateObject();
      let dateTwo = endDate;
      let time_difference = dateTwo.unix * 1000 - dateOne.unix * 1000;
      if (time_difference < 0) {
        warningNotify("تاریخ پایان پروژه گذشته است");
        setOverEndDate(true);
        return;
      }
      let days_difference = Math.round(time_difference / (1000 * 60 * 60 * 24));
      setDaysLeft(days_difference);
      console.log(days_difference);
    }
  }, [endDate]);

  return (
    <div className="bg-gray border-b-4 border-b-white pb-5">
      <div className="flex items-center">
        <h1 className="p-3">روزشمار پروژه</h1>
      </div>
      {endDate ? (
        daysLeft ? (
          <div className="flex bg-white rounded-md">
            {/* <img className="w-8 h-auto" src={hourGlass} alt="" /> */}
            <p className="p-3 ">{daysLeft} روز تا پایان پروژه باقی مانده است</p>
          </div>
        ) : overEndDate ? (
          <p className="bg-white rounded-md w-[95%] xl:w-full mx-auto p-3">تاریخ پایان پروژه گذشته است</p>
        ) : (
          <div className="bg-white w-[95%] lg:w-full mx-auto">
            <TextLoading />
          </div>
        )
      ) : (
        <p className="bg-white rounded-md w-[95%] xl:w-full mx-auto p-3">
          تاریخ پایان پروژه مشخص نشده‌است
        </p>
      )}
    </div>
  );
};

export default CountDown;
