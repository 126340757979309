import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dateChange } from "./dateConvertor";

const TicketDetail = () => {
    const navigate = useNavigate();
    const [ticketList, setTicketList] = useState([]);
    const [date, setDate] = useState(new Date());
    const [addit, setAddit] = useState(0);
    const [text, setText] = useState(null);
    const { id } = useParams();

    const LOCAL_STORAGE_AUTH_KEY = "lgn";

    const getTicketList = async () => {
        try {
            const response = await fetch(`./nstd_site/apigttktarr.php?tid=${id}`, {
                method: "GET",
                cash: 'no-store',
            });
            let data = await response.json();
            setTicketList(data.reverse());
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTicketList();
    }, [])

    const sendReply = async () => {
        if (text !== null && text !== '') {
            try {
                const response = await fetch(`./nstd_site/apisvtktrsp.php?tid=${id}&tx=${text}&c=${localStorage.getItem('lgn').split("=")[1]}`, {
                    method: "GET",
                    cash: 'no-store',
                });
                let data = await response.json();
                getTicketList();
            } catch (error) {
                console.log(error);
            }
        }
    }
    const handleText = (e) => {
        setText(e.target.value);
    }
    return (
        <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
            {ticketList ? (
                <>
                    <div className="bg-blue rounded m-4 p-2">
                        <p>متن پاسخ:{console.log(ticketList, 'ticket data')}</p>
                        <input className="w-full h-24 rounded my-4 p-2" onChange={handleText} defaultValue={text}></input>
                        <button className="m-4 p-2 bg-black text-white rounded" onClick={() => {
                            sendReply();
                        }}>ارسال</button>
                    </div>
                    {
                        ticketList.length ? (
                            <p>عنوان تیکت: {ticketList[0].subject}</p>
                        ) : ''
                    }
                    {ticketList.map((ticket, index) => {
                        return (
                            <div id={index} className={`${index===ticketList.length-1?'bg-yellow':'bg-white'} rounded overflow-hidden shadow-md m-4 p-2`}>
                                <p className="font-bold">{ticket.text}</p>
                                <p className="">{dateChange(ticket.date)}</p>
                            </div>
                        )
                    })}
                </>
            )
                :
                ''
            }
        </div>
    );
};

export default TicketDetail;
