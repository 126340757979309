import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Alarms from "./Alarms/Alarms";
import { useRole } from "./context/roleProvider";
import { dateChange } from "./dateConvertor";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "./General/Modal";
import ChatList from "./gram/ChatList";
import Chat from "./gram/Chat";
import UserList from "./gram/UserList";
import newThing from './icons/icons8_create_document.svg';
import closePic from './icons/close.svg';
import usersPic from './icons/icons8_user.svg';
import backArrow from './icons/icons8_right.svg';
import PopUp from "./General/PopUp";
import { grpChnlOptions } from "./General/Config";
import { successNotify, warningNotify } from "./General/notifications";
import LoadingButton from "./General/LoadingButton";
import { ToastContainer } from "react-toastify";



const ChatPage = () => {
    // const { loggedInUserInfo } = useRole();
    const navigate = useNavigate();
    const [tabs, setTabs] = useState({
        alarms: true,
        chat: false,
        tickets: false,
    });
    const [openTicket, setOpenTicket] = useState(false);
    const [newMessage, setNewMessage] = useState(false);
    const [myMessages, setMyMessages] = useState(null);
    const [text, setText] = useState('');
    const optionsTwo = [
        { label: "سپیده خودکاوندی", value: "سپیده خودکاوندی", id: 100 },
        { label: "حسین کارگر", value: "حسین کارگر", id: 101 },
        { label: "علی شمالی", value: "علی شمالی", id: 102 },
        { label: "رضا عبدالملکی", value: "رضا عبدالملکی", id: 103 },
    ];
    const [optionsTwo2, setOptionsTwo2] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState({ search: false, save: false });
    const [loadingBtn2, setLoadingBtn2] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [loadedTickets, setloadedTickets] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [textToShow, setTextToShow] = useState(null);
    const [userSelected, setUserSelected] = useState(null);
    const [chatOfUser, setChatOfUser] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const [grpChnl, setGrpChnl] = useState(null);
    const [showAllUser, setShowAllUser] = useState(true);
    const [users, setUsers] = useState([]);
    const [subGroups, setSubGroups] = useState(null);
    const [checkedSubGroups, setCheckedSubGroups] = useState([]);
    const [grpName, setGrpName] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [refreshAll, setRefreshAll] = useState(false);

    const user = {
        id: 1,
        name: "John Doe",
        avatar: "https://via.placeholder.com/150x150",
    }
    const LOCAL_STORAGE_AUTH_KEY = "lgn";
    let userNationalCode = localStorage
        .getItem(LOCAL_STORAGE_AUTH_KEY)
        .split("=")[1];
    let data = {
        id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
        id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
    };
    useEffect(() => {
        if (grpChnl) {
            setShowAllUser(true);
        } else {
            setShowAllUser(true);
        }
    }, [grpChnl])
    useEffect(() => {
        setInterval(() => {
            // console.log('the interval');
            // //   setRefreshAll(!refreshAll);
            // getMessages();

            // bunch of code from new thing
            console.log('the interval');
            setRefreshTrigger(!refreshTrigger);
        }, 3000);
    }, []);
    // useEffect(()=>{
    //     setTimeout(() => {
    //         getMessages();
    //     }, 3000);
    // },[])

    const getSub = async (nationalCode) => {
        try {
            const response = await fetch(
                `./nstd_site/apigtrel.php?code=${nationalCode}`, {
                    method: "GET",
                    cash: 'no-store',
                  }
            );
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    setCheckedSubGroups(
                        data[0].sub
                            .split(",")
                            .splice(
                                0,
                                data[0].sub.split(",").length - 1,
                                data[0].sub.split(",").length
                            )
                    );
                }
                else {
                    return
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const getSubGroups = async () => {
            try {
                const response = await fetch(
                    `./nstd_site/apigtrel.php?code=${userNationalCode}`,
                    {
                        method: "GET",
                        cash: 'no-store',
                    }
                );
                if (response.ok) {
                    let data = await response.json();
                    console.log(data, "subgroups data");
                    setSubGroups(data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        const getUsers = async () => {
            try {
                const response = await fetch(
                    "./nstd_site/usapgt.php",
                    {
                        method: "POST",
                        body: JSON.stringify(data),
                        cash: 'no-store',
                    }
                );
                if (response.ok) {
                    let userData = await response.json();
                    console.log(userData, "userData");
                    setUsers(userData);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getUsers();
        getSubGroups();
    }, []);
    useEffect(() => {
        console.log(userSelected, 'the use returned!!!');//11111111111111
        let newArray = Array();
        myMessages && myMessages.map((message, index) => {
            console.log(myMessages, 'all of messages');//2222222222222222222
            let thisUser = localStorage.getItem('lgn').split('=')[1];
            let sender1 = userSelected && userSelected.receivercode;
            let snd = userSelected && userSelected.sendercode;
            let rec = userSelected && userSelected.receivercode;
            let isGroup = userSelected && userSelected.groupId;
            let isChannel = userSelected && userSelected.channelId;
            console.log(message, 'the ' + index, message.sendercode, sender1, message.receivercode, thisUser);
            if (!(isGroup || isChannel)) {
                console.log('is not gr chn');//333333333333333
                if (message.sendercode === snd && message.receivercode === rec) {
                    newArray.push({
                        id: message.id1,
                        sender: {
                            id: message.sendercode,
                            name: message.sender,
                            avatar: "https://via.placeholder.com/150x150",
                        },
                        timestamp: message.date,
                        type: 'text',
                        content: message.text,
                    })
                } else if (message.sendercode === rec && message.receivercode === snd) {
                    newArray.push({
                        id: message.id1,
                        sender: {
                            id: message.sendercode,
                            name: message.sender,
                            avatar: "https://via.placeholder.com/150x150",
                        },
                        timestamp: message.date,
                        type: 'text',
                        content: message.text,
                    })
                }
                console.log(message.sendercode, thisUser, message.receivercode, sender1);
            } else if (isGroup) {
                if (message.receivergroup === isGroup) {
                    newArray.push({
                        id: message.id1,
                        sender: {
                            id: message.sendercode,
                            name: message.sender,
                            avatar: "https://via.placeholder.com/150x150",
                        },
                        timestamp: message.date,
                        type: 'text',
                        content: message.text,
                    })
                }
            } else if (isChannel) {
                if (message.receiverchannel === isChannel) {
                    newArray.push({
                        id: message.id1,
                        sender: {
                            id: message.sendercode,
                            name: message.sender,
                            avatar: "https://via.placeholder.com/150x150",
                        },
                        timestamp: message.date,
                        type: 'text',
                        content: message.text,
                    })
                }
            }
        })
        console.log(newArray, 'messages filtered');
        setChatOfUser({
            id: userSelected && userSelected.id,
            name: userSelected && userSelected.name,
            description: '',
            avatar: "https://via.placeholder.com/150x150",
            messages: newArray.reverse(),
        });
        // console.log('my dream executed');
    }, [userSelected, myMessages])
    const chat = {
        id: 1,
        name: "Example Chat Room",
        description: "This is a sample chat room",
        avatar: "https://via.placeholder.com/150x150",
        messages: [
            {
                id: 1,
                sender: {
                    id: 2,
                    name: "Jane Smith",
                    avatar: "https://via.placeholder.com/150x150",
                },
                timestamp: Date.now() - 1000 * 60 * 10,
                type: 'text',
                content: 'Hello!',
            },
            {
                id: 2,
                sender: {
                    id: 1,
                    name: "John Doe",
                    avatar: "https://via.placeholder.com/150x150",
                },
                timestamp: Date.now() - 1000 * 60 * 5,
                type: 'text',
                content: 'Hi there!',
            },
        ]
    }

    useEffect(() => {
        const getTickets = async () => {
            try {
                const response = await fetch(
                    `./nstd_site/apigttkt.php?id=${localStorage.getItem('lgn').split("=")[1]}`,
                    {
                        method: "GET",
                        cash: 'no-store',
                    }
                );
                let data = await response.json();
                setloadedTickets(data);
            } catch (error) {
                console.log(error);
            }
        }
        getTickets();
    }, [])
    const getMessages = async () => {
        try {
            console.log('the first place');
            const response = await fetch(
                `./nstd_site/apigtmsg.php?id=${localStorage.getItem('lgn').split("=")[1]}&rl=${localStorage.getItem('lgn').split("&")[0]}`,
                {
                    method: "GET",
                    cash: 'no-store',
                }
            );
            let data = await response.json();
            setMyMessages(data);
            console.log(data, 'the all of messages');
        } catch (error) {
            console.log(error);
        }
        //     setTimeout(() => {
    //         getMessages();
    //     }, 3000);
    }
    useEffect(() => {
        getMessages();
    }, [])
    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await fetch(
                    `./nstd_site/apigtusch.php`,
                    {
                        method: "GET",
                        cash: 'no-store',
                    }
                );
                let data = await response.json();
                let arr = Array();
                data.map((datum, index) => {
                    let newVal = '';
                    if (datum.label.split("*")[1] === 'installer') {
                        newVal = '(نصاب)';
                    }
                    else if (datum.label.split("*")[1] === 'transport') {
                        newVal = '(مسئول حمل)';
                    }
                    else if (datum.label.split("*")[1] === 'Visitor') {
                        newVal = '(ویزیتور)';
                    }
                    else if (datum.label.split("*")[1] === 'accountant') {
                        newVal = '(مالی)';
                    }
                    else if (datum.label.split("*")[1] === 'sale') {
                        newVal = '(مدیر فروش)';
                    }
                    else if (datum.label.split("*")[1] === 'qc') {
                        newVal = '(کنترل کیفی)';
                    }
                    else if (datum.label.split("*")[1] === 'product_manager') {
                        newVal = '(مدیر تولید)';
                    }
                    else if (datum.label.split("*")[1] === 'coord') {
                        newVal = '(هماهنگی)';
                    }
                    else if (datum.label.split("*")[1] === 'Manager') {
                        newVal = '(مدیر)';
                    }
                    arr.push({
                        'label': datum.label.split("*")[0] + " " + newVal,
                        'value': datum.value,
                        'id': data.id,
                    }
                    )
                })
                setOptionsTwo2(arr.filter((item) => item.value !== localStorage.getItem('lgn').split('=')[1]));

            } catch (error) {
                console.log(error);
            }
        }
        getUsers();
    }, [])
    const serachCustomer = (e) => {
        if (e) {
            if (e.value) {
                setCustomer(e);
            } else {
                setCustomer({
                    label: "سپیده خودکاوندی",
                    value: "سپیده خودکاوندی",
                    id: 100,
                });
            }
        } else {
            setCustomer(null);
        }
    };
    const changeText = (e) => {
        const { value, name } = e.target;
        // console.log(value, name, 'the event');
        if (e) {
            setText(value);
        } else {
            setCustomer(null);
        }
    };
    const sendChat = () => {
        if (customer.value != null && text != null) {
            const saveChat = async () => {
                try {
                    const response = await fetch(
                        `./nstd_site/apisvcht.php?tr=${customer.value}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
                        {
                            method: "GET",
                            cash: 'no-store',
                        }
                    );
                    setNewMessage(!newMessage);
                    let data = await response.text();
                    successNotify('پیام با موفقیت ارسال گردید.');
                    setText(null);
                    setCustomer(null);
                    getMessages();
                    setUserSelected(null);
                    setChatOfUser(null);
                    console.log(data, 'response of the save chat');
                } catch (error) {
                    console.log(error);
                }
            }
            saveChat();
        }
    }
    const sendChat2 = () => {
        // console.log(userSelected, 'the use returned!!!');
        console.log(text, 'matn neveshte karbar');
        let isGroup = userSelected && userSelected.groupId;
        let isChannel = userSelected && userSelected.channelId;
        if (userSelected.id !== null && text !== null && text !== '') {
            const saveChat = async () => {
                if (!(isGroup || isChannel)) {
                    // console.log("hooooooooorrrrraaaaaaaaaaaa chat");
                    try {
                        console.log(userSelected, 'check the user');
                        let trg = userSelected.id;
                        if (userSelected.sendercode === localStorage.getItem('lgn').split("=")[1]) {
                            trg = userSelected.receivercode;
                        } else {
                            trg = userSelected.sendercode;
                        }
                        if (!userSelected.sendercode && !userSelected.receivercode) {
                            console.log('trg is null');
                            trg = userSelected.id;
                        }
                        console.log(trg, 'the trggg');
                        const response = await fetch(
                            `./nstd_site/apisvcht.php?tr=${trg}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
                            {
                                method: "GET",
                                cash: 'no-store',
                            }
                        );
                        // setNewMessage(!newMessage);
                        let data = await response.text();
                        console.log('پیام با موفقیت ارسال گردید.');
                        setText('');
                        setCustomer(null);
                        getMessages();
                        setRefresh(!refresh);
                        console.log(data, 'response of the save chat');
                    } catch (error) {
                        console.log(error);
                    }
                    console.log('sendChat executed');
                } else if (isGroup) {
                    // console.log("hooooooooorrrrraaaaaaaaaaaa group");
                    try {
                        const response = await fetch(
                            `./nstd_site/apisvgrpmsg.php?tr=${userSelected.groupId}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
                            {
                                method: "GET",
                                cash: 'no-store',
                            }
                        );
                        // setNewMessage(!newMessage);
                        let data = await response.text();
                        successNotify('پیام با موفقیت ارسال گردید.');
                        setText('');
                        setCustomer(null);
                        getMessages();
                        setRefresh(!refresh);
                        console.log(data, 'response of the save group message');
                    } catch (error) {
                        console.log(error);
                    }
                    console.log('sendChat executed');
                } else if (isChannel) {
                    // console.log("hooooooooorrrrraaaaaaaaaaaa channel");
                    try {
                        const response = await fetch(
                            `./nstd_site/apisvchnmsg.php?tr=${userSelected.channelId}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
                            {
                                method: "GET",
                                cash: 'no-store',
                            }
                        );
                        // setNewMessage(!newMessage);
                        let data = await response.text();
                        successNotify('پیام با موفقیت ارسال گردید.');
                        setText('');
                        setCustomer(null);
                        getMessages();
                        setRefresh(!refresh);
                        console.log(data, 'response of the save channel message');
                    } catch (error) {
                        console.log(error);
                    }
                    console.log('sendChat executed');
                }

            }
            saveChat();
            setRefreshTrigger(!refreshTrigger);
        } else {
            console.log('sendChat not executed');
        }
    }
    const handleGrpChnlChange = (evt) => {
        setGrpChnl(evt);
        evt.preventDefault();
    };
    const addSubGroup = async (e) => {
        e.preventDefault();
        setLoadingBtn(true);
        if (grpName === null || grpName === '') {
            warningNotify('لطفا نام گروه را وارد کنید.');
            console.log('لطفا نام گروه را وارد کنید.');
            return;
        }
        const formData = new FormData(e.currentTarget);
        let data = [];
        users.forEach((user) => {
            if (formData.get(user.kodemelli) !== null) data.push(user.kodemelli);
        });
        let sub = data.join(",");
        if (sub === '') {
            alert(sub);
        }
        try {
            const response = await fetch(
                `./nstd_site/apisvgrp.php?head=${grpChnl.value}&sub=${sub}&cr=${localStorage.getItem('lgn').split("=")[1]}&nm=${grpName}`, {
                    method: "GET",
                    cash: 'no-store',
                  }
            );
            let data = await response.text();
            if (data === '') {
                successNotify("با موفقیت اعمال شد");
                setShowAllUser(false);
                setGrpChnl(null);
            } else {
                warningNotify("لطفا دوباره تلاش کنید");
            }
            setLoadingBtn(false);
        } catch (error) {
            console.log(error);
            warningNotify("لطفا دوباره تلاش کنید");
            setLoadingBtn(false);
        }
    };
    const grpNameHandler = (e) => {
        setGrpName(e.currentTarget.value);
    }
    return (
        <>
            {/* flex h-full w-full bg-white hidden md:flex mt-2 */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex w-full h-screen hidden md:flex bg-white">
                <div className="w-1/4 max-w-sm border-r border-gray-200 overflow-y-scroll scrollbar-hide bg-telegram rounded">
                    <div className="flex w-full text-center bg-amber-400-contrast text-white p-2">
                        <img onClick={(e) => { navigate(-1) }} src={closePic} className="h-8 w-8 bg-red rounded cursor-pointer m-2"></img>
                        <img onClick={(e) => { setOpenModal2(true) }} src={newThing} className="h-8 w-8 bg-black rounded cursor-pointer m-2"></img>
                        <img onClick={(e) => { setOpenModal3(!openModal3) }} src={usersPic} className="h-8 w-8 bg-black rounded cursor-pointer m-2"></img>
                        {
                            openModal3 ? (
                                <span className="mt-3 mr-6">مخاطبین</span>
                            ) : (
                                <span className="mt-3 mr-6">لیست چت ها</span>
                            )
                        }

                    </div>
                    {
                        openModal3 ? (
                            <UserList setUserSelected={setUserSelected} setOpenModal3={setOpenModal3} />
                        ) : (
                            <ChatList myMessages={myMessages} setMyMessages={setMyMessages} setUser={setUserSelected} refresh={refreshTrigger} />
                        )
                    }

                </div>
                <div className="flex-1 overflow-y-scroll scrollbar-hide border-2 rounded bg-telegramBack">
                    {
                        userSelected && chatOfUser ? (
                            <Chat setText={setText} sendChat={sendChat2} text={text} changeText={changeText} chat={chatOfUser} user={userSelected} setUser={setUserSelected} />
                        ) : (
                            <div className="text-center mt-[30%]">
                                <span className="font-bold text-sm text-telegramText">
                                    هیچ چتی انتخاب نشده است.
                                </span>
                            </div>
                        )
                    }
                    {/* <Chat chat={chat} user={user} /> */}
                </div>
            </div>
            <div className="md:hidden border-r border-gray-200 overflow-y-scroll scrollbar-hide bg-telegram rounded w-full h-screen ">
                {
                    userSelected && chatOfUser ? (
                        <>
                            <Chat setText={setText} sendChat={sendChat2} text={text} changeText={changeText} chat={chatOfUser} user={userSelected} setUser={setUserSelected} />
                        </>
                    ) : (
                        <>
                            <div className="flex w-full text-center bg-amber-400-contrast text-white p-2">
                                <img onClick={(e) => { navigate(-1) }} src={closePic} className="h-8 w-8 bg-red rounded cursor-pointer m-2"></img>
                                <img onClick={(e) => { setOpenModal2(true) }} src={newThing} className="h-8 w-8 bg-black rounded cursor-pointer m-2"></img>
                                <img onClick={(e) => { setOpenModal3(!openModal3) }} src={usersPic} className="h-8 w-8 bg-black rounded cursor-pointer m-2"></img>
                                {
                                    openModal3 ? (
                                        <span className="mt-3 mr-6">مخاطبین</span>
                                    ) : (
                                        <span className="mt-3 mr-6">لیست چت ها</span>
                                    )
                                }

                            </div>
                            {
                                openModal3 ? (
                                    <UserList setUserSelected={setUserSelected} setOpenModal3={setOpenModal3} />
                                ) : (
                                    <ChatList myMessages={myMessages} setMyMessages={setMyMessages} setUser={setUserSelected} refresh={refreshTrigger} />
                                )
                            }
                        </>
                    )
                }

            </div>
            {openModal && (
                <Modal
                    title={"متن پیام"}
                    description={textToShow}
                    deleteAction={null}
                    setOpenModal={setOpenModal}
                />
            )}

            <PopUp
                title='نوع گروه و یا کانال را انتخاب کنید:'
                content={
                    <>
                        <p className="m-2">نوع:</p>
                        <Select
                            name="kind"
                            placeholder="گروه یا کانال"
                            options={grpChnlOptions}
                            onChange={handleGrpChnlChange}
                            value={grpChnl}
                        />
                        {
                            showAllUser ? (
                                <>
                                    <form
                                        onSubmit={addSubGroup}
                                        className="flex flex-col pr-5 items-center justify-center flex-wrap mx-5 md:mx-10"
                                        action=""
                                    >
                                        {
                                            grpChnl ? (
                                                grpChnl.value === 'گروه' ? (
                                                    <input
                                                        className="border my-3 border-lightgray rounded-md outline-none px-2 py-1"
                                                        name="username"
                                                        type="text"
                                                        onChange={grpNameHandler}
                                                        value={grpName}
                                                        placeholder="نام گروه"
                                                    />
                                                ) : grpChnl.value === 'کانال' ? (
                                                    <input
                                                        className="border my-3 border-lightgray rounded-md outline-none px-2 py-1"
                                                        name="username"
                                                        type="text"
                                                        onChange={grpNameHandler}
                                                        value={grpName}
                                                        placeholder="نام کانال"
                                                    />
                                                ) : ''
                                            ) : ''
                                        }
                                        <div className="flex flex-wrap justify-start">
                                            {users ? (
                                                users
                                                    .map((user, index) => {
                                                        return (
                                                            user.kodemelli !== localStorage.getItem('lgn').split('=')[1] ? (
                                                                <div
                                                                    key={user.kodemelli}
                                                                    className="flex w-[180px] md:w-[250px] items-center justify-start"
                                                                >
                                                                    <input
                                                                        className="w-5 h-5"
                                                                        name={user.kodemelli}
                                                                        id={user.kodemelli}
                                                                        type="checkBox"
                                                                        defaultChecked={
                                                                            checkedSubGroups.length
                                                                                ? checkedSubGroups.includes(user.kodemelli)
                                                                                : ""
                                                                        }
                                                                    />
                                                                    <label className="p-2" htmlFor="subUserName">
                                                                        {user.name}
                                                                    </label>
                                                                </div>) : null
                                                        );
                                                    })
                                            ) : (
                                                <p>کاربری ثبت نشده است</p>
                                            )}
                                        </div>
                                        <LoadingButton
                                            title={"ذخیره"}
                                            Tstyle={
                                                "inline-flex items-center my-6 text-center justify-center max-w-[200px] px-6 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-darkBlue transition ease-in-out duration-150"
                                            }
                                            type={"submit"}
                                            loadingBtn={loadingBtn2}
                                        />
                                    </form>
                                </>
                            ) : ''
                        }
                    </>
                }
                showPopUp={openModal2}
                setShowPopUp={setOpenModal2}
            />

        </>
    );
};
export default ChatPage;
