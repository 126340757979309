import React from "react";
const Note = ({
  title,
  content,
  btnTitleOne,
  btnTitleTwo,
  btnActOne,
  btnActTwo,
  role,
  visitor,
  loggedInUser,
}) => {
  return (
    <>
      <div className="bg-white mx-auto w-[95%] xl:w-full my-5 p-5 relative rounded-md">
        <h1>{title}</h1>
        <div className="my-5">{content}</div>
        <div className="flex">
          {/*visitor === loggedInUser.name ||*/ role === "Manager" ? (
            <>
            {title==="مجموع پرداختی‌ها"?'':(
              <button
              onClick={() => btnActOne()}
              className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
            >
              {btnTitleOne}
            </button>
            )}
              
              <button
                onClick={() => btnActTwo()}
                className="text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-2 md:right-10  py-2 absolute text-center rounded-md bg-white text-black border"
              >
                {btnTitleTwo}
              </button>
            </>
          ) : title === "مجموع پرداختی‌ها" ? (
             role === "sale" ? (
              <>
                <button
                  onClick={() => btnActOne()}
                  className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
                >
                  {btnTitleOne}
                </button>
                <button
                  onClick={() => btnActTwo()}
                  className="text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-2 md:right-10  py-2 absolute text-center rounded-md bg-white text-black border"
                >
                  {btnTitleTwo}
                </button>
              </>
            ) : (
              <button
                onClick={() => btnActTwo()}
                className="text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-2 md:right-10  py-2 absolute text-center rounded-md bg-white text-black border"
              >
                {btnTitleTwo}
              </button>
            )
          ) : role === "installer" || role === "transport" ? (
            <button
              onClick={() => btnActTwo()}
              className="text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-2 md:right-10  py-2 absolute text-center rounded-md bg-white text-black border"
            >
              {btnTitleTwo}
            </button>
          ) : role==='qc'?(
            role === "qc" && title === "یادداشت بخش کنترل کیفیت"
              ? (<button
                onClick={() => btnActOne()}
                className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
              >
                {btnTitleOne}
              </button>): null
          ): role==='sale'?(
            role === "sale" && title === "یادداشت بخش فروش"
              ? (<button
                onClick={() => btnActOne()}
                className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
              >
                {btnTitleOne}
              </button>): null
          ): role==='product_manager'?(
            role === "product_manager" && title === "یادداشت مدیر تولید"
              ? (<button
                onClick={() => btnActOne()}
                className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
              >
                {btnTitleOne}
              </button>): null
          ): role==='coord'?(
            role === "coord" && title === "یادداشت بخش هماهنگی"
              ? (<button
                onClick={() => btnActOne()}
                className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
              >
                {btnTitleOne}
              </button>): null
          ):
              role ==="Manager" && title === "یادداشت بخش کنترل کیفیت" ||
              role ==="Manager" && title === "یادداشت بخش فروش" ||
              role ==="Manager" && title === "یادداشت بخش هماهنگی" ||
              role ==="Manager" && title === "یادداشت مدیر تولید" ||
              role==="product_manager" && title==="یادداشت مدیر تولید"||
              role==="qc" && title === "یادداشت بخش کنترل کیفیت" ||
              role==="coord" && title==="یادداشت بخش هماهنگی"
              ? (<button
                onClick={() => btnActOne()}
                className={`text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-[140px] md:right-48 py-2 absolute text-center rounded-md bg-black text-white`}
              >
                {btnTitleOne}
              </button>): null}
              <button
                onClick={() => btnActTwo()}
                className="text-xs md:text-sm min-w-[120px] md:min-w-[150px] right-2 md:right-10  py-2 absolute text-center rounded-md bg-white text-black border"
              >
                {btnTitleTwo}
              </button>
        </div>
      </div>
    </>
  );
};
export default Note;
