export const optionsTwo = [
  { label: "همه", value: "همه", id: 300 },
  // { label: "ویزیت", value: "ویزیت", id: 400 },
  // { label: "قرارداد", value: "قرارداد", id: 500 },
  // { label: "هماهنگی", value: "هماهنگی", id: 600 },
  // { label: "تولید", value: "تولید", id: 700 },
  // { label: "کنترل کیفی", value: "کنترل کیفی", id: 800 },
  // { label: "بارگیری", value: "بارگیری", id: 900 },
  // { label: "در حال نصب", value: "در حال نصب", id: 106 },
  // { label: "تحویل به مشتری", value: "تحویل به مشتری", id: 1000 },
  { label: "ویزیت", value: "ویزیت", id: 400 },
  { label: "قرارداد", value: "قرارداد", id: 500 },
  { label: "هماهنگی", value: "هماهنگی", id: 600 },
  { label: "تولید", value: "تولید", id: 700 },
  { label: "کنترل کیفی", value: "کنترل کیفی", id: 800 },
  { label: "آماده بارگیری", value: "آماده بارگیری", id: 900 },
  { label: "بارگیری", value: "بارگیری", id: 950 },
  { label: "آماده نصب", value: "آماده نصب", id: 1000 },
  { label: "در حال نصب", value: "در حال نصب", id: 1050 },
  { label: "تحویل به مشتری", value: "تحویل به مشتری", id: 1100 },
  { label: "اتمام پروژه", value: "اتمام پروژه", id: 1200 },
];
export const dataLabel = [
  { label: "آدرس", slug: "address" },
  { label: "ویزیتور", slug: "nazer" },
  { label: "نام مالک", slug: "name" },
  { label: "نام‌خانوادگی مالک", slug: "family" },
  { label: "شماره پروژه", slug: "id1" },
  { label: "تلفن", slug: "phone" },
  { label: " امتیاز پروژه", slug: "rating" },
  { label: "تاریخ شروع", slug: "date" },

  { label: "تعداد طبقات", slug: "floor" },
  { label: "تعداد تقریبی درب و پنجره", slug: "winno" },
  { label: "نوع شیشه", slug: "glasskind" },
  { label: "توری", slug: "toory" },
  { label: "مسئول حمل", slug: "shippingOfficer" },
  { label: "مسئول نصب", slug: "installationOfficer" },
  { label: "وضعیت پرونده", slug: "feval" },
  { label: "توضیحات ثبت", slug: "explanation" },
  { label: "ماشین مناسب حمل", slug: "cartype" },
];

export const options = [
  { label: "ویزیت", value: "ویزیت", id: 100 },
  { label: "قرارداد", value: "قرارداد", id: 101 },
  { label: "هماهنگی", value: "هماهنگی", id: 102 },
  { label: "تولید", value: "تولید", id: 103 },
  { label: "کنترل کیفی", value: "کنترل کیفی", id: 104 },
  { label: "آماده بارگیری", value: "آماده بارگیری", id: 105 },
  { label: "بارگیری", value: "بارگیری", id: 106 },
  { label: "آماده نصب", value: "آماده نصب", id: 107 },
  { label: "در حال نصب", value: "در حال نصب", id: 108 },
  { label: "تحویل به مشتری", value: "تحویل به مشتری", id: 109 },
  { label: "اتمام پروژه", value: "اتمام پروژه", id: 110 },
];

export const roleOptions = [
  { label: "واحد مالی", value: "accountant", id: 100 },
  { label: "هماهنگی", value: "coord", id: 101 },
  { label: "نصاب", value: "installer", id: 102 },
  { label: "مدیر کل", value: "Manager", id: 103 },
  { label: "مدیر تولید", value: "product_manager", id: 104 },
  { label: "کنترل کیفی", value: "qc", id: 105 },
  { label: "فروش", value: "sale", id: 106 },
  { label: "بارگیری", value: "transport", id: 107 },
  { label: "ویزیتور", value: "Visitor", id: 108 },
  // { label: "مشتری", value: "customer", id: 109 },
];
export const carOptions = [
  { label: "وانت پراید", value: "وانت پراید", id: 100 },
  { label: "وانت پیکان", value: "وانت پیکان", id: 101 },
  { label: "نیسان", value: "نیسان", id: 102 },
  { label: "خاور", value: "خاور", id: 103 },
  { label: "10 چرخ", value: "10 چرخ", id: 104 },
  { label: "تریلی", value: "تریلی", id: 105 },
];
export const grpChnlOptions = [
  { label: "گروه", value: "گروه", id: 100 },
  { label: "کانال", value: "کانال", id: 101 },
];

export const addUserFields = [
  { name: "نقش", slug: "naghsh" },
  { name: "نام", slug: "name" },
  { name: "کد ملی", slug: "kodemelli" },
  { name: "شماره موبایل", slug: "shemaremobile" },
  { name: "نام کاربری", slug: "username" },
  { name: "رمز عبور", slug: "passwo" },
];

export const survey =[
  {text:"آیا زمان نصب و اجرای پروژه همان زمان از قبل تعیین شده بوده است؟"},
  {text:"آیا تمام درب و پنجره‌ها با طرح مورد تائید هنگام قرارداد تطابق دارد؟"},
  {text:"آیا تمام درب و پنجره‌ها به خوبی باز و بسته می‌شود؟"},
  {text:"آیا تمام درب و پنجره‌ها به خوبی آب‌بندی شده‌است؟"},
  {text:"آیا زمان نصب درب و پنجره‌ها لیبل‌های بیرونی جدا شده‌است؟"},
  {text:"آیا قسمت بیرونی درب و پنجره‌ها بعد از نصب با پارچه مرطوب تمیز شده‌است؟"},
  {text:"آیا تمامی شیشه‌ها سالم و مورد تائید است؟"},
  {text:"آیا بر روی تمام لولاها کاور نصب گردیده‌است؟"},
  {text:"آیا نحوه برخورد و اجرای پرسنل نصب رضایت‌بخش بوده‌است؟"},
  {text:"آیا در صورت داشتن پروژه‌های دیگر مایل به همکاری با مجموعه عایق نصر هستید؟"},



  
]
