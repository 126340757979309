import React from "react";

function ChatItem(props) {
  // console.log(props.item, 'as a new');
  return (
    <div key={props.userCode} className="flex items-center ml-4 py-4 px-4 cursor-pointer hover:bg-gray-100" onClick={() => {
      if (props.item && props.item.receivergroup === null && props.item.receiverchannel === null) {
        props.setUser(
          {
            id: props.userCode,
            name: props.name,
            avatar: "https://via.placeholder.com/150x150",
            receivercode: props.item.receivercode,
            sendercode: props.item.sendercode,
          }
        );
      } else if (props.item && props.item.receivergroup !== null && props.item.receiverchannel === null) {
        props.setUser(
          {
            id: props.userCode,
            name: props.item.groupname,
            avatar: "https://via.placeholder.com/150x150",
            groupId: props.item.receivergroup,
          }
        );
      } else if (props.item && props.item.receivergroup === null && props.item.receiverchannel !== null) {
        props.setUser(
          {
            id: props.userCode,
            name: props.item.channelname,
            avatar: "https://via.placeholder.com/150x150",
            channelId: props.item.receiverchannel,
          }
        );
      }
    }}>
      <img
        className="w-16 h-16 rounded-full object-cover object-center mr-4"
        src={props.photo}
        alt="Profile picture"
      />
      <div>
        {
          props.item.receivergroup ? (
            <h3 className="text-telegramText text-lg font-medium mr-4">{props.item.groupname}</h3>
          ) : props.item.receiverchannel ? (
            <h3 className="text-telegramText text-lg font-medium mr-4">{props.item.channelname}</h3>
          ) : (
            
              props.item?(
                props.item.sendercode===localStorage.getItem('lgn').split('=')[1]?(
                  <>
              <h3 className="text-telegramText text-lg font-medium mr-4">{props.name}</h3>
              <span className="text-telegramText text-xs mr-4">فرستنده:{props.item.sender}/گیرنده:{props.item.receiver}</span>
            </>
                ):(
                  <>
              <h3 className="text-telegramText text-lg font-medium mr-4">{props.item.sender}</h3>
              <span className="text-telegramText text-xs mr-4">فرستنده:{props.item.sender}/گیرنده:{props.item.receiver}</span>
            </>
                )
              ):null
            
            
          )
        }

        <p className="text-telegramText mr-4">{props.text}</p>
      </div>
    </div>
  );
}


export default ChatItem;
