import { Route, Routes } from "react-router-dom";
import AddNewProject from "./NewProject/AddNewProject";
import Login from "./Login";
import ProjectsList from "./ProjectsList";
import UProjectsList from "./UProjectList";
import SubGroup from "./SubGroups/SubGroup";
import Users from "./Users/Users";
import ProjectDetails from "./pDetails/ProjectDetails";
import Alarms from "./Alarms/Alarms";
import Layout from "./Layout";
import "react-toastify/dist/ReactToastify.css";
import MyChart from "./Chart/MyChart";
import { AuthProvider } from "./contexts/AuthProvider";
import { ToastContainer } from "react-toastify";
import Grading from "./Grading";
import Discount from "./Discount";
import Messages from "./Messages";
import CallList from "./CallList";
import History from "./History";
import DrawingCanvas from "./DrawingCanvas/DrawingCanvas";
import TicketDetail from "./TicketDetail";
import Board from "./Board";
import ChatPage from "./ChatPage";
import Report from "./Report";
import { useEffect } from "react";
import { requestForToken } from "./firebase";
import AppLogout from "./General/AppLogout";
// import 'emoji-mart/css/emoji-mart.css';


function App() {
  useEffect(() => {
    requestForToken();
  }, [])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={true}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Login />
          </AuthProvider>
        }
      />
      <Route
        path={`ticket-detail/:id`}
        element={
          <>
            <Layout children={<TicketDetail />} />
          </>
        }
      />
      {/* <Route
        path='intro'
        element={
          <>
            <Layout children={<IntroPage />} />
          </>
        }
      /> */}
      <Route
        path="chat"
        element={
          <>
            <ChatPage />
          </>
        }
      />
      <Route
        path="whiteboard2"
        element={
          <>
            <Board />
          </>
        }
      />
      <Route
        path="whiteboard"
        element={
          <>
            <DrawingCanvas />
          </>
        }
      />
      <Route
        path="new-project"
        element={
          <>
            <Layout children={<AddNewProject />} />
          </>
        }
      />
      <Route
        path="projects-list"
        element={
          <>
            <Layout children={<ProjectsList />} />
          </>
        }
      />
      <Route
        path="unsuccessful-projects-list"
        element={
          <>
            <Layout children={<UProjectsList />} />
          </>
        }
      />
      <Route
        path="call-list"
        element={
          <>
            <Layout children={<CallList />} />
          </>
        }
      />
      <Route
        path="history"
        element={
          <>
            <Layout children={<History />} />
          </>
        }
      />
      <Route
        path="subgroup"
        element={
          <>
            <Layout children={<SubGroup />} />
          </>
        }
      />
      <Route
        path="users"
        element={
          <>
            <Layout children={<Users />} />
          </>
        }
      />
      <Route
        path={`project-details/:id`}
        element={
          <>
            <Layout children={<ProjectDetails />} />
          </>
        }
      />
      <Route
        path="alarms"
        element={
          <>
            <Layout children={<Alarms />} />
          </>
        }
      />
      <Route
        path="chart"
        element={
          <>
            <Layout children={<MyChart />} />
          </>
        }
      />
      <Route
        path="report"
        element={
          <>
            <Layout children={<Report />} />
          </>
        }
      />
      <Route
        path="grading"
        element={
          <>
            <Layout children={<Grading />} />
          </>
        }
      />
      <Route
        path="discount"
        element={
          <>
            <Layout children={<Discount />} />
          </>
        }
      />
      <Route
        path="messages"
        element={
          <>
            <Layout children={<Messages />} />
          </>
        }
      />
      <Route
        path="/*"
        element={
          <>
            <Layout children={<ProjectsList />} />
          </>
        }
      />
    </Routes>
  );
}

export default App;
