import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Note from "../General/Note";
import { successNotify, warningNotify } from "../General/notifications";
import PopUp from "../General/PopUp";
import { dateChange } from "../dateConvertor";
import TextLoading from "../General/TextLoading";
import LoadingButton from "../General/LoadingButton";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker, { DateObject } from "react-multi-date-picker";
import JalaliDate from "../dateConvertor";
import { useRole } from "../context/roleProvider";

const Notes = ({ selectedDay, setSelectedDay, setEndDate, nsbDate, setNsbDate }) => {
  const { loggedInUserInfo } = useRole();
  const [totalPayment, setTotalPayment] = useState(null);
  const [paymentsList, setPaymentsList] = useState(null);
  const [detailsList, setDetailsList] = useState(null);
  const [saleNotes, setSaleNotes] = useState(null);
  const [zeroNotes, setZeroNotes] = useState(null);
  const [oneNotes, setOneNotes] = useState(null);
  const [twoNotes, setTwoNotes] = useState(null);
  const [showPaymentPopUp, setShowPaymentPopUp] = useState(false);
  const [showPaymentListPopUp, setShowPaymentListPopUp] = useState(false);
  const [showDetailsPopUp, setShowDetailsPopUp] = useState(false);
  const [showProductionPopUp, setShowProductionPopUp] = useState(false);
  const [showCoordinationPopUp, setShowCoordinationPopUp] = useState(false);
  const [showSalePopUp, setShowSalePopUp] = useState(false);
  const [showSaleList, setShowSaleList] = useState(false);
  const [showCoordinationList, setShowCoordinationList] = useState(false);
  const [showQualityList, setShowQualityList] = useState(false);
  const [showProductionList, setShowProductionList] = useState(false);
  const { id } = useParams();
  const [showQualityPopUp, setShowQualityPopUp] = useState(false);
  const [showAllDetailsPopUp, setShowAllDetailsPopUp] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState({
    info: false,
    payment: false,
    details: false,
    zero: false,
    one: false,
    two: false,
    sale: false,
  });

  useEffect(() => {
    if (
      showQualityPopUp ||
      showAllDetailsPopUp ||
      showProductionList ||
      showQualityList ||
      showCoordinationList ||
      showSaleList ||
      showCoordinationPopUp ||
      showSalePopUp ||
      showProductionPopUp ||
      showDetailsPopUp ||
      showPaymentListPopUp ||
      showPaymentPopUp
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [
    showQualityPopUp,
    showAllDetailsPopUp,
    showProductionList,
    showQualityList,
    showSaleList,
    showSalePopUp,
    showCoordinationList,
    showCoordinationPopUp,
    showProductionPopUp,
    showDetailsPopUp,
    showPaymentListPopUp,
    showPaymentPopUp,
  ]);

  const getProjectDetails = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/api4.php?id=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data = await response.json();
      setProjectDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    // alert('1');
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].value.match(/^$|\s +/) && e.target[i].type !== "submit") {
        warningNotify(`لطفا ${e.target[i].id} را به درستی وارد نمایید`);
        return 1;
      }
    }
    let nsbDateValue = 'null';
    if (loggedInUserInfo[0].naghsh === "qc") {
      // nsbDate = formData.get("nsbdate");
      if (nsbDate && nsbDate.year) {
        nsbDateValue = JalaliDate.jalaliToGregorian(
          nsbDate.year,
          nsbDate.month,
          nsbDate.day
        ).join("-");
      } else {
        nsbDateValue = nsbDate;
      }
    }
    let width = formData.get("projectLength");
    let m2 = formData.get("projectSquareMeter");
    let cost = '';
    try {
      cost = formData.get("projectCost").replaceAll(',', '');
    } catch (error) {
      cost = 'null';;
      console.log(error);
    }
    // let dtt = formData.get('calcal');
    // let year = dtt.substring(0, dtt.indexOf('/'));
    // let month=dtt.substring(dtt.indexOf('/')+1, dtt.indexOf('/', dtt.indexOf('/')+1));
    // let day=dtt.substring(dtt.indexOf('/', dtt.indexOf('/')+1)+1);
    // alert(year+' '+month+' '+day);
    let tr;
    if (selectedDay && selectedDay.year) {
      tr = JalaliDate.jalaliToGregorian(
        selectedDay.year,
        selectedDay.month,
        selectedDay.day
      ).join("-");
    } else {
      tr = selectedDay;
    }
    let insfee = '';
    try {
      insfee = formData.get("installationCost").replaceAll(',', '');
    } catch (error) {
      insfee = 'null';
      console.log(error);
    }
    let slper = formData.get("percentageOfSale");
    setLoadingBtn((prev) => ({ ...prev, info: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvdt.php?width=${width}&prjno=${id}&m2=${m2}&cost=${cost}&tr=${tr}&if=${insfee}&sp=${slper}&indt=${nsbDateValue}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      console.log(ack, "ack");
      if (ack) {
        successNotify("با موفقیت انجام شد");
        e.target.reset();
        getProjectDetails();
        setEndDate(selectedDay);
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, info: false }));
    } catch (error) {
      console.log(error);
      setLoadingBtn((prev) => ({ ...prev, info: false }));
      warningNotify("لطفا دوباره تلاش کنید");
    }
    setProjectDetails(null);
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    if (paymentsList) {
      const calculateTotalPayment = () => {
        let total = 0;
        for (let i = 0; i < paymentsList.length; i++) {
          total = total + Number(paymentsList[i].fee);
        }
        setTotalPayment(total);
      };
      calculateTotalPayment();
    }
  }, [paymentsList]);

  const saveNoteSale = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let noteSale = formData.get("noteSale");
    if (noteSale.match(/^$|\s +/)) {
      warningNotify("لطفا متن یادداشت را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, sale: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvnt.php?note=${noteSale}&prjno=${id}&noteno=3`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      showSaleNotesList();
      console.log(ack, "ack");
      if (ack) {
        successNotify("با موفقیت ثبت شد");
        setShowSalePopUp(false);
        warningNotify("لطفا دوباره تلاش کنید");
      } else {
      }
      setLoadingBtn((prev) => ({ ...prev, sale: false }));
      e.target.reset();
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, sale: false }));
    }
  };
  const saveNoteZero = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let noteZero = formData.get("noteZero");
    if (noteZero.match(/^$|\s +/)) {
      warningNotify("لطفا متن یادداشت را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, zero: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvnt.php?note=${noteZero}&prjno=${id}&noteno=0`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      showCoordinationNotesList();
      console.log(ack, "ack");
      if (ack) {
        successNotify("با موفقیت ثبت شد");
        setShowCoordinationPopUp(false);
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, zero: false }));
      e.target.reset();
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, zero: false }));
    }
  };
  const saveNoteOne = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let noteOne = formData.get("noteOne");
    console.log(noteOne);
    if (noteOne.match(/^$|\s +/)) {
      successNotify("لطفا متن یادداشت را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, one: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvnt.php?note=${noteOne}&prjno=${id}&noteno=1`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      console.log(ack, "ack");
      if (ack) {
        e.target.reset();
        showProductionNotesList();
        setShowProductionPopUp(false);
        successNotify("با موفقیت ثبت شد");
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, one: false }));
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, one: false }));
    }
  };
  const saveNoteTwo = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let noteTwo = formData.get("noteTwo");
    console.log(noteTwo);
    if (noteTwo.match(/^$|\s +/)) {
      warningNotify("لطفا متن یادداشت را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, two: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvnt.php?note=${noteTwo}&prjno=${id}&noteno=2`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      console.log(ack, "ack");
      if (ack) {
        showQualityNotesList();
        successNotify("با موفقیت ثبت شد");
        setShowQualityPopUp(false);
        e.target.reset();
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, two: false }));
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, two: false }));
    }
  };
  const savePayment = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let payment = formData.get("payment").replaceAll(',', '');
    if (payment.match(/^$|\s +/)) {
      warningNotify("لطفا مبلغ پرداختی را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, payment: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvpy.php?pay=${payment}&prjno=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      if (response.ok) {
        e.target.reset();
        successNotify("با موفقیت ثبت شد");
        setShowPaymentPopUp(false);
        showPaymentList();
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, payment: false }));
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, payment: false }));
    }
  };
  const saveDetails = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let details = formData.get("details");
    if (details.match(/^$|\s +/)) {
      warningNotify("لطفا توضیحات را به درستی وارد نمائید");
      return;
    }
    setLoadingBtn((prev) => ({ ...prev, details: true }));
    try {
      const response = await fetch(
        `../../nstd_site/apisvex.php?explan=${details}&prjno=${id}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let ack = await response.json();
      if (ack) {
        setShowDetailsPopUp(false);
        e.target.reset();
        successNotify("با موفقیت ثبت شد");
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn((prev) => ({ ...prev, details: false }));
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn((prev) => ({ ...prev, details: false }));
    }
    showDetails();
  };
  const showSaleNotesList = async () => {
    console.log('the load sale note is executed');
    try {
      const response = await fetch(
        `../../nstd_site/apilsnt.php?id=${id}&no=3`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let notesSale = await response.json();
      console.log(notesSale, 'noteeeeeeeeeeesaleeeeeeeeeeeeeeeee')
      setSaleNotes(notesSale);
    } catch (error) {
      console.log(error);
    }
  };
  const showCoordinationNotesList = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apilsnt.php?id=${id}&no=0`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let notesZero = await response.json();
      setZeroNotes(notesZero);
    } catch (error) {
      console.log(error);
    }
  };
  const handleProduction = () => {
    setShowProductionPopUp(true);
  };
  const showDetails = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apilsex.php?id=${id}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let detailsList = await response.json();
      setDetailsList(detailsList);
    } catch (error) {
      console.log(error);
    }
  };
  const handleProductionList = () => {
    setShowProductionList(true);
    showProductionNotesList();
  };
  const handleQuality = () => {
    setShowQualityPopUp(true);
    showQualityNotesList();
  };
  const handleQualityList = () => {
    setShowQualityList(true);
    showQualityNotesList();
  };
  const showProductionNotesList = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apilsnt.php?id=${id}&no=1`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let notesOne = await response.json();
      setOneNotes(notesOne);
    } catch (error) {
      console.log(error);
    }
  };
  const showQualityNotesList = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apilsnt.php?id=${id}&no=2`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      let notesTwo = await response.json();
      setTwoNotes(notesTwo);
    } catch (error) {
      console.log(error);
    }
  };
  const showPaymentList = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apilspy.php?id=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let paymentList = await response.json();
      setPaymentsList(paymentList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const showPaymentList = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apilspy.php?id=${id}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let paymentList = await response.json();
        setPaymentsList(paymentList);
      } catch (error) {
        console.log(error);
      }
    };
    showSaleNotesList();
    showDetails();
    showPaymentList();
    showQualityNotesList();
    showProductionNotesList();
    showCoordinationNotesList();
  }, []);

  useEffect(() => {
    if (paymentsList) {
      const calculateTotalPayment = () => {
        let total = 0;
        for (let i = 0; i < paymentsList.length; i++) {
          total = total + Number(paymentsList[i].fee);
        }
        setTotalPayment(total);
      };
      calculateTotalPayment();
    }
  }, [paymentsList]);

  const handleRegisterPayment = () => {
    setShowPaymentPopUp((prev) => !prev);
  };
  const handlePaymentsList = () => {
    showPaymentList();
    setShowPaymentListPopUp(true);
  };
  const handleExplanation = () => {
    setShowDetailsPopUp(true);
  };
  const handleExplanationList = () => {
    setShowAllDetailsPopUp(true);
    showDetails();
  };
  const handleSale = () => {
    setShowSalePopUp(true);
    showSaleNotesList();
  };
  const handleCoordination = () => {
    setShowCoordinationPopUp(true);
    showCoordinationNotesList();
  };
  const handleSaleList = () => {
    setShowSaleList(true);
    showSaleNotesList();
  };
  const handleCoordinationList = () => {
    setShowCoordinationList(true);
    showCoordinationNotesList();
  };
  const commaSeparated = (text) => {
    if (text === null) {
      return '';
    }
    let txt = text.replace(',', '');
    while (txt.includes(",")) {
      txt = txt.replace(',', '');
    }
    // alert(txt);
    let len = txt.length;
    let newTxt = '';
    let arr = [];
    for (let j = len - 1; j >= 0; j = j - 3) {
      arr.push(txt.substring(j - 2, j + 1));
    }
    arr.forEach((item, index) => {
      if (index !== 0) {
        newTxt = item + ',' + newTxt;
      } else {
        newTxt = item + newTxt;
      }
    })
    return newTxt;
  }
  return (
    <>
      <form
        onSubmit={handleUpdate}
        className="rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative"
      >
        <h1 className="mb-4">اطلاعات پروژه</h1>
        {
          loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh !== "transport" &&
              loggedInUserInfo[0].naghsh !== "coord" &&
              loggedInUserInfo[0].naghsh !== "product_manager" &&
              loggedInUserInfo[0].naghsh !== "qc" &&
              loggedInUserInfo[0].naghsh !== "installer" ? (<p className="my-3">پرداختی تا کنون :{totalPayment && totalPayment}</p>) : null
          ) : null
        }

        <div className="flex flex-col md:flex-row">
          {loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh === "sale" ||
              loggedInUserInfo[0].naghsh === "Manager" ? (
              <div className="flex flex-col md:flex-row md:items-center">
                <label className="min-w-[120px]" htmlFor="endDate">
                  تاریخ اتمام پروژه
                </label>
                <div className="rounded-md my-3 md:m-3 border border-lightgray px-1">
                  <DatePicker
                    className="custom-input-two"
                    value={selectedDay && new DateObject(selectedDay)}
                    onChange={setSelectedDay}
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                    id="تاریخ"
                    name="calcal"
                  />
                </div>
              </div>
            ) : loggedInUserInfo[0].naghsh === "qc" ? (
              <div className="flex flex-col md:flex-row md:items-center">
                <label className="min-w-[120px]" htmlFor="endDate">
                  تاریخ نصب
                </label>
                <div className="rounded-md my-3 md:m-3 border border-lightgray px-1">
                  <DatePicker
                    className="custom-input-two"
                    value={nsbDate && new DateObject(nsbDate)}
                    onChange={setNsbDate}
                    calendar={persian}
                    locale={persian_fa}
                    name="nsbdate"
                    calendarPosition="bottom-right"
                    id="تاریخ"
                  />
                </div>
              </div>
            ) : null
          ) : null}
          <div className="flex flex-col md:flex-row md:items-center">
            {
              loggedInUserInfo ? (
                loggedInUserInfo[0].naghsh !== "transport" &&
                  loggedInUserInfo[0].naghsh !== "coord" &&
                  loggedInUserInfo[0].naghsh !== "product_manager" &&
                  loggedInUserInfo[0].naghsh !== "qc" &&
                  loggedInUserInfo[0].naghsh !== "installer" ? (
                  <>
                    <label className="min-w-[120px] mt-3" htmlFor="projectCost">
                      مبلغ پروژه
                    </label>
                    <input
                      id="مبلغ پروژه"
                      name="projectCost"
                      type="text"
                      className="rounded-md my-3 md:m-3 border border-lightgray px-1"
                      defaultValue={(projectDetails && projectDetails[0].cost) && commaSeparated(projectDetails[0].cost)}
                      onChange={(e) => {
                        let txt = e.currentTarget.value.replace(',', '');
                        while (txt.includes(",")) {
                          txt = txt.replace(',', '');
                        }
                        // alert(txt);
                        let len = txt.length;
                        let newTxt = '';
                        let arr = [];
                        for (let j = len - 1; j >= 0; j = j - 3) {
                          arr.push(txt.substring(j - 2, j + 1));
                        }
                        arr.forEach((item, index) => {
                          if (index !== 0) {
                            newTxt = item + ',' + newTxt;
                          } else {
                            newTxt = item + newTxt;
                          }
                        })
                        e.currentTarget.value = newTxt;
                      }}
                    />
                  </>
                ) : null
              ) : null
            }

          </div>
        </div>
        <div className="flex flex-col md:flex-row flex-wrap">
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="min-w-[120px]" htmlFor="projectLength">
              طول کار
            </label>
            <input
              className="rounded-md min-w-[200px] my-3 md:m-3 border border-lightgray px-1"
              name="projectLength"
              type="number"
              id="طول کار"
              defaultValue={projectDetails && projectDetails[0].width}
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="min-w-[120px]" htmlFor="projectSquareMeter">
              متر مربع کار
            </label>
            <input
              id="متر مربع کار"
              name="projectSquareMeter"
              type="number"
              className="rounded-md my-3 md:m-3 border border-lightgray px-1"
              defaultValue={projectDetails && projectDetails[0].m2}
            />
          </div>
          {loggedInUserInfo && projectDetails ? (
            loggedInUserInfo[0].naghsh === "installer" ||
              loggedInUserInfo[0].naghsh === "Manager" ||
              loggedInUserInfo[0].naghsh === "sale" /*||
              projectDetails[0].nazer === loggedInUserInfo[0].name*/ ? (
                <div className="flex flex-col md:flex-row md:items-center">
                  <label className="min-w-[120px]" htmlFor="installationCost">
                    مبلغ نصب
                  </label>
                  <input
                    id="مبلغ نصب"
                    name="installationCost"
                    type="text"
                    className="rounded-md min-w-[200px] my-3 md:m-3 border border-lightgray px-1"
                    defaultValue={(projectDetails && projectDetails[0].insfee) && commaSeparated(projectDetails[0].insfee)}
                    onChange={(e) => {
                      let txt = e.currentTarget.value.replace(',', '');
                      while (txt.includes(",")) {
                        txt = txt.replace(',', '');
                      }
                      // alert(txt);
                      let len = txt.length;
                      let newTxt = '';
                      let arr = [];
                      for (let j = len - 1; j >= 0; j = j - 3) {
                        arr.push(txt.substring(j - 2, j + 1));
                      }
                      arr.forEach((item, index) => {
                        if (index !== 0) {
                          newTxt = item + ',' + newTxt;
                        } else {
                          newTxt = item + newTxt;
                        }
                      })
                      e.currentTarget.value = newTxt;
                    }}
                  />
                </div>
              ) : null
          ) : null}
          {loggedInUserInfo && projectDetails ? (
            loggedInUserInfo[0].naghsh === "Manager" /*||
              projectDetails[0].nazer === loggedInUserInfo[0].name*/ ? (
                <div className="flex flex-col md:flex-row md:items-center">
                  <label className="min-w-[120px]" htmlFor="percentageOfSale">
                    درصد فروش
                  </label>
                  <input
                    id="درصد فروش"
                    name="percentageOfSale"
                    type="number"
                    className="rounded-md min-w-[200px] my-3 md:m-3 border border-lightgray px-1"
                    defaultValue={projectDetails && projectDetails[0].slper}
                  />
                </div>
              ) : null
          ) : null}
        </div>
        {loggedInUserInfo && projectDetails ? (
          projectDetails[0].nazer === loggedInUserInfo[0].name && loggedInUserInfo[0].naghsh !== "Manager" &&
            loggedInUserInfo[0].naghsh !== "qc" &&
            loggedInUserInfo[0].naghsh !== "sale" ? (
            ''
            // <LoadingButton
            //   title={"به روزرسانی"}
            //   type={"submit"}
            //   loadingBtn={loadingBtn.info}
            //   Tstyle={`bg-black text-xs md:text-sm rounded-md text-white absolute left-10 w-[144px] h-[44px] flex justify-center items-center cursor-pointer`}
            // />
          ) : projectDetails && (
            loggedInUserInfo[0].naghsh === "Manager" ||
            loggedInUserInfo[0].naghsh === "qc" ||
            loggedInUserInfo[0].naghsh === "sale") ? (
            <LoadingButton
              title={"بروزرسانی"}
              type={"submit"}
              loadingBtn={loadingBtn.info}
              Tstyle={`bg-black text-xs md:text-sm rounded-md text-white absolute left-10 w-[144px] h-[44px] flex justify-center items-center cursor-pointer`}
            />
          ) : null
        ) : null}
      </form>
      {
        loggedInUserInfo ? (
          loggedInUserInfo[0].naghsh === 'transport' || loggedInUserInfo[0].naghsh === 'installer' ? null : (
            <div>
              {
                loggedInUserInfo[0].naghsh !== "coord" && loggedInUserInfo[0].naghsh !== "product_manager" && loggedInUserInfo[0].naghsh !== "qc" ? (
                  <div className="mb-10 w-[95%] mx-auto md:w-full">
                    <Note
                      loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                      visitor={projectDetails && projectDetails[0].nazer}
                      role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                      title={"مجموع پرداختی‌ها"}
                      content={
                        totalPayment ? (
                          <div className="flex items-center">
                            <p>{commaSeparated(totalPayment.toString(10))}</p>
                            <p className="mr-2">تومان</p>
                          </div>
                        ) : (
                          "مبلغی برای نمایش وجود ندارد"
                        )
                      }
                      btnTitleOne={"ثبت پرداخت"}
                      btnTitleTwo={"لیست پرداختی‌ها"}
                      btnActOne={handleRegisterPayment}
                      btnActTwo={handlePaymentsList}
                    />
                  </div>
                ) : null
              }
              <div className="mb-10 w-[95%] mx-auto md:w-full">
                <Note
                  loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                  visitor={projectDetails && projectDetails[0].nazer}
                  role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                  title={"توضیحات"}
                  content={
                    detailsList ? (
                      detailsList.length ? (
                        <p className="text-justify">
                          {detailsList[detailsList.length - 1].explan.split("$")}
                        </p>
                      ) : (
                        "توضیحی برای نمایش وجود ندارد"
                      )
                    ) : (
                      <TextLoading />
                    )
                  }
                  btnTitleOne={"افزودن جزئیات"}
                  btnTitleTwo={"جزئیات پیشین"}
                  btnActOne={handleExplanation}
                  btnActTwo={handleExplanationList}
                />
              </div>
              <div className="mb-10 w-[95%] mx-auto md:w-full">
                <Note
                  loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                  visitor={projectDetails && projectDetails[0].nazer}
                  role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                  title={"یادداشت بخش فروش"}
                  content={
                    <div className="pb-2">
                      {saleNotes ? (
                        saleNotes.length ? (
                          <>
                            <p className="text-justify">
                              {saleNotes[0].note.split("$3$")}
                            </p>
                            <div className="absolute py-2 md:m-0 flex text-graytext text-xs md:text-sm left-5 bottom-4">
                              <p>{dateChange(saleNotes[0]._date)}</p>
                              <p className="mr-2">{saleNotes[0]._date.split(" ")[1]}</p>
                            </div>
                          </>
                        ) : (
                          <p>یادداشتی برای نمایش وجود ندارد</p>
                        )
                      ) : (
                        <TextLoading />
                      )}
                    </div>
                  }
                  btnTitleOne={"یادداشت جدید"}
                  btnTitleTwo={"یادداشت‌های پیشین"}
                  btnActOne={handleSale}
                  btnActTwo={handleSaleList}
                />
              </div>
              <div className="mb-10 w-[95%] mx-auto md:w-full">
                <Note
                  loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                  visitor={projectDetails && projectDetails[0].nazer}
                  role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                  title={"یادداشت بخش هماهنگی"}
                  content={
                    <div className="pb-2">
                      {zeroNotes ? (
                        zeroNotes.length ? (
                          <>
                            <p className="text-justify">
                              {zeroNotes[0].note.split("$0$")}
                            </p>
                            <div className="absolute py-2 md:m-0 flex text-graytext text-xs md:text-sm left-5 bottom-4">
                              <p>{dateChange(zeroNotes[0]._date)}</p>
                              <p className="mr-2">{zeroNotes[0]._date.split(" ")[1]}</p>
                            </div>
                          </>
                        ) : (
                          <p>یادداشتی برای نمایش وجود ندارد</p>
                        )
                      ) : (
                        <TextLoading />
                      )}
                    </div>
                  }
                  btnTitleOne={"یادداشت جدید"}
                  btnTitleTwo={"یادداشت‌های پیشین"}
                  btnActOne={handleCoordination}
                  btnActTwo={handleCoordinationList}
                />
              </div>
              <div className="mb-10 w-[95%] mx-auto md:w-full">
                <Note
                  loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                  visitor={projectDetails && projectDetails[0].nazer}
                  role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                  title={"یادداشت مدیر تولید"}
                  content={
                    <div className="pb-2">
                      {oneNotes ? (
                        oneNotes.length ? (
                          <>
                            <p className="text-justify">
                              {oneNotes[0].note.split("$1$")}
                            </p>
                            <div className="absolute py-2 md:m-0 flex text-graytext text-xs md:text-sm left-5 bottom-4">
                              <p>{dateChange(oneNotes[0]._date)}</p>
                              <p className="mr-2">{oneNotes[0]._date.split(" ")[1]}</p>
                            </div>
                          </>
                        ) : (
                          <p>یادداشتی برای نمایش وجود ندارد</p>
                        )
                      ) : (
                        <TextLoading />
                      )}
                    </div>
                  }
                  btnTitleOne={"یادداشت جدید"}
                  btnTitleTwo={"یادداشت‌های پیشین"}
                  btnActOne={handleProduction}
                  btnActTwo={handleProductionList}
                />
              </div>
              <div className="mb-10 w-[95%] mx-auto md:w-full">
                <Note
                  loggedInUser={loggedInUserInfo ? loggedInUserInfo[0] : ""}
                  visitor={projectDetails && projectDetails[0].nazer}
                  role={loggedInUserInfo ? loggedInUserInfo[0].naghsh : ""}
                  title={"یادداشت بخش کنترل کیفیت"}
                  content={
                    <div className="pb-2">
                      {twoNotes ? (
                        twoNotes.length ? (
                          <>
                            <p className="text-justify">
                              {twoNotes[0].note.split("$2$")}
                            </p>
                            <div className="absolute py-2 md:m-0 flex text-graytext text-xs md:text-sm left-5 bottom-4">
                              <p>{dateChange(twoNotes[0]._date)}</p>
                              <p className="mr-2">{twoNotes[0]._date.split(" ")[1]}</p>
                            </div>
                          </>
                        ) : (
                          <p>یادداشتی برای نمایش وجود ندارد</p>
                        )
                      ) : (
                        <TextLoading />
                      )}
                    </div>
                  }
                  btnTitleOne={"یادداشت جدید"}
                  btnTitleTwo={"یادداشت‌های پیشین"}
                  btnActOne={handleQuality}
                  btnActTwo={handleQualityList}
                />
              </div>
            </div>
          )
        ) : null
      }
      {showPaymentPopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا مبلغ پرداختی را وارد نمائید"}
          content={
            <form
              onSubmit={savePayment}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                id="مبلغ پرداختی"
                name="payment"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
                onChange={(e) => {
                  let txt = e.currentTarget.value.replace(',', '');
                  while (txt.includes(",")) {
                    txt = txt.replace(',', '');
                  }
                  // alert(txt);
                  let len = txt.length;
                  let newTxt = '';
                  let arr = [];
                  for (let j = len - 1; j >= 0; j = j - 3) {
                    arr.push(txt.substring(j - 2, j + 1));
                  }
                  arr.forEach((item, index) => {
                    if (index !== 0) {
                      newTxt = item + ',' + newTxt;
                    } else {
                      newTxt = item + newTxt;
                    }
                  })
                  e.currentTarget.value = newTxt;
                }}
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.payment}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showPaymentPopUp}
          setShowPopUp={setShowPaymentPopUp}
        />
      )}
      {showPaymentListPopUp && (
        <PopUp
          hasButton={false}
          title={"لیست مبالغ پرداختی"}
          content={
            <>
              <table className="hidden md:inline-table w-[80%]">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="py-2">شناسه</th>
                    <th>شماره پروژه</th>
                    <th>مبلغ</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentsList ? (
                    paymentsList.length ? (
                      paymentsList.map((payment, index) => {
                        return (
                          <tr key={index}>
                            <td
                              title={payment.id1}
                              className="py-2 text-center bg-gray"
                            >
                              {payment.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {payment.project_id}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {payment.fee.split("$")}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              <p>{dateChange(payment._date)}</p>
                              <p>{payment._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center py-2" colSpan={4}>
                          {"پرداختی برای نمایش وجود ندارد"}
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {paymentsList ? (
                  paymentsList.length ? (
                    paymentsList.map((payment, index) => {
                      return (
                        <div
                          key={payment.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p className="w-[40%]">شناسه :</p>
                            <p className="text-left w-[60%]">{payment.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p className="w-[40%]">شماره پروژه :</p>
                            <p className="text-left w-[60%]">
                              {payment.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p className="w-[40%]">مبلغ :</p>
                            <p className="text-left w-[60%]">
                              {payment.fee.split("$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p className="w-[40%]">تاریخ :</p>
                            <div className="text-left w-[60%]">
                              <p>{dateChange(payment._date)}</p>
                              <p>{payment._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center py-2">
                      {"پرداختی برای نمایش وجود ندارد"}
                    </p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showPaymentListPopUp}
          setShowPopUp={setShowPaymentListPopUp}
        />
      )}
      {showDetailsPopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا توضیحات جدید را وارد نمائید"}
          content={
            <form
              onSubmit={saveDetails}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                id="توضیحات"
                name="details"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.details}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showDetailsPopUp}
          setShowPopUp={setShowDetailsPopUp}
        />
      )}
      {showAllDetailsPopUp && (
        <PopUp
          hasButton={false}
          title={"لیست یادداشت‌های پیشین"}
          content={
            <>
              <table className="w-[80%] hidden md:inline-table">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="py-2 px-2">شناسه</th>
                    <th className="min-w-[100px]">شماره پروژه</th>
                    <th>متن</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {detailsList ? (
                    detailsList.length ? (
                      detailsList.map((detail, index) => {
                        return (
                          <tr key={index}>
                            <td className="py-2 text-center bg-gray">
                              {detail.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {detail.project_id}
                            </td>
                            <td className="p-2 text-center bg-gray max-w-[300px] text-ellipsis overflow-hidden">
                              {detail.explan.split("$")}
                            </td>
                            <td className="py-2 pl-2 text-center bg-gray">
                              <p>{dateChange(detail._date)}</p>
                              <p>{detail._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-2">
                          جزئیاتی برای نمایش وجود ندارد
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {detailsList ? (
                  detailsList.length ? (
                    detailsList.map((detail, index) => {
                      return (
                        <div
                          key={detail.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شناسه :</p>
                            <p className="text-left w-2/3">{detail.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شماره پروژه :</p>
                            <p className="text-left w-2/3">
                              {detail.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>متن :</p>
                            <p className="text-left w-2/3">
                              {detail.explan.split("$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p>تاریخ :</p>
                            <div className="text-left w-2/3">
                              <p>{dateChange(detail._date)}</p>
                              <p>{detail._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">جزئیاتی برای نمایش وجود ندارد</p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showAllDetailsPopUp}
          setShowPopUp={setShowAllDetailsPopUp}
        />
      )}
      {showSalePopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا متن را وارد نمائید"}
          content={
            <form
              onSubmit={saveNoteSale}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                name="noteSale"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
                id="متن"
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.sale}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showSalePopUp}
          setShowPopUp={setShowSalePopUp}
        />
      )}
      {showCoordinationPopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا متن را وارد نمائید"}
          content={
            <form
              onSubmit={saveNoteZero}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                name="noteZero"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
                id="متن"
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.zero}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showCoordinationPopUp}
          setShowPopUp={setShowCoordinationPopUp}
        />
      )}
      {showSaleList && (
        <PopUp
          hasButton={false}
          title={"لیست یادداشت‌‌های پیشین"}
          content={
            <>
              <table className="w-[80%] hidden md:inline-table">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="p-2">شناسه</th>
                    <th className="min-w-[100px]">شماره پروژه</th>
                    <th>یادداشت</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {saleNotes ? (
                    saleNotes.length ? (
                      saleNotes.map((notes, index) => {
                        return (
                          <tr key={index}>
                            <td className="py-2 text-center bg-gray">
                              {notes.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {notes.project_id}
                            </td>
                            <td className="py-2 text-center bg-gray max-w-[300px] text-ellipsis overflow-hidden">
                              {notes.note.split("$3$")}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-2">
                          یادداشتی برای نمایش وجود ندارد
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {saleNotes ? (
                  saleNotes.length ? (
                    saleNotes.map((notes, index) => {
                      return (
                        <div
                          key={notes.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شناسه :</p>
                            <p className="text-left w-2/3">{notes.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شماره پروژه :</p>
                            <p className="text-left w-2/3">
                              {notes.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>متن :</p>
                            <p className="text-left w-2/3">
                              {notes.note.split("$0$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p>تاریخ :</p>
                            <div className="text-left w-2/3">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p colSpan={4} className="text-center">
                      یادداشتی برای نمایش وجود ندارد
                    </p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showSaleList}
          setShowPopUp={setShowSaleList}
        />
      )}
      {showCoordinationList && (
        <PopUp
          hasButton={false}
          title={"لیست یادداشت‌‌های پیشین"}
          content={
            <>
              <table className="w-[80%] hidden md:inline-table">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="p-2">شناسه</th>
                    <th className="min-w-[100px]">شماره پروژه</th>
                    <th>یادداشت</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {zeroNotes ? (
                    zeroNotes.length ? (
                      zeroNotes.map((notes, index) => {
                        return (
                          <tr key={index}>
                            <td className="py-2 text-center bg-gray">
                              {notes.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {notes.project_id}
                            </td>
                            <td className="py-2 text-center bg-gray max-w-[300px] text-ellipsis overflow-hidden">
                              {notes.note.split("$0$")}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-2">
                          یادداشتی برای نمایش وجود ندارد
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {zeroNotes ? (
                  zeroNotes.length ? (
                    zeroNotes.map((notes, index) => {
                      return (
                        <div
                          key={notes.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شناسه :</p>
                            <p className="text-left w-2/3">{notes.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شماره پروژه :</p>
                            <p className="text-left w-2/3">
                              {notes.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>متن :</p>
                            <p className="text-left w-2/3">
                              {notes.note.split("$0$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p>تاریخ :</p>
                            <div className="text-left w-2/3">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p colSpan={4} className="text-center">
                      یادداشتی برای نمایش وجود ندارد
                    </p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showCoordinationList}
          setShowPopUp={setShowCoordinationList}
        />
      )}
      {showProductionPopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا متن را وارد نمائید"}
          content={
            <form
              onSubmit={saveNoteOne}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                name="noteOne"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
                id="متن"
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.one}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showProductionPopUp}
          setShowPopUp={setShowProductionPopUp}
        />
      )}
      {showProductionList && (
        <PopUp
          hasButton={false}
          title={"لیست یادداشت‌‌های پیشین"}
          content={
            <>
              <table className="w-[80%] hidden md:inline-table">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="p-2">شناسه</th>
                    <th className="min-w-[100px]">شماره پروژه</th>
                    <th>یادداشت</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {oneNotes ? (
                    oneNotes.length ? (
                      oneNotes.map((notes, index) => {
                        return (
                          <tr key={index}>
                            <td className="py-2 text-center bg-gray">
                              {notes.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {notes.project_id}
                            </td>
                            <td className="p-2 text-center bg-gray overflow-hidden text-ellipsis">
                              {notes.note.split("$1$")}
                            </td>
                            <td className="pl-2 py-2 text-center bg-gray">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-2">
                          یادداشتی برای نمایش وجود ندارد
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {oneNotes ? (
                  oneNotes.length ? (
                    oneNotes.map((notes, index) => {
                      return (
                        <div
                          key={notes.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شناسه :</p>
                            <p className="text-left w-2/3">{notes.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شماره پروژه :</p>
                            <p className="text-left w-2/3">
                              {notes.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>متن :</p>
                            <p className="text-left w-2/3">
                              {notes.note.split("$1$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p>تاریخ :</p>
                            <div className="text-left w-2/3">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">
                      یادداشتی برای نمایش وجود ندارد
                    </p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showProductionList}
          setShowPopUp={setShowProductionList}
        />
      )}
      {showQualityPopUp && (
        <PopUp
          hasButton={false}
          title={"لطفا متن را وارد نمائید"}
          content={
            <form
              onSubmit={saveNoteTwo}
              className="w-[80%] flex flex-col justify-center"
            >
              <textarea
                name="noteTwo"
                className=" border border-lightgray rounded-md mb-5 p-3"
                cols={20}
                rows={8}
                id="متن"
              ></textarea>
              <LoadingButton
                loadingBtn={loadingBtn.two}
                Tstyle="rounded-md px-8 py-2 bg-darkBlue text-white flex justify-center"
                title="ذخیره"
                type="submit"
              />
            </form>
          }
          showPopUp={showQualityPopUp}
          setShowPopUp={setShowQualityPopUp}
        />
      )}
      {showQualityList && (
        <PopUp
          hasButton={false}
          title={"لیست یادداشت‌‌های پیشین"}
          content={
            <>
              <table className="w-[80%] hidden md:inline-table">
                <thead>
                  <tr className="bg-darkBlue text-white">
                    <th className="p-2">شناسه</th>
                    <th className="min-w-[100px]">شماره پروژه</th>
                    <th>یادداشت</th>
                    <th className="min-w-[100px]">تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {twoNotes ? (
                    twoNotes.length ? (
                      twoNotes.map((notes, index) => {
                        return (
                          <tr key={index}>
                            <td className="py-2 text-center bg-gray">
                              {notes.id1}
                            </td>
                            <td className="py-2 text-center bg-gray">
                              {notes.project_id}
                            </td>
                            <td className="p-2 text-center bg-gray overflow-hidden text-ellipsis">
                              {notes.note.split("$2$")}
                            </td>
                            <td className="p-2 text-center bg-gray">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-2">
                          یادداشتی برای نمایش وجود ندارد
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <TextLoading />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
                {twoNotes ? (
                  twoNotes.length ? (
                    twoNotes.map((notes, index) => {
                      return (
                        <div
                          key={notes.id1}
                          className="bg-white mb-3 border border-lightgray rounded-md"
                        >
                          <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شناسه :</p>
                            <p className="text-left w-2/3">{notes.id1}</p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>شماره پروژه :</p>
                            <p className="text-left w-2/3">
                              {notes.project_id}
                            </p>
                          </div>
                          <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex justify-between">
                            <p>متن :</p>
                            <p className="text-left w-2/3">
                              {notes.note.split("$2$")}
                            </p>
                          </div>
                          <div className="py-2 mx-3 text-right flex justify-between">
                            <p>تاریخ :</p>
                            <div className="text-left w-2/3">
                              <p>{dateChange(notes._date)}</p>
                              <p>{notes._date.split(" ")[1]}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">
                      یادداشتی برای نمایش وجود ندارد
                    </p>
                  )
                ) : (
                  <TextLoading />
                )}
              </div>
            </>
          }
          showPopUp={showQualityList}
          setShowPopUp={setShowQualityList}
        />
      )}
    </>
  );
};

export default Notes;
