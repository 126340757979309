import React, { useEffect, useRef, useState } from "react";
// import React from "react";
// import { useState } from "react";
import Select from "react-select";
import LoadingButton from "./General/LoadingButton";
import { successNotify, warningNotify } from "./General/notifications";

const Grading = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [gradeValue, setGradeValue] = useState(null);
  const [customer2, setCustomer2] = useState(null);
  const [searchText, setSearchText] = useState(null);
  // const [options, setOptions] = useState(null);
  // const [optionsTwo, setOptions2] = useState(null);

  const LOCAL_STORAGE_AUTH_KEY = "lgn";

  const getCustomerDetails = async () => {
    let data;
    if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
      data = {
        id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
        id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
      };
    }
    try {
      const response = await fetch(
        `./nstd_site/usapgt.php`,
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      let data1 = await response.json();
      // console.log(data1, 'data of users in grading');
      let d2 = [];
      data1.map((d1, index) => {
        if (d1.naghsh === 'customer') {
          let name = d1.rank;
          if (d1.rank) {
            d2.push({
              label: d1.name + " : " + d1.rank,
              value: d1.kodemelli,
              id: d1.kodemelli,
            });
          } else {
            d2.push({
              label: d1.name,
              value: d1.kodemelli,
              id: d1.kodemelli,
            });
          }
        }
      });
      setCustomer2(d2);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {

    getCustomerDetails();
  }, []);
  const serachCustomer = (e) => {
    if (e) {
      if (e.value) {
        // console.log(e, 'customer data selected');
        setCustomer(e);
        setGradeValue(options.filter((item)=>item.value===e.label.split(":")[1].trim()));
      } else {
        setCustomer({
          label: "کاربر تعریف نشده",
          value: "کاربر تعریف نشده",
          id: 100,
        });
      }
    } else {
      setCustomer(null);
    }
  };

  const saveBtnClick = async (e) => {
    if(!gradeValue.value){
      warningNotify("لطفا ورودی های خود را کنترل کنید.");
      return;
    }
    try {
      const response = await fetch(
        `./nstd_site/apisvgrdcus.php?cd=${customer.id}&rnk=${gradeValue.value}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data = await response.json();
      if (data) {
        successNotify("با موفقیت ثبت شد.");
        getCustomerDetails();
      }
      console.log(data, 'ranking api response');
    } catch (error) {
      console.log(error);
      warningNotify("مشکل در ثبت اطلاعات");
    }
    console.log(customer.value, 'name of customer');
    console.log(e);
  }
  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const handleCustomerGrade = async (e) => {
    if (e) {
      if (e.value) {
        // console.log(e, 'customer data selected');
        setGradeValue(e);
      }
    }
  };

  const options = [
    { label: "هیچکدام", value: "هیچکدام", id: 100 },
    { label: "طلایی", value: "طلایی", id: 101 },
    { label: "نقره‌ای", value: "نقره‌ای", id: 102 },
    { label: "برنزی", value: "برنزی", id: 103 },
  ];

  const optionsTwo = [
    { label: "سپیده خودکاوندی", value: "سپیده خودکاوندی", id: 100 },
    { label: "حسین کارگر", value: "حسین کارگر", id: 101 },
    { label: "علی شمالی", value: "علی شمالی", id: 102 },
    { label: "رضا عبدالملکی", value: "رضا عبدالملکی", id: 103 },
  ];
  return (
    <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
      <h1 className="m-5">رده‌بندی مشتریان</h1>
      <div className="m-5">
        <div className="flex">
          <input
            placeholder="شماره تماس یا نام مشتری ..."
            type="text"
            name=""
            id=""
            onChange={handleSearchText}
            className="min-w-[300px] focus:outline-none rounded-md px-2 py-1"
          />
          <LoadingButton
            Tstyle={
              "bg-darkBlue text-white rounded-md px-4 mr-3 py-2 flex justify-center items-center min-w-[170px]"
            }
            loadingBtn={loadingBtn}
            title={"جستجو"}
            type={"button"}
            action={(e) => {
              searchText && customer2 && customer2.map((c1, index) => {
                if (c1.label.includes(searchText)) {
                  console.log(index, c1.label);
                  console.log(index, searchText);
                  serachCustomer({
                    label: c1.label,
                    value: c1.value,
                    id: c1.id,
                  });
                }
              })
            }}
          />
        </div>

        <div className="flex bg-white rounded-md p-5 mt-10 items-center">
        {customer ? (
            <Select
              className="ml-5 min-w-[202px] text-sm"
              onChange={serachCustomer}
              isClearable
              options={customer2}
              placeholder="انتخاب مشتری..."
              value={customer ? customer : ""}
            />
          ) : (
            <Select
              className="ml-5 min-w-[202px] text-sm"
              onChange={serachCustomer}
              isClearable
              options={customer2}
              placeholder="انتخاب مشتری..."
              value={customer ? customer : ""}
            />
          )}
          <Select
            className="ml-5 min-w-[150px] text-sm"
            onChange={handleCustomerGrade}
            isClearable
            name="customerGrade"
            options={options}
            placeholder="رده مشتری"
            // defaultValue={{ label: "هیچکدام", value: "هیچکدام", id: 100 }}
          />
          <LoadingButton
            action={saveBtnClick}
            title={"به روزرسانی"}
            // type={"submit"}
            loadingBtn={loadingBtn.info}
            value={gradeValue? gradeValue:''}
            Tstyle={`bg-blue text-xs md:text-sm rounded-md text-white w-[100px] h-[34px] flex justify-center items-center cursor-pointer`}
          />
        </div>
      </div>
    </div>
  );
};

export default Grading;
