import React, { useEffect, useState } from "react";
import arr from "./icons/arr.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthProvider";
import { warningNotify } from "./General/notifications";
import eye from "../src/icons/eye.svg";
import eyeSlash from "../src/icons/eyeSlash.svg";
import { useRole } from "./context/roleProvider";

const Login = () => {
  let SITEKEY = "6LcNdDgiAAAAADHMejVShSXHK2J8KRwn8VDnWJgk";
  // const { loggedInUserNationalCode, setLoggedInUserNationalCode, loggedInUserInfo} = useRole();
  const navigate = useNavigate();
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const checkCaptcha = (value) => {
    // console.log(value, "captcha");
    setCaptcha(value);
  };
  const { userstate, setUserState } = useAuth();

  const login = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let username = formData.get("username");
    let password = formData.get("password");
    if (!username || !password) {
      warningNotify("لطفا نام کاربری و رمز خود را وارد کنید.");
      return;
    }
    // if (!captcha) {
    //   warningNotify("لطفا کپچا را وارد نمائید");
    //   return;
    // }
    let ssnd = "u=" + username + "&p=" + password + "";
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        console.log(this.responseText);
        console.log("OK");
        if (this.responseText === "error") {
          warningNotify("نام کاربری یا رمز اشتباه می باشد!");
          return null;
        } else {
          window.username = this.responseText;
          console.log("index.html?id=" + this.responseText);
          var rs = "index.html?id=" + this.responseText.split("*")[0];
          console.log(this.responseText, "rs");
          if (!rs.includes("missing")) {
            localStorage.setItem("lgn", this.responseText.split("*")[0]);
            localStorage.setItem("lgn-name", this.responseText.split("*")[1]);
            console.log(this.responseText.split("*").length, 'the len of login');
            if (this.responseText.split("*").length === 4) {
              localStorage.setItem("car-type", this.responseText.split("*")[3]);
            }
            console.log("lgn", this.responseText.split("*")[0]);
            console.log("lgn-name", this.responseText.split("*")[1]);
            console.log(this.responseText);
            setUserState({
              lgn: this.responseText.split("*")[0],
              lgnName: this.responseText.split("*")[1],
            });
          }
          navigate("projects-list");
        }
      } else {
        console.log("ok2");
      }
    };

    // xhttp.open("GET", "./nstd_site/lgapi.php?" + ssnd, true);
    xhttp.open("GET", "./nstd_site/lgapi.php?" + ssnd, true);

    xhttp.setRequestHeader("access-control-allow-origin", "*");
    xhttp.send(captcha);
  };


  return (
    <>
      <div className="flex w-full h-full min-h-screen">
        <div className=" bg-gray flex flex-col justify-center items-center w-full lg:w-[40%]">
          <div className="bg-[url('./images/Login-Mobile.jpg')] lg:bg-none bg-cover w-full h-full bg-no-repeat bg-center flex flex-col justify-center items-center">
            <span className="bg-graytext rounded-full w-20 h-20 mb-10 my-3"></span>
            <form
              className="bg-white rounded-md p-3 min-w-[328px] min-h-[402px] h-auto"
              onSubmit={login}
            >
              <h1 className="text-center my-5">ورود به حساب کاربری</h1>
              <div className="flex flex-col">
                <label htmlFor="username">نام کاربری</label>
                <input
                  className="border my-3 border-lightgray rounded-md outline-none px-2 py-1"
                  name="username"
                  type="text"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="password">رمز عبور</label>
                <div className="relative flex">
                  <input
                    className="relative border w-full my-3 border-lightgray rounded-md outline-none px-2 py-1"
                    name="password"
                    type={hiddenPassword ? "password" : "text"}
                  />
                  <img
                    onClick={() => setHiddenPassword((prev) => !prev)}
                    src={hiddenPassword ? eyeSlash : eye}
                    alt="hidePass"
                    className="absolute left-2 top-1/3 w-4 h-auto cursor-pointer"
                  />
                </div>
              </div>
              <ReCAPTCHA
                style={{ transform: "scale(0.77)" }}
                sitekey={SITEKEY}
                onChange={checkCaptcha}
              />
              <button className="rounded-md my-3 bg-darkBlue text-white w-full py-2">
                ورود
              </button>
              {/* <div className="flex my-3 justify-center items-center">
                <p className="px-4">فراموشی رمز عبور</p>
                <img src={arr} alt="arrow" />
              </div> */}
            </form>
          </div>
        </div>
        <div className="w-[60%] hidden lg:block bg-[url('./images/Login-Desktop.jpg')] bg-cover bg-center"></div>
      </div>
    </>
  );
};

export default Login;
