import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging'
import { warningNotify } from './General/notifications';

var firebaseConfig = {
    apiKey: "AIzaSyCAvj5mJNkiIaelN4O5pIu0PUCeYiURH-g",
    authDomain: "nasr-pwa.firebaseapp.com",
    projectId: "nasr-pwa",
    storageBucket: "nasr-pwa.appspot.com",
    messagingSenderId: "743209129018",
    appId: "1:743209129018:web:51279af2a7db013f431103",
    measurementId: "G-36HQE182EP"
};
// Initialize Firebase
initializeApp(firebaseConfig);

const messaging=getMessaging();

export const requestForToken =()=>{
    return getToken(messaging, { 
        vapidKey: 'BIEgbjvXGtu1gfcAYzp5OzPf1HWNhEXLs1Ha28I1hdJiEYYHwAfcCPa1g_H-6MyV35WoocWJq4-LdHuqDXcdS74'
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
        alert('دسترسی نوتیفیکیشن داده نشده است.')
      console.log('An error occurred while retrieving token. ', err);
    });
}