import React, { useEffect, useRef, useState, useSyncExternalStore } from "react";
import chat from "./icons/Chat.svg";
import notif from "./icons/Notification.svg";
import projects from "./icons/projects.svg";
import callList from "./icons/icons8_phone.svg";
import history from "./icons/icons8_order_history.svg";
import hamMenu from "./icons/ham-menu.svg";
import close from "./icons/close.svg";
import home from "./icons/home.svg";
import { useLocation, useNavigate } from "react-router-dom";
import backArrow from "./icons/Back.svg";
import user from "./icons/user.svg";
import { useAuth } from "./contexts/AuthProvider";
import InstallPWA from "./InstallButton";
import { useRole } from "./context/roleProvider";
import PopUp from "./General/PopUp";
import Request from "./Request";
import envelope from "./icons/envelope.svg";
import goldenbadge from "./icons/Layer 2.png";
import silverbadge from "./icons/Layer 3.png";
import bronzebadge from "./icons/Layer 4.png";
import info from "./icons/icons8_info_1.svg";
// import goldenbadge from "./icons/goldenbadge.svg";
// import silverbadge from "./icons/silverbadge.svg";
// import bronzebadge from "./icons/bronzebadge.svg";
import coin from "./images/icons8_Dollar_Coin_1.svg";
import PopUp3 from "./General/PopUp3";
import Modal from "./General/Modal2";
import IntroPage from "./IntroPage";
import { callNotify } from "./General/notifications";
import { requestForToken } from "./firebase";

const Header = () => {
  const { loggedInUserInfo } = useRole();
  const [showMenu, setShowMenu] = useState(false);
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [customerLevel, setCustomerLevel] = useState(null);
  const navigate = useNavigate();
  const { userstate, setUserState } = useAuth();
  const [users, setUsers] = useState([]);
  const target = useRef();
  const outerTarget = useRef();
  const { pathname, state } = useLocation();
  const [userRole, setUserRole] = useState(null);
  const [openRequestPopUp, setOpenRequestPopUp] = useState(false);
  const [informationView, setInformationView] = useState(false);
  const [noTips, setNoTips] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [textToShow, setTextToShow] = useState(null);
  const [showIntro, setShowIntro] = useState(true);
  const [calls, setCalls] = useState(null);
  const a = useLocation();

  // useEffect(() => {
  //   if (loggedInUserInfo) {
  //     if (loggedInUserInfo[0].guide === 'yes') {
  //       setInformationView(false);
  //     } else if (loggedInUserInfo[0].naghsh === 'customer') {
  //       // console.log('needed guide');
  //       setInformationView(true);
  //       // demoFunction();
  //     }
  //   }
  // }, loggedInUserInfo)
  useEffect(() => {
    const interval = setInterval(() => {
      getNewCalling();
      if (false) {
        callNotify('test');
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (calls?.length > 0) {
      for (let i = 0; i < calls.length; i++) {
        callNotify(
          <>
            <a href={`/project-details/${calls[i].project}`}>
              <p>{calls[i].name+" "+calls[i].family}</p>
              <p>{calls[i].phone}</p>
              {/* navigate(`/project-details/${id}`); */}
            </a>
          </>
        );
      }
      
    }
  }, [calls])

  const getNewCalling = async () => {
    try {
      const response = await fetch(
        `./nstd_site/apigtnecalng.php?c=${localStorage.getItem('lgn').split("=")[1]}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      if (response.ok) {
        let callData = await response.json();
        console.log(callData, "new calling data");
        setCalls(callData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const demoFunction = () => {
    console.log('started this work');
    showMenu(true);
    setInterval(() => {
      showMenu(false);
    }, 2000)
  }

  const logout = () => {
    localStorage.clear();
    setUserState({
      lgn: false,
      lgnName: false,
    });
    // setTimeout(function () {
    navigate("/");
    // }, 500);
  };

  const handleSubGroupsClick = () => {
    setShowMenu(false);
    navigate("/subgroup");
  };

  const handleUsersClick = () => {
    setShowMenu(false);
    navigate("/users");
  };

  const handleAlarmsClick = () => {
    setShowMenu(false);
    navigate("/alarms");
  };

  const handleProjectsList = () => {
    setShowMenu(false);
    navigate("/projects-list");
  };
  const handleCallList = () => {
    setShowMenu(false);
    navigate("/call-list");
  };
  const handleHistory = () => {
    setShowMenu(false);
    navigate("/history");
  };

  const handleNewProjClick = () => {
    setShowMenu(false);
    navigate("/new-project", {
      state: { eidtMode: false },
    });
  };

  function showTime() {
    var date = new Date();
    var h = date.getHours(); // 0 - 23
    var m = date.getMinutes(); // 0 - 59
    var s = date.getSeconds(); // 0 - 59
    var session = "AM";
    if (h === 0) {
      h = 12;
    }
    if (h > 12) {
      h = h - 12;
      session = "PM";
    }
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    var time = h + ":" + m + ":" + s + " ";
    setTime(time);
  }
  function showDate() {
    let date = new Date();
    let pDate = date.toLocaleDateString("fa-IR");
    setDate(pDate);
  }

  useEffect(() => {
    setTimeout(showTime, 1000);
  }, [time]);

  useEffect(() => {
    showDate();
  }, []);

  const handleChartClick = () => {
    setShowMenu(false);
    navigate("/chart");
  };
  const handleReportClick = () => {
    setShowMenu(false);
    navigate("/report");
  };
  const handleClickOutside = (e) => {
    if (e.target === outerTarget.current) {
      setShowMenu(false);
    }
  };

  const handleBackArrow = () => {
    // if (pathname === "/users" || pathname === "/chart" ||
    // pathname === "/alarms" || pathname.startsWith("/project-details")) {
    //   window.location.pathname = "/projects-list"
    // }
    // else if (pathname === "/new-project") {
    //   if (state.editMode) {
    //     window.location.pathname = `/project-details/${state.projectID}`
    //   }
    //   else {
    //     window.location.pathname = "/projects-list"

    //   }
    // }
    window.scrollTo(0, 0);
    navigate(-1);
  };
  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  let data;
  if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
    data = {
      id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
      id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
    };
  }

  const getUsers = async () => {
    try {
      const response = await fetch(
        "./nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        console.log(userData, "userData");
        setUsers(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCustomerLevel = async () => {
    try {
      const response = await fetch(
        `./nstd_site/apildbadges.php?k=${localStorage.getItem('lgn').split("=")[1]}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        let userData = await response.json();
        console.log(userData, "customr level data");
        localStorage.setItem('level', userData[0].rank);
        setCustomerLevel(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveNotShowGuide = async (uId) => {
    try {
      const response = await fetch(
        `./nstd_site/apisvntshguide.php?i=${uId}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        let userData = await response.json();
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users.length) {
      let userRole = users.filter((user) => user.kodemelli === data.id1)[0]
        .naghsh;
      setUserRole(userRole);
    }
  }, [users]);
  useEffect(() => {
    // alert(userRole);
    if (userRole === 'customer') {
      getCustomerLevel();
    }
  }, [userRole])

  let push_code;
  const save_push_code = function () {
    console.log("save-push-code");
    requestForToken().then((ccd) => {
      save_push_func(data.id1, ccd);
    });
  };
  var SERVER_URL_SAVE_PU = "./nstd_site/apisvpuco.php"; //www.test.com/route/apitest.php?para1=gdgd&para2=hfhdd
  let Pushe = window.Pushe;
  function save_push_func(id, pu) {
    var dataArray = [];
    // alert("the save pu she token");
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        console.log(this.responseText);
      } else {
        return null;
      }
    };
    console.log("req: " + SERVER_URL_SAVE_PU + "?id=" + id + "&pu=" + pu);
    xhttp.open("GET", SERVER_URL_SAVE_PU + "?id=" + id + "&pu=" + pu, true);
    xhttp.send();
  }
  const handlePushNotification = async () => {
    // console.log(Pushe, "Pushe");
    // Pushe.init("9gl6w02rm7qx3j3g");
    // Pushe.subscribe();
    // localStorage.setItem("pushed", "true");
    // save_push_code();
    // Pushe.getDeviceId(false)
    //   .then((deviceId) => {
    //     console.log(`device granted and its id is:${deviceId}`);
    //   })
    //   .catch((err) => console.log(err));

    // let permission = await OneSignal.Notifications.permission; // boolean
    // if(permission===false){
    //   console.log('permission false executed');
    //   OneSignal.Slidedown.promptPush();
    // }
    // console.log(permission, 'if permision is granted?');
    // OneSignal.Slidedown.promptPush();

    // console.log(OneSignal.User.PushSubscription.id, 'id of this user');

    save_push_code();
  };

  const handleCustomerGrading = () => {
    setShowMenu(false);
    navigate("/grading");
  };

  const handleCustomerDiscount = () => {
    setShowMenu(false);
    navigate("/discount");
  };

  const handleUnsuccessfullProjects = () => {
    setShowMenu(false);
    navigate("/unsuccessful-projects-list");
  };

  const handleCustomerRequest = () => {
    setShowMenu(false);
    setOpenRequestPopUp(true);
  };
  const closeTip = () => {
    if (noTips === true) {
      saveNotShowGuide(localStorage.getItem('lgn').split("=")[1]);
    }
    setInformationView(false);
  }

  return (
    <>
      <div className={pathname !== "/projects-list" ? "flex flex-col fixed w-full top-0 bg-white justify-between items-center px-5 py-2 z-[500]" : "flex flex-col fixed w-full top-0 bg-white justify-end items-center px-5 py-2 z-[500]"}>
        <div className={pathname !== "/projects-list" ? "w-full flex justify-between py-2 border-b border-graydivider" : "w-full flex justify-end py-2 border-b border-graydivider"}>
          {pathname === "/projects-list" ? (
            customerLevel ? (
              customerLevel[0] ? (
                <div className="flex items-end justify-center cursor-pointer">
                  {customerLevel[0].rank !== 'هیچکدام' ? (
                    <div onClick={() => {
                      setTextToShow("شما مشتری سطح " + localStorage.getItem('level') + " ما هستید.");
                      setOpenModal(true);
                      // alert("شما مشتری سطح " + localStorage.getItem('level') + " ما هستید.");
                    }} className="bounce-top">
                      <img src={customerLevel[0].rank === 'نقره‌ای' ? silverbadge : customerLevel[0].rank === 'برنزی' ? bronzebadge : customerLevel[0].rank === 'طلایی' ? goldenbadge : bronzebadge} alt="badge" className="h-8 w-8" />
                    </div>) : ''}
                  {/* <div className="rotate-center"><img src={coin}></img></div> */}
                  <img
                    title={loggedInUserInfo && loggedInUserInfo[0].name}
                    className="md:hidden cursor-pointer h-[80%] w-auto opacity-40"
                    src={user}
                    alt="user"
                  />
                  <p className="text-graytext mr-2 hidden md:block ">
                    {loggedInUserInfo && loggedInUserInfo[0].name}
                  </p>
                </div>
              ) : null

            ) : null) : (
            <div
              onClick={handleBackArrow}
              className="flex items-center cursor-pointer"
            >
              <img className="rotate-180 w-8 mx-2" src={backArrow} alt="back" />
              <p className="text-graytext hidden md:block ">بازگشت</p>

            </div>
          )}
          {/* {pathname.indexOf('details') ? (
            customerLevel?(
            <div className="flex items-end justify-center cursor-pointer">
              <img src={customerLevel[0].rank==='نقره‌ای'?silverbadge:customerLevel[0].rank==='برنزی'?bronzebadge:customerLevel[0].rank==='طلایی'?goldenbadge:bronzebadge} alt="badge" className="h-8 w-8" />

              <img
                title={loggedInUserInfo && loggedInUserInfo[0].name}
                className="md:hidden cursor-pointer h-[80%] w-auto opacity-40"
                src={user}
                alt="user"
              />
              <p className="text-graytext mr-2 hidden md:block ">
                {loggedInUserInfo && loggedInUserInfo[0].name}
              </p>
            </div>
          ):null) : (
            ''
          )} */}
          {
            pathname !== "/projects-list" ? (
              <img
                className="cursor-pointer opacity-40"
                onClick={() => {
                  localStorage.setItem('page', '1');
                  navigate("/projects-list");
                }}
                src={home}
                alt="home"
              />
            ) : null
          }
          <div
            className="cursor-pointer flex justify-items-end"
          >
            {
              loggedInUserInfo ? (
                loggedInUserInfo[0].naghsh === 'customer' ? (
                  <div className="ml-3">
                    <img onClick={() => { setInformationView(true) }} src={info}></img>
                  </div>
                ) : ''
              ) : ''
            }

            <img onClick={() => setShowMenu(true)} src={hamMenu} alt="menu" className=" opacity-40 justify-items-end" />
          </div>
        </div>
        <div className="bg-white w-full pt-2 text-graytext flex justify-between">
          <div className="flex items-center">
            <div className="relative">
              <img
                onClick={() => navigate("/messages")}
                className="ml-3 w-6 h-6 cursor-pointer"
                src={envelope}
              ></img>
              <span className="absolute top-0 right-0 flex h-2 w-2">
                <span className=" absolute inline-flex h-2 w-2 rounded-full bg-red opacity-75"></span>
                <span className="animate-ping relative inline-flex rounded-full h-2 w-2 bg-red"></span>
              </span>
            </div>
            <p className="text-center">{time}</p>
          </div>
          <p className="text-center">{date}</p>
        </div>
      </div>
      <div
        ref={outerTarget}
        onClick={handleClickOutside}
        className={`justify-center items-center z-[12000] font-iranYekanRegular  sm:rounded-md w-full h-full fixed flex duration-1000 inset-0 transition-transform bg-black bg-opacity-40 ${showMenu ? "translate-y-[0] fade" : " fadeOut translate-y-[-100%]"
          }`}
      >
        <div
          ref={target}
          className={` w-full p-5 fixed bg-white flex justify-between top-0 z-[800] ${showMenu
            ? "duration-1000 transition-transform translate-y-[0%]"
            : "duration-1000 translate-y-[-100%]"
            }`}
        >
          <ul>
            <li className="py-2 cursor-pointer">
              کاربر وارد شده : {loggedInUserInfo && loggedInUserInfo[0].name}
              {loggedInUserInfo ?
                (
                  loggedInUserInfo[0].naghsh === 'Manager' ? ' مدیر' :
                    loggedInUserInfo[0].naghsh === 'accountant' ? ' مالی' :
                      loggedInUserInfo[0].naghsh === 'coord' ? ' هماهنگی' :
                        loggedInUserInfo[0].naghsh === 'customer' ? ' مشتری' :
                          loggedInUserInfo[0].naghsh === 'installer' ? ' نصاب' :
                            loggedInUserInfo[0].naghsh === 'product_manager' ? ' مدیر تولید' :
                              loggedInUserInfo[0].naghsh === 'qc' ? ' کنترل کیفی' :
                                loggedInUserInfo[0].naghsh === 'sale' ? ' فروش' :
                                  loggedInUserInfo[0].naghsh === 'transport' ? ' حمل' :
                                    loggedInUserInfo[0].naghsh === 'Visitor' ? ' ویزیتور' : ''
                )
                : null
              }
            </li>
            {loggedInUserInfo ? (
              /*loggedInUserInfo[0].naghsh === "sale" ||*/
              loggedInUserInfo[0].naghsh === "Manager" ?
                (<li onClick={handleUsersClick} className="py-2 cursor-pointer">
                  کاربران
                </li>) : null) : null
            }
            {userRole === "Manager" ? (
              <li
                onClick={handleSubGroupsClick}
                className="py-2 cursor-pointer"
              >
                تنظیم گروه‌ها
              </li>
            ) : (
              ""
            )}
            <li onClick={handleNewProjClick} className="py-2 cursor-pointer">
              تعریف پروژه
            </li>
            {loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh === "sale" ||
                loggedInUserInfo[0].naghsh === "Manager" ? (
                <li
                  onClick={handleCustomerGrading}
                  className="py-2 cursor-pointer"
                >
                  رده‌بندی مشتری
                </li>
              ) : null
            ) : null}
            {loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh === "Manager" ? (
                <li
                  onClick={handleCustomerDiscount}
                  className="py-2 cursor-pointer"
                >
                  اعمال تخفیف برای مشتری
                </li>
              ) : null
            ) : null}
            {loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh === "Manager" ? (
                <li
                  onClick={handleUnsuccessfullProjects}
                  className="py-2 cursor-pointer"
                >
                  پروژه های ناموفق
                </li>
              ) : null
            ) : null}
            {loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh === "customer" ? (
                <li
                  onClick={handleCustomerRequest}
                  className="py-2 cursor-pointer"
                >
                  درخواست
                </li>
              ) : null
            ) : null}
            <li
              onClick={handlePushNotification}
              className="py-2 cursor-pointer"
            >
              فعال‌سازی پوش نوتیفیکیشن
            </li>
            {loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh === "sale" ||
                loggedInUserInfo[0].naghsh === "Manager" ? (
                <>
                  <li onClick={handleChartClick} className="py-2 cursor-pointer">
                    نمودار
                  </li>
                  <li onClick={handleReportClick} className="py-2 cursor-pointer">
                    گزارش
                  </li>
                </>
              ) : null) : null
            }
            <li onClick={logout} className="py-2 cursor-pointer">
              خروج
            </li>
            <li className="py-2 cursor-pointer">
              <InstallPWA />
            </li>
            <li className="flex mt-4">
              <div className="bg-yellow rounded-full ml-3 flex justify-center items-center w-12 h-12 cursor-pointer">
                <img src={chat} alt="chat"
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/messages");
                  }}
                />
              </div>
              <div
                onClick={handleAlarmsClick}
                className="bg-yellow rounded-full ml-3 flex justify-center items-center w-12 h-12 cursor-pointer"
              >
                <img src={notif} alt="notofications" />
              </div>
              <div
                onClick={handleProjectsList}
                className="bg-yellow rounded-full ml-3 flex justify-center items-center w-12 h-12 cursor-pointer"
              >
                <img src={projects} alt="projects-list" />
              </div>
              {loggedInUserInfo ? (
                loggedInUserInfo[0].naghsh === "sale" ||
                  loggedInUserInfo[0].naghsh === "Manager" ? (
                  <>
                    <div
                      onClick={handleCallList}
                      className="bg-yellow rounded-full ml-3 flex justify-center items-center w-12 h-12 cursor-pointer"
                    >
                      <img src={callList} alt="call-list" />
                    </div>
                    {loggedInUserInfo[0].naghsh === "Manager" ? (<div
                      onClick={handleHistory}
                      className="bg-yellow rounded-full ml-3 flex justify-center items-center w-12 h-12 cursor-pointer"
                    >
                      <img src={history} alt="history" />
                    </div>) : null}
                  </>
                ) : null) : null
              }

            </li>
          </ul>
          <img
            className={`w-6 h-6 self-start cursor-pointer`}
            onClick={() => setShowMenu(false)}
            src={close}
            alt="close"
          />
        </div>
      </div>
      <PopUp
        content={<Request />}
        setShowPopUp={setOpenRequestPopUp}
        showPopUp={openRequestPopUp}
        title={"درخواست مشتریان"}
      />
      {
        informationView ? (
          informationView !== false ? (
            <IntroPage
              show={showIntro}
              setShow={setShowIntro}
              setInformationView={setInformationView}
              informationView={informationView}
            />) : null
        ) : null
      }
      {openModal && (
        <Modal
          title={"سطح مشتری"}
          description={textToShow}
          deleteAction={null}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default Header;
