import React, { useEffect, useRef, useState } from 'react';
import { XSquare } from 'react-feather';
import { warningNotify } from './General/notifications';
// import { Smile } from 'react-feather';


const FileUploadDialog = ({ showDialog, setShowDialog, setText, sendChat }) => {
    const [fileMessage, setFileMessage] = useState('');
    const fileAttachment = useRef();


    const uploadAttachment = async (event) => {
        let fileMsg = fileMessage && fileMessage;
        const file = fileAttachment.current.files[0];
        let fileName = file.name;

        const fd = new FormData();
        fd.append("myFile", file);
        fd.append("id", '1000');
        console.log(fileName, 'the file all data');
        try {
            const response = await fetch(
                "./nstd_site/uploadchtfl.php",
                {
                    method: "POST",
                    body: fd,
                }
            );
            if (response.ok) {
                console.log("OK");
                setText(fileName);
                sendChat();
                // setShowDialog(false);
            } else {
                warningNotify("لطفا دوباره تلاش کنید");
            }
        } catch (error) {
            console.log(error);
        }

        // currentUser
        //     .sendMessage({
        //         text: fileMessage || file.name,
        //         roomId: currentRoom.id,
        //         attachment: {
        //             file,
        //             name: file.name,
        //         },
        //     })
        //     .then(messageId => {
        //         const roomData = currentRoom.customData || {};

        //         const roomAttachments = roomData.attachments || {};
        //         roomAttachments[messageId] = {
        //             likes: [],
        //         };

        //         roomData.attachments = roomAttachments;

        //         return currentUser.updateRoom({
        //             roomId: currentRoom.id,
        //             customData: roomData,
        //         });
        //     })
        //     .catch(console.error);


        // this.setState({
        //     showDialog: false,
        //     fileMessage: '',
        // });
        setFileMessage('');
        setShowDialog(false);
    }
    return (
        <div className="dialog-container">
            <div className="dialog">
                <header className="dialog-header">
                    <h4>آپلود فایل</h4>
                    <button onClick={(e) => { setShowDialog(false); }}>
                        <XSquare />
                    </button>
                </header>
                <form className="dialog-form" onSubmit={(e) => {
                    e.preventDefault();
                    let fileName = fileAttachment.current.files[0].name;
                    setText(fileName);
                    uploadAttachment();
                }}>
                    <input
                        type="file"
                        ref={fileAttachment}
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                            setText(e.target.files[0].name);
                        }
                        }
                    />
                    <label className="dialog-label" htmlFor="new-message">
                        متن مربوط به عکس:
                    </label>
                    <input
                        id="new-message"
                        className="dialog-input border rounded"
                        autoFocus
                        type="text"
                        name="fileMessage"
                        value={fileMessage}
                        onChange={(e) => { setFileMessage(e.currentTarget.value) }}
                        placeholder="متن..."
                    />
                    <button type="submit" className="submit-btn">
                        Upload
                    </button>
                </form>
            </div>
        </div>
    );
};

export default FileUploadDialog;