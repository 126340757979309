import { faT } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import ChatItem from "./ChatItem";
import pht from "../images/1005-200x200.jpg"

function ChatList({ myMessages, setMyMessages, setUser, refresh }) {
  // const [myMessages, setMyMessages] = useState(null);
  const [messagesList, setMessagesList] = useState(null);
  const getMessages = async () => {
    try {
      console.log('the third place');
      const response = await fetch(
        `../../nstd_site/apigtmsg.php?id=${localStorage.getItem('lgn').split("=")[1]}&rl=${localStorage.getItem('lgn').split("&")[0]}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data = await response.json();
      // console.log(data, 'all the message received!');
      let arr = Array();
      data.map((datum, index) => {
        // console.log(datum, 'the message of iterate');
        let isFound = false;
        let isFoundGpChn = false;
        if (arr.length === 0) {
          isFound = false;
        } else {
          arr.map((ar, index22) => {
            if (((ar.sendercode === datum.sendercode && ar.receivercode === datum.receivercode) || (ar.sendercode === datum.receivercode && ar.receivercode === datum.sendercode)) && datum.receivergroup === null && datum.receiverchannel === null
            && ar.receivergroup === null && ar.receiverchannel === null
            ) {
              isFound = true;
            }
            // console.log(ar,datum,'counter'+index22, isFound);
            // if ((ar.receivergroup === datum.receivergroup || ar.receiverchannel === datum.receiverchannel) && (datum.receivergroup !== null || datum.receiverchannel !== null)) {
            //   isFoundGpChn = true;
            // }
            if (ar.receivergroup === datum.receivergroup && datum.receivergroup !== null) {
              isFoundGpChn = true;
            }
            if (ar.receiverchannel === datum.receiverchannel && datum.receiverchannel !== null) {
              isFoundGpChn = true;
            }
          })
        }
        if (isFound === false && datum.receivergroup === null && datum.receiverchannel === null) {
          // console.log(datum, 'chat111');
          arr.push(datum);
        }
        if (isFoundGpChn === false && (datum.receivergroup !== null || datum.receiverchannel !== null)) {
          arr.push(datum);
          // console.log(datum, 'group111');
        }
      })
      setMyMessages(data);
      setMessagesList(arr);
      console.log(arr, 'the list of messages head');
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getMessages();
  }, [])
  // useEffect(() => {
  //   getMessages();
  // }, [myMessages])
  useEffect(() => {
    console.log(('the trigger executed'));
    getMessages();
  }, [refresh])
  return (
    <div>
      {
        messagesList ? (
          messagesList.map((item, index) => {
            return (
              <ChatItem item={item} setUser={setUser} userCode={item.sendercode} name={item.receiver ? item.receiver : 'ناشناس'} text={item.text} photo={pht} />
            )
          })
        ) : ''
      }
    </div>
  );
}

export default ChatList;
