import React from "react";

const Loading = () => {
  return (
    <>
      {/* <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray opacity-75 flex flex-col items-center justify-center"> */}
      <div className="w-full h-full overflow-hidden bg-gray opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-grayborder h-12 w-12 mb-4"></div>
        <h2 className="text-center text-black text-xl font-semibold">
          در حال بارگیری ...
        </h2>
        <p className="w-1/3 text-center text-black">
          لطفا کمی صبر نموده و صفحه را ترک نکنید ...
        </p>
      </div>
    </>
  );
};

export default Loading;
