import { useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
const CustomMarker = ({ setPosition }) => {
  const map = useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      console.log(lat, lng, "latlng");
      setPosition({ lat: lat, lng: lng });
    },
  });
  return (
null
  );
};

export default CustomMarker;
