import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useNavigate } from "react-router-dom";
import JalaliDate from "./dateConvertor";
import LoadingButton from "./General/LoadingButton";
import { 
  
 } from "./General/Config";
import Pagination from "./Pagination";
import ProjectsTable from "./ProjectsTable";
import Loading from "./General/Loading";
import { useRole } from "./context/roleProvider";
import CountDown from "./pDetails/CountDown";
import { dateChange } from "./dateConvertor";

const CallList = () => {
  const { loggedInUserInfo } = useRole();
  loggedInUserInfo && console.log(loggedInUserInfo[0].naghsh);
  const navigate = useNavigate();
  const [callList, setCallList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [addit, setAddit] = useState(0);

  const LOCAL_STORAGE_AUTH_KEY = "lgn";

  useEffect(() => {
    let separator = '-';
    let newDate = new Date();
    setDate(newDate);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const getCallList = async () => {
      try {
        const response = await fetch(`./nstd_site/apigtcllst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}`, {
          method: "GET",
          cash: 'no-store',
          
        });
        let data = await response.json();
        setCallList(data);
      } catch (error) {
        console.log(error);
      }
    }
    getCallList();
  }, [])
  const previousMonth = async (a) => {
    console.log(a, 'addit');
    let separator = '-';
    let newDate = new Date(new Date().getTime() + (a * 31 * 24 * 60 * 60 * 1000));
    setDate(newDate);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    try {
      const response = await fetch(`./nstd_site/apigtcllst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setCallList(data);
    } catch (error) {
      console.log(error);
    }
  }

  const nextMonth = async (b) => {
    console.log(b, 'addit');
    let separator = '-';
    let newDate = new Date(new Date().getTime() + (b * 31 * 24 * 60 * 60 * 1000));
    setDate(newDate);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    try {
      const response = await fetch(`./nstd_site/apigtcllst.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}`, {
        method: "GET",
        cash: 'no-store',
      });
      let data = await response.json();
      setCallList(data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
      <span className="block m-4 font-bold text-center">صفحه شماره های تماس</span>
      <div className="flex justify-center">
        <button className="rounded bg-white m-4 p-2" onClick={() => {
          let a = addit - 1;
          setAddit(addit - 1);
          previousMonth(a);
        }}>ماه قبل</button>
        {date ? (<span className="font-bold mt-6">{dateChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)}</span>) : ''}
        <button className="rounded bg-white m-4 p-2" onClick={() => {
          let b = addit + 1;
          setAddit(addit + 1);
          nextMonth(b);
        }}>ماه بعد</button>
      </div>
      {callList ? (
        callList.map((call, index) => {
          return (
            <div id={index} className="bg-yellow rounded overflow-hidden shadow-md m-4 p-2" onClick={() => window.open(`tel:${call.phone}`,)}>
              <p className="font-bold">نام مشتری: {call.name}</p>
              <p className="font-bold">شماره تماس: {call.phone}</p>
            </div>
          )
        })
      )
        :
        ''
      }
    </div>
  );
};

export default CallList;
