import React, { useEffect, useState } from "react";
import Loading from "../General/Loading";
import PopUp from "../General/PopUp";
import Select from "react-select";
import LoadingButton from "../General/LoadingButton";
import { successNotify, warningNotify } from "../General/notifications";
import { carOptions, roleOptions, addUserFields } from "../General/Config";
import { convertRole } from "../General/Functions";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import JalaliDate from "../dateConvertor";
import Modal from "../General/Modal";
import eye from "../icons/eye.svg";
import eyeSlash from "../icons/eyeSlash.svg";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showNewPopUp, setShowNewPopUp] = useState(false);
  const [showNewPopUp2, setShowNewPopUp2] = useState(false);
  const [tobeEditedUser, setToBeEditedUser] = useState(null);
  const [editedUserInfo, setEditedUserInfo] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [usersOptions, setUserOptions] = useState(null);
  const [fordel1, setFordel1] = useState(null);
  const [fordel2, setfordel2] = useState(null);
  const [userForDel, setUserForDel] = useState(null);
  const [carKind, setCarKind] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const [hiddenPassword, setHiddenPassword] = useState(true);

  const [selectedDayForAlarm, setselectedDayForAlarm] = useState(
    new DateObject()
  );
  const [openModal, setOpenModal] = useState(false);
  const [userKindHide, setUserKindHide] = useState(false);

  // const [name_, setName_]=useState(null);
  // const[kodemelli_, setKodemelli_]=useState(null);
  // const[shemaremobile_, setShemareMobile_]=useState(null);
  // const[userName_, setUsername_]=useState(null);
  // const[password_, setPassword_]=useState(null);

  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  let userNationalCode = localStorage
    .getItem(LOCAL_STORAGE_AUTH_KEY)
    .split("=")[1];
  // let id = (userstate.lgn).split("&")[0];
  let data = {
    id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
    id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
  };
  const selectStyles = {
    menuList: styles => {
      console.log('menuList:', styles);
      return {
        ...styles,
        maxHeight: 136
      };
    }
  };
  useEffect(() => {
    let selectedDatum;
    if (selectedDayForAlarm) {
      selectedDatum =
        selectedDayForAlarm.year +
        "-" +
        selectedDayForAlarm.month.number +
        "-" +
        selectedDayForAlarm.day;
    }

    let tr = JalaliDate.jalaliToGregorian(
      selectedDayForAlarm.year,
      selectedDayForAlarm.month.number,
      selectedDayForAlarm.day
    ).join("-");
    setBirthDate(tr);
  }, [selectedDayForAlarm]);
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apigtslmd.php`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let data = await response.json();
        setUserOptions(data);
      } catch (error) {
        console.log(error);
      }
    }
    getUsers();
  }, [])

  const getUsers = async () => {
    try {
      const response = await fetch(
        "../../nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        // console.log(userData, "userData");
        setUsers(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCustomers = async () => {
    setUsers([]);
    try {
      const response = await fetch(
        "../../nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        // console.log(userData, "userData");
        setUsers(userData.filter((user) => user.naghsh === 'customer'));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  const getEmployees = async () => {
    setUsers([]);
    try {
      const response = await fetch(
        "../../nstd_site/usapgt.php",
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let userData = await response.json();
        // console.log(userData, "userData");
        setUsers(userData.filter((user) => user.naghsh !== 'customer'));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    // console.log(fordel1, 'for1');
    // console.log(fordel2, 'for2');
    if (usersOptions) {
      // console.log('user option is not null');
      // console.log(usersOptions);

      setUserOptions(
        usersOptions.filter((user) => user.value !== fordel1)
      );
    }
  }, [fordel1, fordel2])
  const deleteBotton = async (k, n) => {
    setShowNewPopUp2(true);
    setFordel1(k);
    setfordel2(n);
    // let permit = window.confirm("آیا مطمئن هستید؟");
    // if (permit) {
    //   try {
    //     const response = await fetch(
    //       `../../nstd_site/apidlus.php?k=${k}&n=${n}`,
    //       {
    //         method: "POST",
    //         cash: 'no-store',
    //         body: JSON.stringify(data),
    //       }
    //     );
    //     if (response.ok) {
    //       getUsers();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  }
  useEffect(() => {
    console.log(carKind, 'log of the car type');
  }, [carKind])
  const handleEditUser = (nationalCode) => {
    const filterdUser = users.filter((user) => user.kodemelli === nationalCode);
    setToBeEditedUser(filterdUser[0]);
    setEditedUserInfo(filterdUser[0]);
    setCarKind(
      carOptions.filter(
        (item) => item.value === filterdUser[0]['car'].split('*')[1]
      )
    );

    if (filterdUser[0].naghsh === 'customer') {
      setUserKindHide(true);
    } else {
      setUserRole({
        value: filterdUser[0].naghsh,
      });
    }
    setShowPopUp(true);
  };

  const saveAction = async (e) => {
    setToBeEditedUser([]);
    e.preventDefault();
    setLoadingBtn(true);
    setHiddenPassword(true);
    const formData = new FormData(e.currentTarget);
    let name = formData.get("name");
    let kodemelli = formData.get("kodemelli");
    let shemaremobile = formData.get("shemaremobile");
    let naghsh;
    if (userRole) {
      naghsh = userRole.value;
    } else {
      naghsh = 'customer';
    }
    let username = formData.get("username");
    let passwo = formData.get("passwo");
    let carType = '';
    let carNumber = '';
    if (naghsh === 'transport') {
      carType = formData.get('carKind');
      carNumber = formData.get('carNumber2')+
      formData.get('carNumberAlph')+
      formData.get('carNumber3')+
      formData.get('carNumberCity')
      ;
    }
    if (naghsh === 'customer') {
      carNumber = birthDate;
    }
    console.log(carNumber, carType);
    setEditedUserInfo((prev) => ({
      ...prev,
      name: name,
      kodemelli: kodemelli,
      shemaremobile: shemaremobile,
      naghsh: naghsh,
      username: username,
      passwo: passwo,
    }));
    console.log(name, kodemelli, shemaremobile, naghsh, username, passwo);
    if (!shemaremobile || !name) {
      warningNotify("شماره موبایل و نام نمی‌تواند خالی باشد");
      setLoadingBtn(false);
      return;
    } else if (
      !shemaremobile.match(/^(?:0|98|\+98|\+980|0098|098|00980)?(9\d{9})$/)
    ) {
      warningNotify("لطفا شماره موبایل را به درستی وارد نمائید");
      setLoadingBtn(false);
      return;
    }
    try {
      const response = await fetch(
        `../../nstd_site/apisvusr.php?nm=${name}&cdm=${kodemelli}&mbl=${shemaremobile}&ngh=${naghsh}&usr=${username}&psv=${passwo}&plt=${carNumber}&cr=${carType}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("تغییرات با موفقیت اعمال شد");
        setShowPopUp(false);
        getUsers();
      } else {
        warningNotify("لطفا دوباره امتحان کنید");
      }
      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
      setLoadingBtn(false);
      warningNotify("لطفا دوباره امتحان کنید");
    }
  };
  const addNewUser = async (e) => {
    // e.preventDefault();
    e.preventDefault();
    console.log("oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooyyyyyyyyyyyyyyyyyyyyyyyyyyy");
    const formData = new FormData(e.currentTarget);
    let name = formData.get("name");
    let kodemelli = formData.get("kodemelli");
    let shemaremobile = formData.get("shemaremobile");
    let naghsh = formData.get("role");
    let username = formData.get("username");
    let passwo = formData.get("passwo");
    let carType = '';
    let carNumber = '';
    if (naghsh === 'transport') {
      carType = formData.get('carKind');
      carNumber = formData.get('carNumber2')+
      formData.get('carNumberAlph')+
      formData.get('carNumber3')+
      formData.get('carNumberCity')
      ;
    }
    console.log(naghsh);
    setLoadingBtn(true);
    try {
      const response = await fetch(
        `../../nstd_site/apichcdm.php?id=${kodemelli}&us=${username}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data[0].error);
        if (data[0].error !== "no") {
          warningNotify("کد ملی و یا نام کاربری تکراری است");
          setLoadingBtn(false);
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (!shemaremobile || !name || !kodemelli) {
      warningNotify("شماره موبایل و نام و کد ملی نمی‌تواند خالی باشد");
      setLoadingBtn(false);
      return;
    } else if (
      !shemaremobile.match(/^(?:0|98|\+98|\+980|0098|098|00980)?(9\d{9})$/)
    ) {
      warningNotify("لطفا شماره موبایل را به درستی وارد نمائید");
      setLoadingBtn(false);
      return;
    } else if (!kodemelli.match(/^\d{10}$/)) {
      warningNotify("کد ملی باید ده رقم و بدون خط تیره باشد");
      setLoadingBtn(false);
      return;
    } else {
      if (naghsh === 'transport') {
        try {
          console.log("with car data ok", 'cardata');
          const response = await fetch(
            `../../nstd_site/apisvusr.php?nm=${name}&cdm=${kodemelli}&mbl=${shemaremobile}&ngh=${naghsh}&usr=${username}&psv=${passwo}&plt=${carNumber}&cr=${carType}`, {
              method: "GET",
              cash: 'no-store',
            }
          );
          if (response.ok) {
            successNotify("کاربر جدید با موفقیت اضافه شد");
            getUsers();
            setShowNewPopUp(false);
            e.target.reset();
          } else {
            warningNotify("لطفا دوباره امتحان کنید");
          }
          setLoadingBtn(false);
        } catch (error) {
          console.log(error);
          setLoadingBtn(false);
          warningNotify("لطفا دوباره امتحان کنید");
        }
      } else {
        try {
          const response = await fetch(
            `../../nstd_site/apisvusr.php?nm=${name}&cdm=${kodemelli}&mbl=${shemaremobile}&ngh=${naghsh}&usr=${username}&psv=${passwo}`, {
              method: "GET",
              cash: 'no-store',
            }
          );
          if (response.ok) {
            successNotify("کاربر جدید با موفقیت اضافه شد");
            getUsers();
            setShowNewPopUp(false);
            e.target.reset();
          } else {
            warningNotify("لطفا دوباره امتحان کنید");
          }
          setLoadingBtn(false);
        } catch (error) {
          console.log(error);
          setLoadingBtn(false);
          warningNotify("لطفا دوباره امتحان کنید");
        }
      }
    }
  };
  const deleteTheUser = async (e) => {
    try {
      const response = await fetch(
        `../../nstd_site/apidlus.php?k=${fordel1}&n=${fordel2}&r=${userForDel.value}`,
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        setOpenModal(false);
        handleClosePopUp2();
        getUsers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditRoleChange = (input) => {
    if (input) {
      setEditedUserInfo((prev) => ({ ...prev, naghsh: input.value }));
      setUserRole(input);
    }
  };

  const handleRoleSelection = (e) => {
    console.log(e, '00000000000000000000000000000000000000000000000000000');
    setUserRole(e);
  };
  const handleUserSelection = (e) => {
    setUserForDel(e);
  };
  const handleCarKindSelection = (e) => {
    setCarKind(e);
    return false;
  };

  const handleClosePopUp = () => {
    setShowNewPopUp(false);
    setUserRole(null);
  };
  const handleClosePopUp2 = () => {
    setShowNewPopUp2(false);
    setUserForDel(null);
  };
  const handleEditUserPopUp = () => {
    setShowPopUp(false);
    setUserRole(null);
    setUserKindHide(false);
  };
  
  const CreateContent = () => {
    return (
      <form
        onSubmit={addNewUser}
        className="flex flex-wrap flex-col justify-center items-center"
      >
        <div className="flex flex-wrap justify-center">
          {addUserFields.map((field, index) => {
            return (
              <div key={index}>
                <div className="mx-2 flex flex-col h-20">
                  <label className="py-1">{field.name}</label>
                  {field.slug === "naghsh" ? (
                    <Select
                      onChange={(ev) => {
                        handleRoleSelection(ev);
                      }}
                      className="min-w-[184px]"
                      isClearable
                      name="role"
                      options={roleOptions}
                      placeholder="لطفا انتخاب کنید ..."
                      value={userRole}
                    />
                  ) : (
                    <input
                      name={field.slug}
                      className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    ></input>
                  )}
                </div>
              </div>
            );
          })}

          {
            userRole && userRole.value === "transport" ? (
              <>
                <div className="mx-2 flex flex-col h-20">
                  <label className="py-1">پلاک ماشین</label>
                  <div className="flex flex-row">
                    <input
                      name="carNumberCity"
                      className="min-w-[10px] max-w-[60px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="ایران"
                    ></input>
                    <input
                      name="carNumber3"
                      className="min-w-[10px] max-w-[80px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="454"
                    ></input>
                    <input
                      name="carNumberAlph"
                      className="min-w-[10px] max-w-[35px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="و"
                    ></input>
                    <input
                      name="carNumber2"
                      className="min-w-[10px] max-w-[40px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="21"
                    ></input>
                  </div>
                </div>
                <div className="mx-2 flex flex-col h-20">
                  <label className="py-1">نوع ماشین</label>
                  {/* <input
                    name="carType"
                    className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                  ></input> */}
                  <Select
                    onChange={handleCarKindSelection}
                    className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    isClearable
                    name="carKind"
                    options={carOptions}
                    placeholder="لطفا انتخاب کنید ..."
                    value={carKind}
                  />
                </div>
              </>
            ) : null
          }
        </div>
        <LoadingButton
          title={"ذخیره"}
          Tstyle={
            "inline-flex items-center my-6 text-center justify-center max-w-[200px] px-6 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-darkBlue transition ease-in-out duration-150"
          }
          type={"submit"}
          // action={(e)=>{addNewUser(e)}}
          loadingBtn={loadingBtn}
        />
      </form>
    );
  };
  const DeleteUser = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(userForDel, 'userfordel');
            if (userForDel) {
              setOpenModal(true);
            } else {
              warningNotify('کاربری انتخاب نشد.');
            }
          }
          }
          className="flex flex-wrap flex-col justify-center items-center"
        >
          <div className="flex flex-wrap item-center">
            {
              <>
                <p className="m-4 pt-2 justify-center text-center item-center">لطفا کاربر جایگزین را انتخاب کنید:</p>
                <Select
                  onChange={(e) => handleUserSelection(e)}
                  className="min-w-[184px] m-4 max-h-[50px]"
                  isClearable
                  name="user"
                  options={usersOptions}
                  styles={selectStyles}
                  placeholder="لطفا انتخاب کنید ..."
                  value={userForDel}
                />
              </>
            }
          </div>
          <LoadingButton
            title={"تایید"}
            Tstyle={
              "m-4 inline-flex items-center my-6 text-center justify-center max-w-[200px] px-6 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-darkBlue transition ease-in-out duration-150"
            }
            type={"submit"}
            loadingBtn={loadingBtn}
          />
        </form>
        {openModal && (
          <Modal
            title={"حذف کاربر"}
            description={"آیا از حذف این کاربر اطمینان دارید؟"}
            deleteAction={deleteTheUser}
            setOpenModal={setOpenModal}
          />
        )}
      </>
    );
  };

  const CreateEditContent = () => {
    return (
      <form onSubmit={saveAction} className="flex flex-col items-center">
        <div className="flex p-5 flex-wrap justify-center">
          {tobeEditedUser &&
            addUserFields.map((field, index) => {
              return (
                <div key={index}>
                  {
                    field.name === 'نقش' ? (
                      userKindHide ? ('') : (
                        <p className="w-[50%]">{field.name}</p>
                      )
                    ) : (<p className="w-[50%]">{field.name}</p>)
                  }

                  <div className="mx-2 flex flex-col h-20">
                    {field.slug === "naghsh" ? (
                      userKindHide ? (
                        ''
                      ) : (
                        <Select
                          onChange={handleEditRoleChange}
                          className="min-w-[184px]"
                          isClearable
                          name="role"
                          options={roleOptions}
                          placeholder="لطفا انتخاب کنید ..."
                          value={roleOptions.filter(
                            (item) => item.value === tobeEditedUser[field.slug]
                          )}
                        />
                      )
                    ) : field.slug === "passwo" ? (
                      // <input
                      //   readOnly={field.slug === "kodemelli"}
                      //   defaultValue={tobeEditedUser[field.slug]}
                      //   name={field.slug}
                      //   type= "password"
                      //   className="min-w-[184px] border rounded-md border-lightgray px-3 py-2"
                      // ></input>
                      <div className="relative flex">
                        <input
                          className="relative border w-full my-3 border-lightgray rounded-md outline-none px-2 py-1"
                          name={field.slug}
                          defaultValue={tobeEditedUser[field.slug]}
                          type={hiddenPassword ? "password" : "text"}
                        />
                        <img
                          onClick={() => setHiddenPassword((prev) => !prev)}
                          src={hiddenPassword ? eyeSlash : eye}
                          alt="hidePass"
                          className="absolute left-2 top-1/3 w-4 h-auto cursor-pointer"
                        />
                      </div>
                    ) : (
                      <input
                        readOnly={field.slug === "kodemelli"}
                        defaultValue={tobeEditedUser[field.slug]}
                        name={field.slug}
                        className="min-w-[184px] border rounded-md border-lightgray px-3 py-2"
                      ></input>
                    )}
                  </div>
                </div>
              );
            })}
          {tobeEditedUser && (userRole && userRole.value === "transport" && userRole.value !== "customer") || (userRole && tobeEditedUser && tobeEditedUser['car'] && userRole.value !== "customer") ? (
            <>
              <div className="mx-2 flex flex-col h-20">
                <label className="py-1">پلاک ماشین</label>
                {
                  tobeEditedUser['car'] ? (
                    // <input
                    //   name="carNumber"
                    //   defaultValue={tobeEditedUser ? tobeEditedUser['car'].split("*")[0] : ''}
                    //   className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    // ></input>
                    <div className="flex flex-row">
                    <input
                      name="carNumberCity"
                      className="min-w-[10px] max-w-[60px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="ایران"
                      defaultValue={tobeEditedUser? tobeEditedUser['car'].split("*")[0].substring(6, 8):''}
                    ></input>
                    <input
                      name="carNumber3"
                      className="min-w-[10px] max-w-[80px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="454"
                      defaultValue={tobeEditedUser? tobeEditedUser['car'].split("*")[0].substring(3, 6):''}
                    ></input>
                    <input
                      name="carNumberAlph"
                      className="min-w-[10px] max-w-[35px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="و"
                      defaultValue={tobeEditedUser? tobeEditedUser['car'].split("*")[0].substring(2, 3):''}
                    ></input>
                    <input
                      name="carNumber2"
                      className="min-w-[10px] max-w-[40px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="21"
                      defaultValue={tobeEditedUser? tobeEditedUser['car'].split("*")[0].substring(0, 2):''}
                    ></input>
                  </div>
                  ) : (
                    <div className="flex flex-row">
                    <input
                      name="carNumberCity"
                      className="min-w-[10px] max-w-[60px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="ایران"
                    ></input>
                    <input
                      name="carNumber3"
                      className="min-w-[10px] max-w-[80px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="454"
                    ></input>
                    <input
                      name="carNumberAlph"
                      className="min-w-[10px] max-w-[35px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="و"
                    ></input>
                    <input
                      name="carNumber2"
                      className="min-w-[10px] max-w-[40px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      placeholder="21"
                    ></input>
                  </div>
                  )
                }
              </div>
              <div className="mx-2 flex flex-col h-20">
                <label className="py-1">نوع ماشین</label>
                {
                  tobeEditedUser['car'] ? (
                    <Select
                      onChange={handleCarKindSelection}
                      className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      isClearable
                      name="carKind"
                      options={carOptions}
                      placeholder="لطفا انتخاب کنید ..."
                      value={carKind}
                    />
                    // <input
                    //   name="carType"
                    //   defaultValue={tobeEditedUser ? tobeEditedUser['car'].split("*")[1] : ''}
                    //   className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    // ></input>
                  ) : (
                    <Select
                      onChange={handleCarKindSelection}
                      className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                      isClearable
                      name="carKind"
                      options={carOptions}
                      placeholder="لطفا انتخاب کنید ..."
                      value={carKind}
                    />
                    // <input
                    //   name="carType"
                    //   className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    // ></input>
                  )
                }
              </div>
            </>
          ) : null}
          {tobeEditedUser && (userRole && userRole.value === "customer") || (userRole && userRole.value === "customer" && tobeEditedUser && tobeEditedUser['car']) ? (
            <>
              <div className="mx-2 flex flex-col h-20">
                <label className="py-1">تاریخ تولد</label>
                {
                  tobeEditedUser['car'] ? (
                    <DatePicker
                      value={selectedDayForAlarm}
                      onChange={setselectedDayForAlarm}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                    />
                    // <input
                    //   name="carNumber"
                    //   defaultValue={tobeEditedUser ? tobeEditedUser['car'].split("*")[0] : ''}
                    //   className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    // ></input>
                  ) : (
                    <input
                      name="carNumber"
                      className="min-w-[184px] min-h-[38px] border rounded-md border-lightgray px-3 py-2"
                    ></input>
                  )
                }
              </div>
            </>
          ) : null}

        </div>
        <LoadingButton
          title={"ذخیره"}
          Tstyle={
            "inline-flex items-center my-6 text-center justify-center max-w-[200px] px-6 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-darkBlue transition ease-in-out duration-150"
          }
          type={"submit"}
          loadingBtn={loadingBtn}
        />
      </form>
    );
  };

  return (

    localStorage.getItem('lgn').split("&")[0] === '1111111111111' ? (
      <div className="mt-32 flex flex-col items-center justify-center max-w-[1380px] mx-auto">
        <button
          onClick={() => setShowNewPopUp(true)}
          className="w-[90%] md:w-[85%] bg-darkBlue text-white py-2 px-3 my-3 rounded-md"
        >
          افزودن کاربر جدید +
        </button>
        <button
          onClick={() => getCustomers()}
          className="w-[90%] md:w-[85%] bg-yellow text-black py-2 px-3 my-3 rounded-md"
        >
          مشتریان
        </button>
        <button
          onClick={() => getEmployees()}
          className="w-[90%] md:w-[85%] bg-green text-black py-2 px-3 my-3 rounded-md"
        >
          کارمندان
        </button>
        <button
          onClick={() => getUsers()}
          className="w-[90%] md:w-[85%] bg-darkBlue text-white py-2 px-3 my-3 rounded-md"
        >
          همه
        </button>
        {users.length ? (
          <>
            <table className="hidden md:inline-table w-[85%]">
              <thead>
                <tr className="bg-darkBlue text-white py-2 rounded-t-md">
                  <td className="text-center p-2 rounded-tr-md">نام</td>
                  <td className="text-center p-2">کد ملی</td>
                  <td className="text-center p-2">شماره موبایل</td>
                  <td className="text-center p-2">نقش</td>
                  <td className="text-center p-2">نام کاربری</td>
                  <td className="text-center p-2">رمز عبور</td>
                  <td className="text-center p-2 rounded-tl-md"></td>
                </tr>
              </thead>
              <tbody className="bg-white">
                {users.map((user) => {
                  return (
                    <tr className="" key={user.kodemelli}>
                      <td className="p-2 text-center">{user.name}</td>
                      <td className="p-2 text-center">{user.kodemelli}</td>
                      <td className="p-2 text-center">{user.shemaremobile}</td>
                      <td className="p-2 text-center">
                        {convertRole(user.naghsh)}
                      </td>
                      <td className="p-2 text-center">{user.username}</td>
                      <td className="p-2 text-center">*****</td>
                      <td className="p-2 text-center flex">
                        <button
                          className="rounded-md bg-lightgray bg-opacity-50 px-3 py-1"
                          onClick={() => handleEditUser(user.kodemelli)}
                        >
                          ویرایش
                        </button>
                        <button
                          className="rounded-md bg-lightgray bg-opacity-50 px-3 py-1"
                          onClick={() => deleteBotton(user.kodemelli, user.naghsh)}
                        >
                          حذف
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="md:hidden flex flex-col w-[90%] mb-40">
              {users.map((user, index) => {
                return (
                  <div key={index} className="rounded-md bg-white mb-3">
                    <div className="py-2  mx-3 border-b-2 border-b-darkBlue text-center flex px-2 justify-between">
                      <p className="w-[40%] text-right">نام :</p>
                      <p className="text-left w-[60%]"> {user.name}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p className="w-[40%]">کدملی :</p>
                      <p className="text-left w-[60%]">{user.kodemelli}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p className="w-[45%]">شماره موبایل :</p>
                      <p className="text-left w-[55%]">{user.shemaremobile}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p className="w-[40%]">نقش :</p>
                      <p className="text-left w-[60%]">
                        {convertRole(user.naghsh)}
                      </p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue text-right flex px-2 justify-between">
                      <p className="w-[40%]">نام کاربری:</p>
                      <p className="text-left w-[60%]">{user.username}</p>
                    </div>
                    <div className="py-2 mx-3 border-b-2 border-b-darkBlue  text-right flex px-2 justify-between">
                      <p className="w-[40%]">رمزعبور :</p>
                      <p className="text-left w-[60%]">*****</p>
                    </div>
                    <div className="mx-3 py-4 flex justify-end">
                      <button
                        onClick={() => handleEditUser(user.kodemelli)}
                        className=" rounded-md bg-lightgray bg-opacity-50 px-5 py-2"
                      >
                        ویرایش
                      </button>
                      <button onClick={() => deleteBotton(user.kodemelli, user.naghsh)} className=" rounded-md mr-3 bg-lightgray bg-opacity-50 px-5 py-2">
                        حذف
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <Loading />
        )}
        <PopUp
          title={"آیتم‌های مورد نظر را اصلاح و ذخیره نمایید"}
          content={<CreateEditContent />}
          showPopUp={showPopUp}
          setShowPopUp={handleEditUserPopUp}
        />
        <PopUp
          title={"اطلاعات مربوط به کاربر جدید را اضافه نمائید"}
          content={<CreateContent />}
          showPopUp={showNewPopUp}
          setShowPopUp={handleClosePopUp}
        />
        <PopUp
          title={"پروژه ها به کارتابل چه کسی منتقل شوند؟"}
          content={<DeleteUser />}
          showPopUp={showNewPopUp2}
          setShowPopUp={handleClosePopUp2}
        />
      </div>
    ) : null

  );
};

export default Users;
