import React, { useRef } from "react";
import close from "../icons/close.svg";

const PopUp = ({ title, content, showPopUp, setShowPopUp }) => {
  const handleClickOutside = (e) => {
    if (e.target === outerTarget.current) {
      setShowPopUp(false);
    }
  };
  const target = useRef();
  const outerTarget = useRef();
  return (
    <div
      ref={outerTarget}
      onClick={handleClickOutside}
      className={`justify-center items-center z-[2000] font-iranYekanRegular w-full h-full fixed flex duration-1000 inset-0 transition-transform bg-black bg-opacity-40 ${
        showPopUp ? "translate-y-[0]" : "translate-y-[-100%]"
      }`}
    >
      <div
        ref={target}
        className="bg-white w-[90%] scroll-smooth md:w-3/4 h-3/4 max-h-[450px] sm:max-h-[3/4] overflow-auto scrollbar rounded-md flex flex-col items-center relative max-w-[1380px]"
      >
        <h1 className="text-xs p-5 md:text-sm self-start mr-2 sm:mr-5 md:mr-10 mt-16">
          {title}
        </h1>
        <div className="py-4 flex justify-end z-[500] fixed bg-white overflow-hidden w-[82%] md:w-[70%] rounded-t-md max-w-[1380px] xxl:max-w-[1362px] px-3">
          <div className="w-4 h-4 fill-grayborder cursor-pointer">
            <img onClick={() => setShowPopUp(false)} src={close} alt="close" />
          </div>
        </div>
        <div className="text-xs md:text-sm w-full flex flex-wrap justify-center mb-4">
          {content}
        </div>
      </div>
    </div>
  );
};

export default PopUp;
