import ProgressCh from "./ProgressCh";
export const checkColor = (perc) => {
  console.log(perc);
  switch (perc) {
    case "قرارداد":
      return "rgba(255, 20, 56, 0.6)";
    case "ویزیت":
      return "rgba(223, 46, 56, 0.6)";
    case "تحویل":
      return "rgba(0, 128, 255, 0.6)";
    case "کنترل کیفی":
      return "rgba(114, 197, 147, 0.6)";
    case "هماهنگی":
      return "rgba(255, 69, 0, 0.6)";
    case "تولید":
      return "rgba(255, 0, 255, 0.6)";
    case "بارگیری":
      return "rgba(80, 193, 154, 0.4)";
    case "در حال نصب":
      return "rgba(0, 100, 0, 0.6)";
    case "آماده بارگیری":
      return "rgba(50, 205, 50, 0.6)";
    case "آماده نصب":
      return "rgba(0, 255, 0, 0.6)";
    case "اتمام پروژه":
      return "rgba(0, 0, 205, 0.6)";
    case "ناموفق":
      return "rgba(205, 205, 205, 0.6)";
    default:
      return "rgba(223, 46, 56, 0.6)";
  }
};
export const checkProgress = (perc) => {
  switch (perc) {
    case "ویزیت":
      return <ProgressCh percent={0} />;
    case "قرارداد":
      return <ProgressCh percent={10} />;
    case "هماهنگی":
      return <ProgressCh percent={20} />;
    case "تولید":
      return <ProgressCh percent={30} />;
    case "کنترل کیفی":
      return <ProgressCh percent={40} />;
    case "آماده بارگیری":
      return <ProgressCh percent={50} />;
    case "بارگیری":
      return <ProgressCh percent={60} />;
    case "آماده نصب":
      return <ProgressCh percent={70} />;
    case "در حال نصب":
      return <ProgressCh percent={80} />;
    case "تحویل به مشتری":
      return <ProgressCh percent={90} />;
    case "اتمام پروژه":
      return <ProgressCh percent={100} />;
    default:
      return <ProgressCh percent={0} />;
  }
};

export const convertRole = (role) => {
  console.log(role);
  switch (role) {
    case "product_manager":
      return "مدیر تولید";
    case "coord":
      return "هماهنگی";
    case "Visitor":
      return "ویزیتور";
    case "installer":
      return "نصاب";
    case "transport":
      return "بارگیری";
    case "Manager":
      return "مدیر کل";
    case "qc":
      return "کنترل کیفی";
    case "accountant":
      return "واحد مالی";
    case "sale":
      return "فروش";
    case "customer":
      return "مشتری";
    default:
      return;
  }
};
