import React, { useEffect, useRef, useState } from "react";
import squarePlus from "../images/plus-square.svg";
import del from "../icons/Delete.svg";
import xclose from "../icons/xclose.svg";
import AddNewWindow from "./AddNewWindow";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Modal from "../General/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { successNotify, warningNotify } from "../General/notifications";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import gps from "../images/icons8_gps.svg";
import { Icon } from "leaflet";
import CustomMarker from "../General/CustomMarker";
import LoadingButton from "../General/LoadingButton";
import ScrollButton from "../General/ScrollButton";
import AddAlaram from "./AddAlarm";
import Compressor from "compressorjs";
import { data } from "autoprefixer";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import JalaliDate from "../dateConvertor";

const AddNewProject = () => {
  const [center, setCenter] = useState({
    lat: 31.88381,
    lng: 54.36829,
  });

  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  let user = {
    id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
    id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
  };

  const [openAddNewWin, setOpenAddNewWin] = useState(false);
  const [position, setPosition] = useState(center);
  const [projectNumber, setprojectNumber] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const uploadInputRef = useRef();
  const [ratingStars, setRatingStars] = useState(useLocation()?.state?.rating);
  const [windows, setWindows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeletePicModal, setOpenDeletePicModal] = useState(false);
  const [winCodeForDelete, setWinCodeForDelete] = useState(null);
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState([]);
  const [editMode, setEditMode] = useState(useLocation()?.state?.editMode);
  const [projectId, setProjectId] = useState(useLocation()?.state?.projectID);
  const [docNameForDelete, setDocNameForDelete] = useState(null);
  const [imagesData, setImagesData] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [upLoading, setUpLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedDayForAlarm, setselectedDayForAlarm] = useState(
    new DateObject()
  );
  const [dataForEdit, setDataForEdit] = useState(
    useLocation()?.state?.projectDetails
  );
  const [starEditMode, setStarEditMode] = useState(
    useLocation()?.state?.editMode
  );
  const navigate = useNavigate();

  const [imagesForEdit, setImagesForEdit] = useState(
    useLocation()?.state?.imagesData || []
  );
  useEffect(()=>{
    getLocationGps();
  }, [])
  useEffect(() => {
    let selectedDatum;
    if (selectedDayForAlarm) {
      selectedDatum =
        selectedDayForAlarm.year +
        "-" +
        selectedDayForAlarm.month.number +
        "-" +
        selectedDayForAlarm.day;
    }

    let tr = JalaliDate.jalaliToGregorian(
      selectedDayForAlarm.year,
      selectedDayForAlarm.month.number,
      selectedDayForAlarm.day
    ).join("-");
    setAlarmData((prev) => ({ ...prev, dt: tr }));
  }, [selectedDayForAlarm]);
  const [alarmData, setAlarmData] = useState({
    dt: "",
  });
  const getLocationGps = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position1) {
        let lat = position1.coords.latitude;
        let lon = position1.coords.longitude;
        setPosition({
          lat: lat,
          lng: lon,
        });
        setCenter({
          lat: lat,
          lng: lon,
        });

        console.log("Latitude is :", position1.coords.latitude);

        console.log("Longitude is :", position1.coords.longitude);
        // alert("lat:"+position1.coords.latitude+" long:"+position1.coords.longitude)
        console.log(position, 'the position saved2');
      });
    } else {

      console.log("Not Available");
      setPosition({
        lat: 0,
        lng: 0,
      });
    }
    console.log(position, 'new position set');
  }
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       setPosition({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });

  //       console.log("Latitude is :", position.coords.latitude);

  //       console.log("Longitude is :", position.coords.longitude);

  //     });
  //   } else {

  //     console.log("Not Available");
  //     setPosition({
  //       lat: 0,
  //       lng: 0,
  //     });
  //   }
  //   console.log(position, 'new position set');
  // }, [])

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openModal]);

  let forEditProject;
  let ordersData = useLocation()?.state?.orders;

  useEffect(() => {
    if (editMode) {
      setWindows(ordersData);
      if (dataForEdit[0].location.indexOf('*') >= 0) {
        setPosition({
          lat: dataForEdit[0].location.split("*")[0],
          lng: dataForEdit[0].location.split("*")[1],
        });
        console.log(position, 'the position saved');
      }
    }
  }, []);

  if (dataForEdit) {
    forEditProject = {
      projectAddress: dataForEdit[0].address,
      ownerName: dataForEdit[0].name,
      ownerFamilyName: dataForEdit[0].family,
      ownerPhoneNumber: dataForEdit[0].phone,
      projectStage: dataForEdit[0].level.substring(
        0,
        dataForEdit[0].level.indexOf("$")
      ),
      projectStatus: dataForEdit[0].id1,
      buildingFloors: dataForEdit[0].floor.substring(
        0,
        dataForEdit[0].floor.indexOf("$")
      ),
      doorWinQuantity: dataForEdit[0].winno,
    };
  }

  const addNewProjectForm = [
    { label: "نام مالک", slug: "ownerName" },
    { label: "نام خانوادگی مالک", slug: "ownerFamilyName" },
    { label: "آدرس پروژه", slug: "projectAddress" },
    { label: "شماره مالک", slug: "ownerPhoneNumber" },
    { label: "مرحله پروژه", slug: "projectStage" },
    // { label: "وضعیت پروژه", slug: "projectStatus" },
    { label: "تاریخ تولد مشتری", slug: "birthDay" },
    { label: "کد ملی مشتری", slug: "nationalCode" },
  ];
  const [newProjectData, setNewProjectData] = useState({
    name: "",
    famil: "",
    address: "",
    level: "",
    floor: "",
    winno: "",
    rating: "",
    profilkind: "",
    glasskind: "",
    number: "",
    toory: "",
    nazer: "",
    location: "",
    images: "",
    nationalCode: "",
  });

  let stars = [1, 2, 3, 4, 5];
  const starRef = useRef([]);
  const fillStar = (index) => {
    setStarEditMode(false);
    if (starRef.current[index].style.stroke === "darkblue") {
      for (let i = index + 1; i < 5; i++) {
        starRef.current[i].style.fill = "none";
        starRef.current[i].style.stroke = "none";
      }
    } else {
      for (let i = 0; i <= index; i++) {
        starRef.current[i].style.fill = "darkBlue";
        starRef.current[i].style.stroke = "darkBlue";
      }
    }
    setRatingStars(index + 1);
  };

  const saveProject = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let ownerName = formData.get("ownerName");
    let ownerFamilyName = formData.get("ownerFamilyName");
    let projectAddress = formData.get("projectAddress");
    let ownerPhoneNumber = formData.get("ownerPhoneNumber");
    let projectStage = formData.get("projectStage");
    let projectStatus = formData.get("projectStatus");
    let buildingFloors = formData.get("buildingFloors");
    let doorWinQuantity = formData.get("doorWinQuantity");
    let profileType = formData.get("profileType");
    let glassType = formData.get("glassType");
    let toori = formData.get("toori");
    let exp = formData.get("exp");
    let nationalCode = formData.get("nationalCode");
    let bDate = alarmData.dt;
    if (ownerName.match(/^$|\s" "+/) || ownerFamilyName.match(/^$|\s" "+/)) {
      warningNotify("اطلاعات مربوط به نام نمی‌تواند خالی باشد");
      return;
    }
    if (
      !ownerPhoneNumber.match(
        /^(?:0|98|\+98|\+980|0098|098|00980)?(9\d{9})$/
      ) ||
      ownerPhoneNumber.match(/^$|\s +/)
    ) {
      warningNotify("لطفا شماره موبایل را به درستی وارد نمائید");
      return;
    }
    setSaveLoading(true);
    console.log(
      ownerName,
      ownerFamilyName,
      projectAddress,
      ownerPhoneNumber,
      projectStage,
      projectStatus,
      buildingFloors,
      doorWinQuantity,
      profileType,
      glassType,
      toori,
      exp, 
      nationalCode,
    );
    if (windows && windows.length) {
      for (let i = 0; i < windows.length; i++) {
        const fd = new FormData();
        fd.append("imgBase64", windows[i].data);
        fd.append("name", windows[i].name);
        console.log(windows[i]);
        try {
          const response = await fetch(
            "../../nstd_site/uploadw.php",
            {
              method: "POST",
              cash: 'no-store',
              body: fd,

            }
          );
          if (response.ok) {
            console.log("OK");
            if (i === windows.length - 1 && windows.length>0) {
              // successNotify("با موفقیت آپلود شد");
            }
          } else {
            if (i === windows.length - 1) {
              setUpLoading(false);
              warningNotify("لطفا دوباره تلاش کنید");
            }
          }
        } catch (error) {
          console.log(error);
          if (i === windows.length - 1) {
            setUpLoading(false);
            warningNotify("لطفا دوباره تلاش کنید");
          }
        }
      }
    }
    let convertedData;
    if (windows && windows.length) {
      for (let i = 0; i < windows.length; i++) {
        if (i === 0) {
          convertedData =
            projectStage +
            "$" +
            windows[i].name +
            "*" +
            windows[i].width +
            "*" +
            windows[i].height +
            "*" +
            windows[i].quantity +
            "*" +
            windows[i].code +
            "#";
          if (windows.length === 1) {
            convertedData += "$";
          }
        } else {
          let splitter = "";
          for (let j = 1; j <= i; j++) {
            splitter = splitter + "\n";
          }
          if (i === windows.length - 1) {
            convertedData =
              convertedData +
              splitter +
              windows[i].code +
              "*" +
              windows[i].quantity +
              "*" +
              windows[i].height +
              "*" +
              windows[i].width +
              "*" +
              windows[i].name +
              "#" +
              "$";
          } else {
            convertedData =
              convertedData +
              splitter +
              windows[i].code +
              "*" +
              windows[i].quantity +
              "*" +
              windows[i].height +
              "*" +
              windows[i].width +
              "*" +
              windows[i].name +
              "#";
          }
        }
      }
    } else {
      convertedData = projectStage + "$";
    }

    console.log(imagesForEdit, "imagesforedit");
    console.log(projectImages, "projectImages");
    let images = "";
    let delPics = "";
    if (editMode) {
      console.log("if", editMode);
      projectImages.forEach((img) => (images = images + img + "*"));
      // projectImages.forEach((img) => (images = images + img ));
      deletedFiles.forEach(
        (img) =>
        (delPics =
          delPics + "../../nstd_site/uploads/" + img + "*")
      );
    } else {
      console.log(projectImages, "projectimages");
      projectImages.forEach((img) => (images = images + img + "*"));
    }
    console.log(delPics, "delPics");
    console.log(images, "images");

    let data = {
      name: ownerName,
      famil: ownerFamilyName,
      address: projectAddress,
      level: convertedData,
      floor: `${buildingFloors}$${exp}$`,
      winno: doorWinQuantity,
      rating: ratingStars && ratingStars.toString(),
      profilkind: profileType,
      glasskind: glassType,
      number: ownerPhoneNumber,
      toory: toori,
      nazer: user.id1,
      location: `${position.lat}*${position.lng}`,
      images: images,
      birthday: bDate,
      nationalCode: nationalCode,
    };
    let editData = {
      name: ownerName,
      famil: ownerFamilyName,
      address: projectAddress,
      level: convertedData,
      floor: `${buildingFloors}$${exp}$`,
      winno: doorWinQuantity,
      rating: ratingStars && ratingStars.toString(),
      profilkind: profileType,
      glasskind: glassType,
      number: ownerPhoneNumber,
      toory: toori,
      nazer: user.id1,
      location: `${position.lat}*${position.lng}`,
      images: images,
      idprj: projectId,
      del_pics: delPics,
      nationalCode: nationalCode, 
    };
    console.log(editData, "editData");
    // console.log(convertedData);
    try {
      if (editMode) {
        const response = await fetch(
          "../../nstd_site/apiprjupd2.php",
          {
            method: "POST",
            cash: 'no-store',
            body: JSON.stringify(editData),
          }
        );
        if (response.ok) {
          successNotify("تغییرات با موفقیت اعمال شد");
          navigate(`/project-details/${projectId}`);
        } else {
          warningNotify("لطفا دوباره تلاش کنید");
        }
        setSaveLoading(false);
      } else {
        const response = await fetch(
          `../../nstd_site/apiprj.php`,
          {
            method: "POST",
            cash: 'no-store',
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          successNotify("پروژه با موفقیت ذخیره شد");
          navigate("/project-list");
        } else {
          warningNotify("لطفا دوباره تلاش کنید");
        }
        setSaveLoading(false);
      }
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setSaveLoading(false);
    }
  };
  const handleOpenAddNewWindow = (e) => {
    e.preventDefault();
    setOpenAddNewWin(true);
  };

  const deleteWindow = () => {
    setWindows((prev) => prev.filter((win) => win.code !== winCodeForDelete));
    setOpenModal(false);
  };

  const handleDeleteWindow = (windowCode) => {
    setOpenModal(true);
    setWinCodeForDelete(windowCode);
  };

  const handleFiles = (e) => {
    setPreview([]);
    setFiles([]);

    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 900000) {
        new Compressor(e.target.files[i], {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            let blobToFile = new File(
              [compressedResult],
              compressedResult.name
            );
            setFiles((prev) => [...prev, blobToFile]);
          },
        });
      } else {
        setFiles((prev) => [...prev, e.target.files[i]]);
      }
      // setFiles((prev) => [...prev, e.target.files[i]]);
      setPreview((prev) => [
        ...prev,
        {
          url: URL.createObjectURL(e.target.files[i]),
          name: e.target.files[i].name,
        },
      ]);
    }
  };

  const uploadImages = async () => {
    console.log(files, "files");
    if (files.length) {
      setUpLoading(true);
      for (let i = 0; i < files.length; i++) {
        const fd = new FormData();
        fd.append("myFile", files[i]);
        console.log(files[i]);
        try {
          const response = await fetch(
            "../../nstd_site/upload.php",
            {
              method: "POST",
              cash: 'no-store',
              body: fd,
            }
          );
          if (response.ok) {
            console.log("OK");
            setProjectImages((prev) => [...prev, files[i].name]);
            if (editMode) {
              setImagesForEdit((prev) => [...prev, { name: files[i].name }]);
            }
            if (i === files.length - 1) {
              if (editMode) {
                successNotify("برای ثبت نهایی لطفا دکمه را ذخیره را بزنید");
              } else {
                successNotify("با موفقیت آپلود شد");
              }
              setUpLoading(false);
            }
            setPreview([]);
            uploadInputRef.current.value = "";
          } else {
            if (i === files.length - 1) {
              setUpLoading(false);
              warningNotify("لطفا دوباره تلاش کنید");
            }
          }
        } catch (error) {
          console.log(error);
          if (i === files.length - 1) {
            setUpLoading(false);
            warningNotify("لطفا دوباره تلاش کنید");
          }
        }
      }
    } else {
      warningNotify("شما فایلی را برای آپلود انتخاب نکرده‌اید");
      return;
    }
  };

  const getProjectImages = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apidocs.php?id=${projectId}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let imagesData = await response.json();
      setImagesData(imagesData);
      console.log(imagesData, "imagesData");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDocDelete = async (docName) => {
    setOpenDeletePicModal(true);
    setDocNameForDelete(docName);
    console.log(docName);
  };
  const deleteDoc = async () => {
    if (editMode) {
      setDeletedFiles((prev) => [...prev, docNameForDelete]);
      setImagesForEdit((prev) => [
        ...prev.filter((image) => image.name !== docNameForDelete),
      ]);
      successNotify("لطفا برای ثبت نهایی حذف عکسها دکمه ذخیره را بزنید");
    } else {
      setProjectImages((prev) => [
        ...prev.filter((img) => img !== docNameForDelete),
      ]);
    }
    setOpenDeletePicModal(false);
    if (editMode) {
      getProjectImages();
    }
  };

  const handleDeletePreview = (name) => {
    // console.log(name);
    setPreview((prev) => [...prev.filter((img) => img.name !== name)]);
    setFiles((prev) => [...prev.filter((img) => img.name !== name)]);
    if (preview.length === 1) {
      uploadInputRef.current.value = "";
      //  setPreview([]);
    }
  };

  const focusLevelField = async (e) => {
    try {
      const response = await fetch(
        `../../nstd_site/apichecknoproj.php?no=${phoneNumber}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data1 = await response.json();
      if (data1[0].count !== "0") {
        setprojectNumber(data1[0].count);
        successNotify("تعداد پروژه های قبلی: " + data1[0].count);
      } else {
        setprojectNumber(null);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
     }, [lat, lng]);
     return null;
   }

  return (
    <div className="relative max-w-[1320px] mx-auto">
      <div className="m-5 my-32 pb-6 max-w-[1320px] xxl:mx-auto">
        <div className="flex">
          <h1>افزودن پروژه جدید</h1>
          {
            projectNumber ? (
              <h1 className="text-red">پروژه شماره {parseInt(projectNumber) + 1}</h1>
            ) : ''
          }
        </div>
        <form className="pt-5" onSubmit={saveProject}>
          <div className="flex flex-wrap">
            {addNewProjectForm.map((item, i) => {
              return (
                <>
                  {
                    item.slug === "birthDay" ? (
                      <div
                        key={i}
                        className={`${i % 2 === 0 ? "ml-[3%]" : "mr-[3%]"
                          } flex flex-col w-[47%] `}
                      >
                        <label className="py-4" htmlFor={item.slug}>
                          {item.label}
                        </label>
                        <div className="w-full overflow-hidden flex justify-center items-center h-[42px] rounded-md bg-white">
                          <DatePicker
                            value={selectedDayForAlarm}
                            onChange={setselectedDayForAlarm}
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                          />
                        </div>
                        {/* <input
                          title={editMode ? forEditProject[item.slug] : ""}
                          className="p-2 rounded-md text-ellipsis overflow-hidden"
                          name={item.slug}
                          type="text"
                          defaultValue={
                            editMode
                              ? item.slug === "projectStatus"
                                ? ""
                                : forEditProject[item.slug]
                              : ""
                          }
                          onChange={item.label === 'شماره مالک' ? (e) => { setphoneNumber(e.currentTarget.value) } : null}
                          onBlur={item.label === 'شماره مالک' ? focusLevelField : null}
                        /> */}
                      </div>
                    ) : (
                      <div
                        key={i}
                        className={`${i % 2 === 0 ? "ml-[3%]" : "mr-[3%]"
                          } flex flex-col w-[47%] `}
                      >
                        <label className="py-4" htmlFor={item.slug}>
                          {item.label}
                        </label>
                        <input
                          title={editMode ? forEditProject[item.slug] : ""}
                          className="p-2 rounded-md text-ellipsis overflow-hidden"
                          name={item.slug}
                          type="text"
                          defaultValue={
                            editMode
                              ? item.slug === "projectStatus"
                                ? ""
                                : forEditProject[item.slug]
                              : ""
                          }
                          onChange={item.label === 'شماره مالک' ? (e) => { setphoneNumber(e.currentTarget.value) } : null}
                          onBlur={item.label === 'شماره مالک' ? focusLevelField : null}
                        />
                      </div>
                    )
                  }
                </>
              );
            })}
          </div>
          <div className="flex flex-col">
            <label className="py-4" htmlFor="projectScore">
              امتیاز پروژه
            </label>
            <div className="flex">
              {stars.map((st, index) => {
                return (
                  <svg
                    ref={(st) => (starRef.current[index] = st)}
                    onClick={() => fillStar(index)}
                    key={index}
                    alt="rating-star"
                    className="ml-2 cursor-pointer"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill={
                      starEditMode && dataForEdit && dataForEdit[0].rating
                        ? index < Number(dataForEdit[0].rating)
                          ? "darkBlue"
                          : "none"
                        : "none"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4061 2.05251C15.8838 0.792392 17.6919 0.792392 18.1712 2.05251L21.2617 10.6136C21.4782 11.1809 22.0292 11.5587 22.6443 11.5587H30.2394C31.6428 11.5587 32.2549 13.3055 31.1516 14.161L25.7468 19.0238C25.5047 19.2099 25.3278 19.468 25.2414 19.7609C25.155 20.0538 25.1637 20.3665 25.2661 20.6542L27.2398 29.0197C27.7206 30.3634 26.1649 31.5175 24.9884 30.6904L17.6471 26.0321C17.3957 25.8554 17.0959 25.7606 16.7886 25.7606C16.4813 25.7606 16.1815 25.8554 15.9301 26.0321L8.58886 30.6904C7.41385 31.5175 5.85661 30.3619 6.33737 29.0197L8.31116 20.6542C8.41355 20.3665 8.42218 20.0538 8.33581 19.7609C8.24944 19.468 8.07251 19.2099 7.8304 19.0238L2.42562 14.161C1.32077 13.3055 1.9359 11.5587 3.33637 11.5587H10.9314C11.2305 11.5597 11.5228 11.4698 11.7696 11.3011C12.0165 11.1323 12.2063 10.8926 12.314 10.6136L15.4046 2.05251H15.4061Z"
                      stroke="#D8D8D8"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row w-full">
            <div className="flex flex-col sm:w-[24%]">
              <label className="py-4" htmlFor="buildingFloors">
                طبقات ساختمان
              </label>
              <input
                className="ml-[1%] p-2 rounded-md"
                name="buildingFloors"
                defaultValue={
                  editMode
                    ? dataForEdit[0].floor.substring(
                      0,
                      dataForEdit[0].floor.indexOf("$")
                    )
                    : ""
                }
              ></input>
            </div>
            <div className="flex flex-col sm:w-[24%]">
              <label className="py-4" htmlFor="doorWinQuantity">
                تعداد درب و پنجره
              </label>
              <input
                className="ml-[1%] p-2 rounded-md"
                name="doorWinQuantity"
                defaultValue={editMode ? dataForEdit[0].winno : ""}
              ></input>
            </div>
            <div className="flex flex-col sm:w-[24%]">
              <label className="py-4" htmlFor="profileType">
                نوع پروفیل
              </label>
              <input
                defaultValue={editMode ? dataForEdit[0].profkind : ""}
                className="ml-[1%] p-2 rounded-md"
                name="profileType"
              ></input>
            </div>
            <div className="flex flex-col sm:w-[24%]">
              <label className="py-4" htmlFor="glassType">
                نوع شیشه
              </label>
              <input
                className="ml-[1%] p-2 rounded-md"
                name="glassType"
                defaultValue={editMode ? dataForEdit[0].glasskind : ""}
              ></input>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex">
              <input
                className="ml-2"
                type="radio"
                value="دارای توری"
                name="toori"
                defaultChecked={
                  editMode ? dataForEdit[0].toory === "دارای توری" : ""
                }
              />
              <label htmlFor="huey">دارای توری</label>
            </div>
            <div className="flex mr-3">
              <input
                className="ml-2"
                type="radio"
                value="بدون توری"
                name="toori"
                defaultChecked={
                  editMode ? dataForEdit[0].toory === "بدون توری" : ""
                }
              />
              <label htmlFor="huey">بدون توری</label>
            </div>
          </div>
          {windows && (
            <div className="flex px-3 mt-5 flex-col bg-white rounded-md">
              {windows &&
                windows.map((win, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex py-3 ${index !== 0 ? "border-t border-t-lightgray" : ""
                        } justify-between`}
                    >
                      <div>
                        <p className="py-2">{win.name}</p>
                        <div className="md:flex">
                          <div className="flex md:ml-5">
                            <p className="px-1">عرض</p>:
                            <p className="px-1"> {win.width}</p>
                          </div>
                          <div className="flex md:ml-5">
                            <p className="px-1">طول</p>:
                            <p className="px-1"> {win.height}</p>
                          </div>
                          <div className="flex md:ml-5">
                            <p className="px-1">تعداد</p>:
                            <p className="px-1"> {win.quantity}</p>
                          </div>
                          <div className="flex md:ml-5">
                            <p className="px-1">کد</p>:
                            <p className="px-1"> {win.code}</p>
                          </div>
                          {
                            win.data === '' ? (
                              <div className="flex md:ml-5">
                                <p className="px-1">تصویر</p>:
                                <p className="px-1 max-w-md h-24"> <img className="h-20" src={"../../nstd_site/uploads2/" + win.name + ".png"}></img></p>
                              </div>
                            ) : (
                              <div className="flex md:ml-5">
                                <p className="px-1">تصویر111</p>:
                                <p className="px-1 max-w-md h-24"> <img className="h-20" src={win.data}></img></p>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      <img
                        className="cursor-pointer self-start"
                        onClick={() => {
                          handleDeleteWindow(win.code);
                        }}
                        src={del}
                        alt="delete"
                      />
                    </div>
                  );
                })}
            </div>
          )}
          <div className="border-b-4 pb-6 border-white">
            <button
              onClick={handleOpenAddNewWindow}
              className=" mt-4 w-full py-2 rounded-md flex justify-center items-center bg-yellow"
            >
              <img className="ml-2" src={squarePlus} alt="plus" />
              افزودن پنجره
            </button>
          </div>
          {/* <AddAlaram projectId={projectId} id1={user.id1} mode={editMode} /> */}
          <div className="flex flex-col ">
            <label className="py-4">توضیحات</label>
            <textarea
              className="rounded-md p-2"
              name="exp"
              id=""
              cols="30"
              rows="5"
              defaultValue={
                editMode
                  ? dataForEdit[0].floor
                    .substring(dataForEdit[0].floor.indexOf("$") + 1)
                    .split("$")[0] === null ||
                    dataForEdit[0].floor
                      .substring(dataForEdit[0].floor.indexOf("$") + 1)
                      .split("$")[0] === ""
                    ? ""
                    : dataForEdit[0].floor
                      .substring(dataForEdit[0].floor.indexOf("$") + 1)
                      .split("$")
                  : ""
              }
            ></textarea>
          </div>

          <div className="flex flex-col mb-4">
            <label className="py-4" htmlFor="">
              عکس‌های پروژه
            </label>
            <div className="flex flex-col items-center md:flex-row justify-center flex-wrap">
              {!editMode ? (
                projectImages && projectImages.length ? (
                  projectImages.map((image, index) => {
                    return (
                      <div
                        className="bg-white min-w-[90%] max-w-[90%] md:w-[300px] md:min-w-[300px] mb-5 md:ml-5 p-3 rounded-md flex relative"
                        key={index}
                      >
                        <img
                          className="h-20 ml-3 w-auto"
                          src={`../../nstd_site/uploads/${image}`}
                          alt="doc"
                        />
                        <p>{image}</p>
                        <img
                          onClick={() => handleDocDelete(image)}
                          className="absolute left-2 bottom-3 cursor-pointer"
                          src={del}
                          alt="delete"
                        />
                      </div>
                    );
                  })
                ) : null
              ) : imagesForEdit && imagesForEdit.length ? (
                imagesForEdit.map((image, index) => {
                  return (
                    <div
                      className="bg-white min-w-[90%] max-w-[90%] md:w-[300px] md:min-w-[300px] mb-5 md:ml-5 p-3 rounded-md flex relative"
                      key={index}
                    >
                      <img
                        className="h-20 ml-3 w-auto"
                        src={`../../nstd_site/uploads/${image.name}`}
                        alt="doc"
                      />
                      <p
                        title={image.name}
                        className="text-ellipsis overflow-hidden"
                      >
                        {image.name}
                      </p>
                      <img
                        onClick={() => handleDocDelete(image.name)}
                        className="absolute left-2 bottom-3 cursor-pointer"
                        src={del}
                        alt="delete"
                      />
                    </div>
                  );
                })
              ) : (
                <p className="p-4">عکسی برای نمایش وجود ندارد</p>
              )}
            </div>
            <div
              className={`${preview.length ? "flex" : "hidden"
                } rounded-md bg-white flex-wrap mt-5 flex justify-center p-3 mb-5`}
            >
              {preview.length &&
                preview.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="w-32 m-2 relative flex  justify-center items-center border border-lightgray rounded-md"
                    >
                      <img
                        alt="preview"
                        key={index}
                        className="h-auto p-4 w-20"
                        src={image.url}
                      ></img>
                      <img
                        onClick={() => handleDeletePreview(image.name)}
                        className="absolute w-5 h-5 top-1 right-1 cursor-pointer"
                        src={xclose}
                        alt="close"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="bg-white py-4 rounded-md flex justify-center items-center">
              <input
                ref={uploadInputRef}
                className="my-5 bg-white text-center"
                multiple
                onChange={handleFiles}
                type="file"
                accept="image/*"
              />
            </div>
            <LoadingButton
              title={"آپلود عکس‌ها"}
              Tstyle={
                "mt-4 py-2 text-center bg-yellow flex justify-center items-center rounded-md"
              }
              action={uploadImages}
              type={"button"}
              loadingBtn={upLoading}
            />
          </div>
          <div className="flex justify-center">
          <div className="m-4 bg-white rounded flex w-24 h-24 justify-center content-center item-center flex-row text-center" onClick={() => { getLocationGps() }}>
            <img className="h-24 w-24" src={gps}></img>
            {/* <span className="mr-4 justify-center" onClick={() => { getLocationGps() }}>ماهواره</span> */}
          </div>
          </div>
          <MapContainer
            style={{ height: "50vh", width: "100%", zIndex: "0" }}
            center={
              center
            }
            // center={{ lat: 36.0507089, lng: 50.9779044 }}
            zoom={16}
            scrollWheelZoom={true}
          >

            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
              position={position}
            // position={{lat:36.0507089,lng:50.9779044}}
            />
            <CustomMarker position={position} setPosition={setPosition} />
            <RecenterAutomatically lat={center.lat} lng={center.lng} />
          </MapContainer>
          <LoadingButton
            loadingBtn={saveLoading}
            type={"submit"}
            title={"ذخیره"}
            Tstyle={
              "flex justify-center items-center mt-4 py-2 w-full bg-darkBlue rounded-md text-white"
            }
          />
        </form>
        <AddNewWindow
          openAddNewWin={openAddNewWin}
          setOpenAddNewWin={setOpenAddNewWin}
          windows={windows}
          setWindows={setWindows}
        />
      </div >
      {
        openModal && (
          <Modal
            title={"حذف پنجره"}
            description={"آیا از حذف این عکس اطمینان دارید؟"}
            deleteAction={deleteWindow}
            setOpenModal={setOpenModal}
          />
        )
      }
      {
        openDeletePicModal && (
          <Modal
            title={"حذف عکس"}
            description={"آیا از حذف این عکس اطمینان دارید؟"}
            deleteAction={deleteDoc}
            setOpenModal={setOpenDeletePicModal}
          />
        )
      }
      <ScrollButton />
    </div >
  );
};

export default AddNewProject;
