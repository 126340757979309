import React, { useEffect, useState } from "react";
import { gregorian_to_jalali } from "../dateConvertor";
import Select from "react-select";
import { successNotify, warningNotify } from "../General/notifications";
import { Link, renderMatches, useParams } from "react-router-dom";
import { carOptions, dataLabel, options } from "../General/Config";
import TextLoading from "../General/TextLoading";
import { useRole } from "../context/roleProvider";
import PopUp from "../General/PopUp";
import { useNavigate } from "react-router-dom";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Details = ({
  projectDetails,
  imagesData,
  handleImagePopUp,
  imageRef,
  carKind, 
  setCarKind,
}) => {
  const { id } = useParams();
  const { loggedInUserInfo } = useRole();
  const [stars, setStars] = useState([]);
  const [iranSDate, setIranSDate] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const [openOfficerPopUp, setOpenOfficerPopUp] = useState(false);
  const [openOfficerPopUp2, setOpenOfficerPopUp2] = useState(false);
  

  const move_to_unsuccessful = async () => {
    try {
      if (status !== null) {
        const response = await fetch(
          `../../nstd_site/apiretprj.php?val=ناموفق&no=${id}`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          successNotify("با موفقیت انجام شد");
          navigate("/project-list");
        }
      } else {
        warningNotify("لطفا وضعیت پرونده را از لیست انتخاب کنید");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const returnProject = async () => {
    try {
      if (status !== null) {
        const response = await fetch(
          `../../nstd_site/apiretprj.php?val=ویزیت&no=${id}`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          successNotify("با موفقیت انجام شد");
          navigate("/unsuccessful-projects-list");
        }
      } else {
        warningNotify("لطفا وضعیت پرونده را از لیست انتخاب کنید");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const changeCarType = async (status, proj) => {
    try {
      if (status !== null) {
        const response = await fetch(
          `../../nstd_site/apisvcar.php?vl=${status.value}&prj=${id}`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          setCarKind(carOptions.filter((opt) => opt.value === status.value)[0]);
          successNotify("با موفقیت انجام شد");
        }
      } else {
        warningNotify("لطفا نوع خودرو را از لیست انتخاب کنید");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const changeProjectStatus = async (status, proj) => {
    console.log(proj, 'the sended data');
    try {
      if (status !== null) {
        const response = await fetch(
          `../../nstd_site/apisvcond.php?val=${status.value}&no=${id}`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          setStatus(options.filter((opt) => opt.value === status.value)[0]);
          successNotify("با موفقیت انجام شد");
        }
      } else {
        warningNotify("لطفا وضعیت پرونده را از لیست انتخاب کنید");
      }
    } catch (error) {
      console.log(error);
    }
    if (status.value === "قرارداد") {
      let customerName;
      let customerFamilyName;
      let ownerPhoneNumber;
      let ownerKodemelli;
      let naghsh;
      let pass;
      let bDate;
      customerName = proj[0].name;
      customerFamilyName = proj[0].family;
      ownerPhoneNumber = proj[0].phone;
      bDate = proj[0].birthdate;
      naghsh = 'customer';
      ownerKodemelli = proj[0].phone;
      pass = '1234';//(Math.random() + 1).toString(36).substring(7);
      console.log(customerName, customerFamilyName, ownerPhoneNumber, ownerKodemelli);
      try {
        if (status !== null) {
          const response = await fetch(
            `../../nstd_site/apisvusr.php?nm=${customerName + ' ' + customerFamilyName}&cdm=${ownerKodemelli}&mbl=${ownerPhoneNumber}&ngh=${naghsh}&usr=${ownerPhoneNumber}&psv=${pass}&pid=${proj[0].id1}&bd=${bDate}`, {
              method: "GET",
              cash: 'no-store',
            }
          );
          if (response.ok) {
            console.log("OK");
            setStatus(options.filter((opt) => opt.value === status.value)[0]);
            successNotify("مشتری با موفقیت ایجاد شد.");
          }
        } else {
          warningNotify("مشکل در ایجاد کاربر!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (projectDetails) {
      setCarKind(carOptions.filter(
        (item) => item.value === projectDetails[0].cartype
      ));
      let d = projectDetails[0].date.split(" ")[0];
      let dOne = d.split("-");
      let dTwo = gregorian_to_jalali(
        Number(dOne[0]),
        Number(dOne[1]),
        Number(dOne[2])
      ).join("-");
      setIranSDate(dTwo);
      setStatus(
        options.filter((opt) => opt.value === projectDetails[0].feval)[0]
      );
      let stars = [];
      for (let i = 1; i <= Number(projectDetails[0].rating); i++) {
        stars.push("on");
      }
      for (let i = stars.length; i < 5; i++) {
        stars.push("off");
      }
      setStars(stars);
    }
  }, [projectDetails]);

  const handleImageClick = (index) => {
    handleImagePopUp(index);
  };

  const showOfficerInfo = () => {
    setOpenOfficerPopUp(true);
  };
  const showOfficerInfo2 = () => {
    setOpenOfficerPopUp2(true);
  };

  return (
    <>
      <div className="flex justify-center mx-auto w-[95%] md:max-h-[600px] xl:w-full ">
        {imagesData ? (
          imagesData.length ? (
            <div className="ml-5 w-[30%] hidden md:flex flex-col min-h-[462px]">
              <div
                className={`bg-white overflow-clip ${imagesData.length > 1 ? "h-[65%]" : "h-full"
                  } flex justify-center items-center mb-2 rounded-md`}
              >
                <img
                  ref={(img) => (imageRef.current[0] = img)}
                  onClick={() => handleImageClick(0)}
                  className={`cursor-pointer w-auto ${imagesData.length > 1 && "md:h-[75%] lg:h-[90%]"
                    }  rounded-md`}
                  src={`../../nstd_site/uploads/${imagesData[0].name}`}
                  alt="project"
                />
              </div>
              <div
                className={`flex py-3 ${imagesData.length > 1 ? "h-[35%]" : "hidden"
                  } pr-3 w-full overflow-scroll scrollbar bg-white rounded-md`}
              >
                {imagesData.map((img, index) => {
                  if (index !== 0) {
                    return (
                      <div
                        onClick={() => handleImageClick(index)}
                        key={index}
                        className="flex cursor-pointer justify-center rounded-md items-center p-2 mx-2 border border-lightgray"
                      >
                        <img
                          ref={(img) => (imageRef.current[index] = img)}
                          alt="project"
                          className="min-w-[100px] h-auto rounded-md"
                          src={`../../nstd_site/uploads/${img.name}`}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <div className="bg-[url('./images/BW.jpg')] bg-center bg-cover bg-no-repeat hidden md:block md:w-[40%] ml-5 rounded-md"></div>
          )
        ) : (
          <div className="hidden md:w-[40%] ml-5 rounded-md min-h-[462px] bg-white md:flex md:flex-col items-center justify-center items-center">
            <TextLoading />
            <TextLoading />
          </div>
        )}
        <div className="flex flex-col w-[95%] md:w-[70%] bg-white rounded-md p-4 lg:p-5">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2">
              {
                projectDetails ? (
                  projectDetails[0].project_number ? (
                    <p className="text-red">پروژه شماره {parseInt(projectDetails[0].project_number) + 1} مشتری</p>
                  ) : null
                ) : null
              }
              {projectDetails ? (
                Object.values(dataLabel).map((item, index) => {
                  if (index !== 0 && index < 8 && item.slug !== "rating" && item.slug !== "phone") {
                    return (
                      <div key={index} className="flex py-2">
                        <p>{item.label} :</p>
                        {item.slug === "date" ? (
                          <>
                            <p>{iranSDate && iranSDate}</p>
                            <p className="mx-2">
                              {projectDetails[0][item.slug].split(" ")[1]}
                            </p>
                          </>
                        ) : (
                          <p>
                            {!projectDetails[0][item.slug] ||
                              projectDetails[0][item.slug] === "null"
                              ? null
                              : projectDetails[0][item.slug]}
                          </p>
                        )}
                      </div>
                    );
                  }
                })
              ) : (
                <TextLoading />
              )}
            </div>
            {projectDetails ? (
              <div className="w-full md:w-1/2">
                {Object.values(dataLabel).map((item, index) => {
                  while (
                    index > 8 &&
                    item.slug !== "feval" &&
                    item.slug !== "explanation"
                  )
                    return (
                      <div key={index} className="flex py-2">
                        <p>{item.label} :</p>
                        <div>
                          {item.label === "مسئول حمل" && projectDetails.trnsfornsb !== null ? (
                            <>
                              {
                                projectDetails ? (
                                  projectDetails.length > 1 ? (
                                    <p
                                      className="cursor-pointer"
                                      onClick={showOfficerInfo}
                                    >
                                      {projectDetails ? projectDetails[1].name1 ? projectDetails[1].name1 : '' : ''}
                                    </p>) : (
                                    <p
                                      className="cursor-pointer"
                                      onClick={showOfficerInfo}
                                    >
                                      {projectDetails ? projectDetails[0].name1 ? projectDetails[0].name1 : '' : ''}
                                    </p>
                                  )
                                ) : ''
                              }
                            </>

                          ) : item.label === "مسئول نصب" && projectDetails.trnsfornsb !== null ? (
                            <>
                              {
                                projectDetails.length > 1 ? (
                                  <p
                                    className="cursor-pointer"
                                    onClick={showOfficerInfo2}
                                  >
                                    {projectDetails ? projectDetails[0].name1 && projectDetails[0].trnsfornsbdt === 'install' ? projectDetails[0].name1 : '' : ''}
                                  </p>
                                ) : ''
                              }
                            </>

                          ) : item.label === "ماشین مناسب حمل" ? (
                            <>
                              {
                                projectDetails ? (
                                  loggedInUserInfo ? (
                                    loggedInUserInfo[0].naghsh === 'qc' ? (
                                      < Select
                                        onChange={(e) => changeCarType(e, projectDetails)}
                                        className="mr-5"
                                        isClearable
                                        name="cartype"
                                        options={carOptions}
                                        placeholder="لطفا انتخاب کنید ..."
                                        value={carKind}
                                      />
                                    ) : (
                                      < Select
                                        onChange={(e) => changeCarType(e, projectDetails)}
                                        className="mr-5"
                                        isClearable
                                        name="cartype"
                                        options={carOptions}
                                        placeholder="لطفا انتخاب کنید ..."
                                        value={carKind}
                                        isDisabled
                                      />
                                    )
                                  ) : (
                                    < Select
                                      onChange={(e) => changeCarType(e, projectDetails)}
                                      className="mr-5"
                                      isClearable
                                      name="cartype"
                                      options={carOptions}
                                      placeholder="لطفا انتخاب کنید ..."
                                      value={carKind}
                                      isDisabled
                                    />
                                  )

                                ) : ''
                              }
                            </>

                          ) : !projectDetails[0][item.slug] ||
                            projectDetails[0][item.slug] === "null" ? (
                            null
                          ) : (
                            projectDetails[0][item.slug]
                          )}
                        </div>
                      </div>
                    );
                })}
              </div>
            ) : (
              <TextLoading />
            )}
          </div>
          {projectDetails && (
            <>
              <div className="flex py-2">
                <p>تلفن :</p>
                {projectDetails[0].phone && (
                  <p><a href={"tel:" + projectDetails[0].phone}>
                    {projectDetails[0].phone}
                  </a></p>
                )}
              </div>
              <div className="flex py-2">
                <p>توضیحات ثبت :</p>
                {projectDetails[0].floor && (
                  <p>
                    {projectDetails[0].floor.split("$") === null
                      ? null
                      : projectDetails[0].floor.split("$")[1]}
                  </p>
                )}
              </div>
            </>
          )}
          <div className="flex py-2 items-center">
            {stars.length ? <p className="ml-3">امتیاز پروژه:</p> : ""}
            {stars.length
              ? stars.map((star, index) => {
                if (index < 5) {
                  return (
                    <svg
                      key={index}
                      alt="rating-star"
                      className="ml-2"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill={star === "on" ? "darkBlue" : "none"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4061 2.05251C15.8838 0.792392 17.6919 0.792392 18.1712 2.05251L21.2617 10.6136C21.4782 11.1809 22.0292 11.5587 22.6443 11.5587H30.2394C31.6428 11.5587 32.2549 13.3055 31.1516 14.161L25.7468 19.0238C25.5047 19.2099 25.3278 19.468 25.2414 19.7609C25.155 20.0538 25.1637 20.3665 25.2661 20.6542L27.2398 29.0197C27.7206 30.3634 26.1649 31.5175 24.9884 30.6904L17.6471 26.0321C17.3957 25.8554 17.0959 25.7606 16.7886 25.7606C16.4813 25.7606 16.1815 25.8554 15.9301 26.0321L8.58886 30.6904C7.41385 31.5175 5.85661 30.3619 6.33737 29.0197L8.31116 20.6542C8.41355 20.3665 8.42218 20.0538 8.33581 19.7609C8.24944 19.468 8.07251 19.2099 7.8304 19.0238L2.42562 14.161C1.32077 13.3055 1.9359 11.5587 3.33637 11.5587H10.9314C11.2305 11.5597 11.5228 11.4698 11.7696 11.3011C12.0165 11.1323 12.2063 10.8926 12.314 10.6136L15.4046 2.05251H15.4061Z"
                        stroke={star === "on" ? "darkBlue" : "#D8D8D8"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  );
                }
              })
              : ""}
          </div>
          {projectDetails && (
            <div className="flex items-center py-2">
              <p>وضعیت پرونده :</p>
              {loggedInUserInfo ? (
                projectDetails[0].feval !== 'ناموفق' && loggedInUserInfo[0].name === projectDetails[0].nazer && loggedInUserInfo[0].naghsh !== "Manager" && loggedInUserInfo[0].naghsh !== "sale" ? (
                  <Select
                    onChange={(e) => changeProjectStatus(e, projectDetails)}
                    className="mr-5"
                    isClearable
                    name="feval"
                    options={options}
                    placeholder="لطفا انتخاب کنید ..."
                    value={status}
                    isDisabled="true"
                  />
                ) : loggedInUserInfo[0].naghsh !== "installer" &&
                  loggedInUserInfo[0].naghsh !== 'customer' &&
                  loggedInUserInfo[0].naghsh !== 'qc' &&
                  loggedInUserInfo[0].naghsh !== 'accountant' &&
                  loggedInUserInfo[0].naghsh !== 'Visitor' &&
                  // loggedInUserInfo[0].naghsh !== 'product_manager' &&
                  loggedInUserInfo[0].naghsh !== 'coord' &&
                  projectDetails[0].feval !== 'ناموفق' &&
                  loggedInUserInfo[0].naghsh !== "transport" ? (
                  <>
                    <Select
                      onChange={(e) => changeProjectStatus(e, projectDetails)}
                      className="mr-5"
                      isClearale
                      name="feval"
                      options={options}
                      placeholder="لطفا انتخاب کنید ..."
                      value={status}
                    />
                    {
                      loggedInUserInfo[0].naghsh === 'product_manager' ? (null) : (
                        <button
                          className="m-2 bg-orange text-white p-2 rounded"
                          onClick={move_to_unsuccessful}
                        >
                          انتقال به ناموفق
                        </button>
                      )
                    }
                  </>
                ) : projectDetails[0].feval === 'ناموفق' &&
                  loggedInUserInfo[0].naghsh !== "installer" &&
                  loggedInUserInfo[0].naghsh !== 'customer' &&
                  loggedInUserInfo[0].naghsh !== 'qc' &&
                  loggedInUserInfo[0].naghsh !== 'accountant' &&
                  loggedInUserInfo[0].naghsh !== 'Visitor' &&
                  // loggedInUserInfo[0].naghsh !== 'product_manager' &&
                  loggedInUserInfo[0].naghsh !== 'coord' &&
                  loggedInUserInfo[0].naghsh !== "transport" ? (
                  <>
                    <Select
                      className="mr-5"
                      isClearable
                      name="feval"
                      options={options}
                      placeholder="لطفا انتخاب کنید ..."
                      value={status}
                      isDisabled="true"
                    />
                    {
                      loggedInUserInfo[0].naghsh === 'product_manager' ? (null) : (
                        <button
                          className="m-2 bg-blue p-2 rounded"
                          onClick={returnProject}
                        >
                          بازگردانی پروژه
                        </button>
                      )
                    }

                  </>
                ) : (
                  <Select
                    className="mr-5"
                    isClearable
                    name="feval"
                    options={options}
                    placeholder="لطفا انتخاب کنید ..."
                    value={status}
                    isDisabled
                  />
                )
              ) : null}
            </div>
          )}
          {projectDetails && (
            <div className="flex py-2">
              <p>{dataLabel[0].label} :</p>
              <p>
                {!projectDetails[0][dataLabel[0].slug] ||
                  projectDetails[0][dataLabel[0].slug] === "null"
                  ? null
                  : projectDetails[0][dataLabel[0].slug]}
              </p>
            </div>
          )}
          {projectDetails && (
            <div className="flex py-2">
              <p>رتبه کاربر :</p>
              <p>
                {projectDetails[0].crank === null ? 'رتبه بندی نشده' : projectDetails[0].crank}
              </p>
            </div>
          )}
        </div>
      </div>
      <PopUp
        title={"مشخصات مسئول حمل"}
        content={
          projectDetails ? (
            projectDetails[0] ? (
              projectDetails[0].trnsfornsbdt === 'transit' ? (
                <>
                  <div className="w-full mx-10">
                    <div className="flex mx-5 my-3">
                      <p>نام:</p>
                      <p>{projectDetails ? projectDetails[0].name1 : 'name1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>نام خانوادگی :</p>
                      <p>{projectDetails ? projectDetails[0].name1 : 'name1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>شماره موبایل :</p>
                      <p>{projectDetails ? projectDetails[0].mob1 : 'mob1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>کد ملی :</p>
                      <p>{projectDetails ? projectDetails[0].kod1 : 'kod1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>پلاک ماشین :</p>
                      <p>{projectDetails ? projectDetails[0].tav1.split("*")[0] : 'تعریف نشده'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>نوع ماشین :</p>
                      <p>{projectDetails ? projectDetails[0].tav1.split("*")[1] : 'تعریف نشده'}</p>
                    </div>
                  </div>
                </>
              ) : projectDetails[1] ? (
                projectDetails[1].trnsfornsbdt === 'transit' ? (
                  <>
                    <div className="w-full mx-10">
                      <div className="flex mx-5 my-3">
                        <p>نام:</p>
                        <p>{projectDetails ? projectDetails[1].name1 : 'name1'}</p>
                      </div>
                      <div className="flex mx-5 my-3">
                        <p>نام خانوادگی :</p>
                        <p>{projectDetails ? projectDetails[1].name1 : 'name1'}</p>
                      </div>
                      <div className="flex mx-5 my-3">
                        <p>شماره موبایل :</p>
                        <p>{projectDetails ? projectDetails[1].mob1 : 'mob1'}</p>
                      </div>
                      <div className="flex mx-5 my-3">
                        <p>کد ملی :</p>
                        <p>{projectDetails ? projectDetails[1].kod1 : 'kod1'}</p>
                      </div>
                      <div className="flex mx-5 my-3">
                        <p>پلاک ماشین :</p>
                        <p>{projectDetails ? projectDetails[1].tav1.split("*")[0] : 'تعریف نشده'}</p>
                      </div>
                      <div className="flex mx-5 my-3">
                        <p>نوع ماشین :</p>
                        <p>{projectDetails ? projectDetails[1].tav1.split("*")[1] : 'تعریف نشده'}</p>
                      </div>
                    </div>
                  </>
                ) : ''
              ) : ''
            ) : ''
          ) : ''

        }
        showPopUp={openOfficerPopUp}
        setShowPopUp={setOpenOfficerPopUp}
      ></PopUp>
      <PopUp
        title={"مشخصات مسئول نصب"}
        content={
          projectDetails ? (
            projectDetails[1] ? (
              projectDetails[1].trnsfornsbdt === 'install' ? (
                <>
                  <div className="w-full mx-10">
                    <div className="flex mx-5 my-3">
                      <p>نام:</p>
                      <p>{projectDetails ? projectDetails[1].name1 : 'name1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>نام خانوادگی :</p>
                      <p>{projectDetails ? projectDetails[1].name1 : 'name1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>شماره موبایل :</p>
                      <p>{projectDetails ? projectDetails[1].mob1 : 'mob1'}</p>
                    </div>
                    <div className="flex mx-5 my-3">
                      <p>کد ملی :</p>
                      <p>{projectDetails ? projectDetails[1].kod1 : 'kod1'}</p>
                    </div>
                    {/* <div className="flex mx-5 my-3">
                <p>پلاک ماشین :</p>
                <p>{projectDetails ? projectDetails[0].tav1:'tav1'}</p>
              </div>
              <div className="flex mx-5 my-3">
                <p>نوع ماشین :</p>
                <p>{projectDetails ? projectDetails[0].tav1:'tav1'}</p>
              </div> */}

                  </div>
                </>) : projectDetails[0] ? (
                  projectDetails[0].trnsfornsbdt === 'install' ? (
                    <>
                      <div className="w-full mx-10">
                        <div className="flex mx-5 my-3">
                          <p>نام:</p>
                          <p>{projectDetails ? projectDetails[0].name1 : 'name1'}</p>
                        </div>
                        <div className="flex mx-5 my-3">
                          <p>نام خانوادگی :</p>
                          <p>{projectDetails ? projectDetails[0].name1 : 'name1'}</p>
                        </div>
                        <div className="flex mx-5 my-3">
                          <p>شماره موبایل :</p>
                          <p>{projectDetails ? projectDetails[0].mob1 : 'mob1'}</p>
                        </div>
                        <div className="flex mx-5 my-3">
                          <p>کد ملی :</p>
                          <p>{projectDetails ? projectDetails[0].kod1 : 'kod1'}</p>
                        </div>
                        {/* <div className="flex mx-5 my-3">
                <p>پلاک ماشین :</p>
                <p>{projectDetails ? projectDetails[0].tav1:'tav1'}</p>
              </div>
              <div className="flex mx-5 my-3">
                <p>نوع ماشین :</p>
                <p>{projectDetails ? projectDetails[0].tav1:'tav1'}</p>
              </div> */}

                      </div>
                    </>
                  ) : ''
                ) : ''
            ) : null) : null
        }
        showPopUp={openOfficerPopUp2}
        setShowPopUp={setOpenOfficerPopUp2}
      ></PopUp>
    </>
  );
};

export default Details;
