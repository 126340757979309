import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MapContainer, TileLayer } from "react-leaflet";
import arrow from "../icons/arrow.svg";
import del from "../icons/Delete.svg";
import Modal from "../General/Modal";
import { Marker } from "react-leaflet";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { callNotify, successNotify, warningNotify } from "../General/notifications";
import xclose from "./../icons/xclose.svg";
import LoadingButton from "../General/LoadingButton";
import Details from "./Details";
import Notes from "./Notes";
import ScrollButton from "../General/ScrollButton";
import ImagePopUp from "../General/ImagePopUp";
import left from "../icons/left.svg";
import TextLoading from "../General/TextLoading";
import Compressor from "compressorjs";
import doc from "../icons/doc.svg";
import pdf from "../icons/pdf.png";
import { useRole } from "../context/roleProvider";
import CountDown from "./CountDown";
import CountDown2 from "./CountDown2";
import { gregorian_to_jalali } from "../dateConvertor";
import { DateObject } from "react-multi-date-picker";
import checkMark from "../icons/checkMark.svg";
import PopUp from "../General/PopUp";
import { survey } from "../General/Config";
import AddAlarm from "../NewProject/AddAlarm";
import ToggleSwitch from "../General/ToggleSwitch";
import Alarms from "../Alarms/Alarms";
import ProjectAlarms from "../Alarms/ProjectAlarms";

const ProjectDetails = () => {
  const { loggedInUserInfo } = useRole();
  const imageRef = useRef([]);
  const docRef = useRef();
  const docsizeRef = useRef();
  const sizeRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const [showDocsPopUp, setShowDocsPopUp] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [allSMS, setAllSMS] = useState(null);
  const [location, setLocation] = useState();
  const [order, setOrder] = useState(null);
  const [currentInx, setCurrentInx] = useState(null);
  const [imagesData, setImagesData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [docNameForDelete, setDocNameForDelete] = useState(null);
  const [preview, setPreview] = useState([]);
  const [preview2, setPreview2] = useState([]);
  const [preview3, setPreview3] = useState([]);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [projectLevel, setProjectLevel] = useState(null);
  const uploadInputRef = useRef();
  const uploadInputRef2 = useRef();
  const [docLoading, setDocLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState(false);
  const [projectDocs, setProjectDocs] = useState(null);
  const [projectDocs2, setProjectDocs2] = useState(null);
  const [hideRight, setHideRight] = useState(false);
  const [hideLeft, setHideLeft] = useState(false);
  const [currentIndex, setCurrentIndex] = useState({
    imageLeft: 0,
    imageRight: 0,
    docLeft: 0,
    docRight: 0,
  });
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState({ index: true });
  const [selectedDay, setSelectedDay] = useState(null);
  const [nsbDate, setNsbDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [garantyDate, setGarantyDate] = useState(null);
  const [confirmProject, setConfirmProject] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openIssueReport, setOpenIssueReport] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [showCustomerAccountForm, setShowCustomerAccountForm] = useState(false);
  const [reject, setReject] = useState('');
  const [acccheck, setAcccheck] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [upLoading, setUpLoading] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [alarmRefresh, setAlarmRefresh] = useState(false);
  const [carKind, setCarKind] = useState(null);

  useEffect(() => {
    if (projectDetails && projectDetails[0].target) {
      setEndDate(new DateObject(projectDetails[0].target));
    }
    if (projectDetails && projectDetails[0].garanty) {
      setGarantyDate(new DateObject(projectDetails[0].garanty));
    }
  }, [projectDetails]);

  useEffect(() => {
    console.log(endDate, "ee");
  }, [endDate]);

  useEffect(() => {
    if (showImagePopUp || showDocsPopUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showImagePopUp, showDocsPopUp]);

  const slideRef = useRef();
  const target = useRef();
  const outerTarget = useRef([]);

  const handleClickOutside = (e) => {
    // for (let i = 0; i <= outerTarget.current.length - 1; i++) {
    // console.log(target);
    // // console.log(outerTarget.current[i]);
    // e.stopPropagation();
    // if (!e.target.onClick(target).length) {
    //   console.log("yes");
    //   setCurrentInx(null);
    // }
    // }
  };

  const getProjectImages = async () => {
    //
    try {
      const response = await fetch(
        `../../nstd_site/api5.php?id=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let imagesData = await response.json();
      setImagesData(imagesData);
      // console.log(imagesData, "imagesData");
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectDocs = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apidocs.php?id=${id}&dt=0`, {
          method: "GET",
          cash: 'no-store',
        }
      );

      let projectsDocs = await response.json();
      setProjectDocs(projectsDocs);
      // console.log("projectDocs", projectDocs);
    } catch (error) {
      console.log(error);
    }
  };
  const uploadImages = async () => {
    console.log(files3, "files");
    if (files3.length) {
      setUpLoading(true);
      for (let i = 0; i < files3.length; i++) {
        const fd = new FormData();
        fd.append("myFile", files3[i]);
        fd.append("id", projectDetails[0].id1);
        try {
          const response = await fetch(
            "../../nstd_site/upload.php",
            {
              method: "POST",
              cash: 'no-store',
              body: fd,
            }
          );
          if (response.ok) {
            console.log("OK");
            getProjectImages();
            if (i === files3.length - 1) {
              successNotify("با موفقیت آپلود شد");
              setUpLoading(false);
            }
            setPreview3([]);
          } else {
            if (i === files3.length - 1) {
              setUpLoading(false);
              warningNotify("لطفا دوباره تلاش کنید");
            }
          }
        } catch (error) {
          console.log(error);
          if (i === files3.length - 1) {
            setUpLoading(false);
            warningNotify("لطفا دوباره تلاش کنید");
          }
        }
      }
    } else {
      warningNotify("شما فایلی را برای آپلود انتخاب نکرده‌اید");
      return;
    }
  };
  const getProjectDocs2 = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apidocs.php?id=${id}&dt=1`, {
          method: "GET",
          cash: 'no-store',
        }
      );

      let projectsDocs2 = await response.json();
      setProjectDocs2(projectsDocs2);
      // console.log("projectDocs", projectDocs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getProjectDetails = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/api4.php?id=${id}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let data = await response.json();
        setProjectDetails(data);
        console.log(data, "dataaaaaaaaaaaaaa");
        setSelectedDay(data[0].target);
        setEndDate(data[0].target);
        setNsbDate(data[0].nsbdate);
      } catch (error) {
        console.log(error);
      }
    };

    const getOtherInfo = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apigtnt.php?id=${id}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let otherData = await response.json();
        console.log(otherData, "otherData");
      } catch (error) {
        console.log(error);
      }
    };
    const getAccCheck = async () => {
      try {
        const response = await fetch(
          `../../nstd_site/apisvacccnf.php?id=${id}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let rspd = await response.json();
        setAcccheck(rspd)
      } catch (error) {
        console.log(error);
      }
    };

    getProjectDocs();
    getProjectDocs2();
    getOtherInfo();
    getAccCheck();
    getProjectDetails();
    getProjectImages();
  }, []);

  const getAllShortMessages = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apisms.php?id=${projectDetails[0].phone}&pid=${id}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let SMSdata = await response.json();
      setAllSMS(SMSdata);
      // console.log(SMSdata, "kjhkhjhjhjhjh");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectDetails) {
      let input = projectDetails[0].level;
      let ordersArray = input.split("#");
      let ord = [];
      for (let i = 0; i < ordersArray.length - 1; i++) {
        if (i === 0) {
          ord.push({
            name: ordersArray[i].split("$")[1].split("*")[0],
            width: ordersArray[i].split("$")[1].split("*")[1],
            height: ordersArray[i].split("$")[1].split("*")[2],
            quantity: ordersArray[i].split("$")[1].split("*")[3],
            code: ordersArray[i].split("$")[1].split("*")[4],
          });
        } else {
          let splitItem = "";
          for (let j = 1; j <= i; j++) {
            splitItem = splitItem + "\n";
          }
          if (ordersArray[i].split(splitItem)[1]) {
            ord.push({
              code: ordersArray[i].split(splitItem)[1].split("*")[0],
              quantity: ordersArray[i].split(splitItem)[1].split("*")[1],
              height: ordersArray[i].split(splitItem)[1].split("*")[2],
              width: ordersArray[i].split(splitItem)[1].split("*")[3],
              name: ordersArray[i].split(splitItem)[1].split("*")[4],
              data: '',
            });
          }
        }
      }
      setProjectLevel(ordersArray[0].split("$")[0]);
      setOrder(ord);

      const getLocation = () => {
        let input = projectDetails[0].location;
        if (location !== "no") {
          let lat = Number(input.substring(0, input.indexOf("*")));
          let lng = Number(input.substring(input.indexOf("*") + 1));
          if (lat && lng) {
            setLocation({ lat: lat, lng: lng });
          }
        } else {
          setLocation(null);
        }
      };

      getAllShortMessages();
      getLocation();
    }
  }, [projectDetails]);

  const handleSendShortMessage = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let text = formData.get("textMSG");
    let phone = formData.get("phone");
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].value.match(/^$|\s +/) && e.target[i].type !== "submit") {
        warningNotify(`لطفا ${e.target[i].id} را به درستی وارد نمایید`);
        return;
      }
    }
    const LOCAL_STORAGE_AUTH_KEY = "lgn";
    setMsgLoading(true);
    let userNationalCode = localStorage
      .getItem(LOCAL_STORAGE_AUTH_KEY)
      .split("=")[1];
    let idd = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0];

    try {
      const response = await fetch("../../nstd_site/sndms.php", {
        method: "POST",
        cash: 'no-store',
        body: JSON.stringify({
          id: idd,
          id1: userNationalCode,
          p: phone,
          t: text,
          s: userNationalCode,
          pid: id,
        }),
      });
      console.log(response.ok);
      if (response.ok) {
        successNotify("با موفقیت ارسال شد");
        e.target.reset();
        setMsgLoading(false);
        getAllShortMessages();
      } else {
        setMsgLoading(false);
        warningNotify("لطفا دوباره تلاش کنید");
      }
    } catch (error) {
      console.log(error);
      setMsgLoading(false);
      warningNotify("لطفا دوباره تلاش کنید");
    }
  };

  const handleDocDelete = async (docName) => {
    setOpenModal(true);
    setDocNameForDelete(docName);
  };

  const deleteDoc = async () => {
    try {
      const response = await fetch(
        `../../nstd_site/apidldc.php?id=${id}&fl=${docNameForDelete}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت انجام شد");
        setOpenModal(false);
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
        setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    }
    getProjectDocs();
    getProjectDocs2();
  };
  const approveDoc = async (imageName, index) => {
    console.log(imageName, "imageName");
    setVerifyLoading({ [index]: true });
    try {
      const response = await fetch(
        `../../nstd_site/apiapvdoc.php?id=${id}&fl=${imageName}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      console.log(response);
      if (response.ok) {
        getProjectDocs();
        getProjectDocs2();
        successNotify("با موفقیت انجام شد");
        setVerifyLoading({ [index]: false });
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
        setVerifyLoading({ [index]: false });
      }
    } catch (error) {
      console.log(error);
      setVerifyLoading({ [index]: false });
      warningNotify("لطفا دوباره تلاش کنید");
    }
  };

  const uploadDoc = async () => {
    console.log(files, "files");
    if (files.length) {
      setDocLoading(true);
      for (let i = 0; i < files.length; i++) {
        const fd = new FormData();
        fd.append("id", id);
        fd.append("dt", '0');
        fd.append("myFile", files[i]);
        try {
          const response = await fetch(
            "../../nstd_site/upload2.php",
            {
              method: "POST",
              cash: 'no-store',
              body: fd,
            }
          );
          if (response.ok) {
            console.log("OK");
            if (i === files.length - 1) {
              setDocLoading(false);
              getProjectDocs();
              setPreview([]);
              setFiles([]);
              uploadInputRef.current.value = "";
            }
          } else {
            if (i === files.length - 1) {
              setDocLoading(false);
              warningNotify("لطفا دوباره تلاش کنید");
            }
          }
        } catch (error) {
          console.log(error);
          if (i === files.length - 1) {
            setDocLoading(false);
            warningNotify("لطفا دوباره تلاش کنید");
          }
        }
      }
    } else {
      warningNotify("شما فایلی را برای آپلود انتخاب نکرده‌اید");
      return;
    }
  };
  const uploadDoc2 = async () => {
    console.log(files2, "files2");
    if (files2.length) {
      setDocLoading(true);
      for (let i = 0; i < files2.length; i++) {
        const fd = new FormData();
        fd.append("id", id);
        fd.append("dt", '1');
        fd.append("myFile", files2[i]);
        try {
          const response = await fetch(
            "../../nstd_site/upload2.php",
            {
              method: "POST",
              cash: 'no-store',
              body: fd,
            }
          );
          if (response.ok) {
            console.log("OK");
            if (i === files2.length - 1) {
              setDocLoading(false);
              getProjectDocs2();
              setPreview2([]);
              setFiles2([]);
              uploadInputRef2.current.value = "";
            }
          } else {
            if (i === files2.length - 1) {
              setDocLoading(false);
              warningNotify("لطفا دوباره تلاش کنید");
            }
          }
        } catch (error) {
          console.log(error);
          if (i === files2.length - 1) {
            setDocLoading(false);
            warningNotify("لطفا دوباره تلاش کنید");
          }
        }
      }
    } else {
      warningNotify("شما فایلی را برای آپلود انتخاب نکرده‌اید");
      return;
    }
  };
  useEffect(() => {
    if (!preview.length) {
      try {
        uploadInputRef.current.value = "";

      } catch (e) {
        console.log(e, "error of upload");
      }
    }
  }, [preview]);
  useEffect(() => {
    if (!preview2.length) {
      try {
        uploadInputRef2.current.value = "";
      } catch {

      }
    }
  }, [preview2]);

  const handleFiles3 = (e) => {
    // setPreview3([]);
    // setFiles3([]);
    for (let i = 0; i < e.target.files.length; i++) {
      if (
        e.target.files[i].size > 900000 &&
        e.target.files[i].type.startsWith("image")
      ) {
        new Compressor(e.target.files[i], {
          quality: 0.9, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            let blobToFile = new File(
              [compressedResult],
              compressedResult.name
            );
            setFiles3((prev) => [...prev, blobToFile]);
          },
        });
      } else {
        setFiles3((prev) => [...prev, e.target.files[i]]);
      }
      setPreview3((prev) => [
        ...prev,
        {
          url: URL.createObjectURL(e.target.files[i]),
          name: e.target.files[i].name,
          type: e.target.files[i].type,
        },
      ]);
    }
  };
  const handleFiles = (e) => {
    setPreview([]);
    setFiles([]);
    for (let i = 0; i < e.target.files.length; i++) {
      if (
        e.target.files[i].size > 900000 &&
        e.target.files[i].type.startsWith("image")
      ) {
        new Compressor(e.target.files[i], {
          quality: 0.9, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            let blobToFile = new File(
              [compressedResult],
              compressedResult.name
            );
            setFiles((prev) => [...prev, blobToFile]);
          },
        });
      } else {
        setFiles((prev) => [...prev, e.target.files[i]]);
      }
      setPreview((prev) => [
        ...prev,
        {
          url: URL.createObjectURL(e.target.files[i]),
          name: e.target.files[i].name,
          type: e.target.files[i].type,
        },
      ]);
    }
    getProjectDocs();
  };
  const handleSurveyCommit = async (e) => {
    e.preventDefault();
    const form1 = new FormData(e.currentTarget);
    // console.log(form1.get("afk"), 'the survey form data');
    console.log(form1.get("r2"), 'for test the radioooooooooooooooo')
    let data = [];
    for (let i = 0; i < survey.length; i++) {
      if (form1.get("r" + i)) {
        data.push({
          question: form1.get("r" + i),
        });
      }
    }
    data.push({
      matn: form1.get('mtn'),
    });
    if (!data.length) {
      warningNotify('لطفا فرم را کامل کنید.');
      return;
    }
    // send data to backend
    try {
      let no = loggedInUserInfo[0].kodemelli;
      let pid = projectDetails[0].id1;
      const response = await fetch(
        `../../nstd_site/apisviss.php?no=${no}&pid=${pid}`,
        {
          method: "POST",
          cash: 'no-store',
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("با موفقیت ثبت شد.");
        setConfirmProject(true);
        setOpenSurvey(false);
      }
    } catch (error) {
      console.log(error);
    }
    // ثدی خب سثدی
    setShowCountDown(true);

    console.log(data, "the result of radioooooooooooooooooooooooooooooooooooos");
  }
  const handleFiles2 = (e) => {
    setPreview2([]);
    setFiles2([]);
    for (let i = 0; i < e.target.files.length; i++) {
      if (
        e.target.files[i].size > 900000 &&
        e.target.files[i].type.startsWith("image")
      ) {
        new Compressor(e.target.files[i], {
          quality: 0.9, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            let blobToFile = new File(
              [compressedResult],
              compressedResult.name
            );
            setFiles2((prev) => [...prev, blobToFile]);
          },
        });
      } else {
        setFiles2((prev) => [...prev, e.target.files[i]]);
      }
      setPreview2((prev) => [
        ...prev,
        {
          url: URL.createObjectURL(e.target.files[i]),
          name: e.target.files[i].name,
          type: e.target.files[i].type,
        },
      ]);
    }
    getProjectDocs2();
  };

  const handleAcceptProject = async (e, ngh, id, id1) => {

    if (ngh === 'installer') {
      const response = await fetch(
        `../../nstd_site/apisvins.php?cmd=ch&id=${id}&prj=${id1}&cmd=ch`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    } else if (ngh === 'transport') {
      const response = await fetch(
        `../../nstd_site/apisvtransit.php?cmd=ch&id=${id}&prj=${id1}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    }


  };
  const handleEscapeProject = async (e, ngh, id, id1) => {

    if (ngh === 'installer') {
      const response = await fetch(
        `../../nstd_site/apisvins.php?cmd=uch&id=${id}&prj=${id1}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    } else if (ngh === 'transport') {
      const response = await fetch(
        `../../nstd_site/apisvtransit.php?cmd=uch&id=${id}&prj=${id1}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    }


  };
  const handleEndOfInstallation = async (e, ngh, id, id1) => {

    if (ngh === 'installer') {
      let t = 'تحویل به مشتری';
      const response = await fetch(
        `../../nstd_site/apisvcond.php?val=${t}&no=${id1}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    }

  };
  const handleEndOfTranspotation = async (e, ngh, id, id1) => {

    if (ngh === 'transport') {
      let t = 'آماده نصب';
      const response = await fetch(
        `../../nstd_site/apisvcond.php?val=${t}&no=${id1}`,
        {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت ثبت شد.");
        navigate('/project-list');
      } else {
        warningNotify("اشکال در شبکه!");
      }
    }
  };
  const handleEditProject = () => {
    if (projectDetails) {
      navigate("/new-project", {
        state: {
          editMode: true,
          projectDetails: projectDetails,
          imagesData: imagesData,
          projectID: id,
          orders: order,
          rating: projectDetails[0].rating,
        },
      });
    }
  };

  const handleOpenSub = (index) => {
    if (currentInx === index) {
      setCurrentInx(null);
    } else {
      setCurrentInx(index);
    }
  };
  const handleOpenMessage = (e) => {
    console.log('open message executed');
    setOpenMessages(!openMessages);
  };

  const handleDeletePreview = (name) => {
    console.log(name);
    setPreview((prev) => [...prev.filter((img) => img.name !== name)]);
    setFiles((prev) => [...prev.filter((img) => img.name !== name)]);
  };
  const handleDeletePreview3 = (name) => {
    console.log(name);
    setPreview3((prev) => [...prev.filter((img) => img.name !== name)]);
    setFiles3((prev) => [...prev.filter((img) => img.name !== name)]);
  };

  const handleRight = (type) => {
    setHideLeft(false);
    if (
      type === "img" &&
      slideRef &&
      slideRef.current &&
      -Number(String(slideRef.current.scrollLeft).split(".")[0]) ===
      slideRef.current.offsetWidth
    ) {
      console.log("start");
      setHideRight(true);
    }
    if (
      type === "doc" &&
      docRef &&
      docRef.current &&
      -Number(String(docRef.current.scrollLeft).split(".")[0]) ===
      docsizeRef.current.offsetWidth
    ) {
      setHideRight(true);
    }
    if (type === "img" && sizeRef && sizeRef.current) {
      slideRef.current.scrollLeft += sizeRef.current.offsetWidth;
    } else if (docRef && docRef.current && docsizeRef && docsizeRef.current) {
      docRef.current.scrollLeft += docsizeRef.current.offsetWidth;
    }
  };

  const handleLeft = (type) => {
    setHideRight(false);
    if (type === "img" && slideRef && slideRef.current) {
      slideRef.current.scrollLeft += -sizeRef.current.offsetWidth;
    } else if (docRef && docRef.current && docsizeRef && docsizeRef.current) {
      docRef.current.scrollLeft += -docsizeRef.current.offsetWidth;
      let length = projectDocs.length - 2;
      let scrollL = Number(String(docRef.current.scrollLeft).split(".")[0]);
      if (-scrollL >= docsizeRef.current.offsetWidth * length - 5) {
        setHideLeft(true);
      }
    }
  };
  const listenScrollEvent = () => {
    if (
      projectDocs &&
      projectDocs.length &&
      docRef &&
      docsizeRef &&
      docsizeRef.current
    ) {
      let imageDocs = projectDocs.filter(
        (doc) =>
          doc.name.split(".")[1].toLowerCase() === "jpeg" ||
          doc.name.split(".")[1].toLowerCase() === "jpg" ||
          doc.name.split(".")[1].toLowerCase() === "png" ||
          doc.name.split(".")[1].toLowerCase() === "tif" ||
          doc.name.split(".")[1].toLowerCase() === "tiff" ||
          doc.name.split(".")[1].toLowerCase() === "psd" ||
          doc.name.split(".")[1].toLowerCase() === "webp" ||
          doc.name.split(".")[1].toLowerCase() === "eps"
      );
      let length = imageDocs.length - 1;
      let scrollL = Number(String(docRef.current.scrollLeft).split(".")[0]);
      console.log(docsizeRef.current.offsetWidth * length - 5);
      if (imageDocs.length === 1) {
        setHideLeft(true);
        setHideRight(true);
        return;
      } else if (
        -Number(String(docRef.current.scrollLeft).split(".")[0]) === 0
      ) {
        setHideRight(true);
        setHideLeft(false);
      } else if (-scrollL >= docsizeRef.current.offsetWidth * length - 5) {
        setHideLeft(true);
        setHideRight(false);
      } else {
        setHideLeft(false);
        setHideRight(false);
      }
    }
  };
  const listenScrollEventImages = () => {
    if (imagesData && imagesData.length) {
      let scrollL = Number(String(slideRef.current.scrollLeft).split(".")[0]);
      let length = imagesData.length - 1;
      if (-Number(String(slideRef.current.scrollLeft).split(".")[0]) === 0) {
        setHideRight(true);
        setHideLeft(false);
      } else if (-scrollL >= sizeRef.current.offsetWidth * length - 5) {
        setHideLeft(true);
        setHideRight(false);
      } else {
        setHideLeft(false);
        setHideRight(false);
      }
    }
  };

  const handleDocPopUp = (imageName) => {
    let imageDocs = projectDocs.filter(
      (doc) =>
        doc.name.split(".")[1].toLowerCase() === "jpeg" ||
        doc.name.split(".")[1].toLowerCase() === "jpg" ||
        doc.name.split(".")[1].toLowerCase() === "png" ||
        doc.name.split(".")[1].toLowerCase() === "tif" ||
        doc.name.split(".")[1].toLowerCase() === "tiff" ||
        doc.name.split(".")[1].toLowerCase() === "psd" ||
        doc.name.split(".")[1].toLowerCase() === "webp" ||
        doc.name.split(".")[1].toLowerCase() === "eps"
    );
    let imageIndex = imageDocs.findIndex((img) => img.name === imageName);
    setShowDocsPopUp(true);
    listenScrollEvent();
    if (docRef && docRef.current && docsizeRef && docsizeRef.current) {
      docRef.current.scrollLeft = imageIndex * -docsizeRef.current.offsetWidth;
    }
  };

  const handleImagePopUp = (index) => {
    setShowImagePopUp(true);
    listenScrollEventImages();
    if (sizeRef && sizeRef.current && slideRef && slideRef.current) {
      slideRef.current.scrollLeft = index * -sizeRef.current.offsetWidth;
    }
  };

  const handleCloseDocPopUp = () => {
    setShowDocsPopUp(false);
    setTimeout(() => {
      docRef.current.scrollLeft = 0;
    }, [1000]);
    setCurrentIndex({ image: 0, doc: 0 });
  };

  const handleCloseImagePopUp = () => {
    setShowImagePopUp(false);
    setTimeout(() => {
      slideRef.current.scrollLeft = 0;
    }, [1000]);
    setCurrentIndex({ image: 0, doc: 0 });
  };
  const openGoogleMap = (location) => {
    // window.open(
    //   `http://maps.google.com/maps?z=12&t=m&q=loc:${location.lat}+${360 + location.lng}`
    // );
    window.open(
      `http://maps.google.com?daddr=${location.lat},${location.lng}&amp;ll=`
    );
  };

  const handleProjectRejection = (e) => {
    if (e.target.value === "on") {
      setShowRejectionReason(true);
    } else {
      setShowRejectionReason(false);
    }
  };
  const handleReject = async (e, txt, a, kid) => {
    // send data to backend
    try {
      let t = 'تولید';
      const response = await fetch(
        `../../nstd_site/apisvcond.php?val=${t}&no=${a}&txt=${txt}&kid=${kid}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("پروژه رد شد و به مرحله قبل منتقل شد.");
        navigate(`/projects-list`);
      }
    } catch (error) {
      console.log(error);
    }
    // ثدی خب سثدی
    setShowCountDown(true);
  }
  const handleRejectionText = (e) => {
    setReject(e.target.value);
    console.log(e.target.value);
  }

  const handleProjectConfirmation = (e) => {
    if (e.target.value === "on") {
      setShowRejectionReason(false);
    } else {
      setShowRejectionReason(true);
    }
  };

  const handleOpenSurvey = () => {
    setOpenSurvey(true);
  };

  const issueReport = () => {
    setOpenIssueReport(true);
  };

  const sendIssueReport = async (e, nsbId) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let issue = formData.get("issue");
    if (issue.match(/^$|\s +/)) {
      warningNotify("لطفا متن مشکل را به درستی وارد نمائید");
      return;
    } else {
      console.log(issue);
      setLoadingBtn(true);
      try {
        let no = loggedInUserInfo[0].kodemelli;
        let pid = projectDetails[0].id1;
        const response = await fetch(
          `../../nstd_site/apisviss2.php?no=${no}&pid=${pid}&txt=${issue}&tar=${nsbId}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          setLoadingBtn(false);
          e.target.reset();
          successNotify("با موفقیت ثبت شد.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const storageOk = async (a) => {
    try {
      let t = 'تولید';
      const response = await fetch(
        `../../nstd_site/apisvcond.php?val=${t}&no=${a}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
      }
    } catch (error) {
      console.log(error);
    }
    try {
      // let t2 = '0';
      // if (e.currentTarget.checked) {
      //   t2 = '1';
      // }
      const response = await fetch(
        `../../nstd_site/apisvcocf.php?val=1&no=${a}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("با موفقیت انجام شد");
        navigate(`/projects-list`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const confirmProjectButt = async (a) => {
    // successNotify("پروژه تائید شد و روزشمار پروژه آغاز می‌شود");
    if (carKind.length===0) {
      warningNotify("لطفا ابتدا در بالای صفحه نوع ماشین حمل را تعیین کنید.");
      return;
    } else {
      successNotify("پروژه تائید شد.");
      // send data to backend
      try {
        let t = 'آماده بارگیری';
        const response = await fetch(
          `../../nstd_site/apisvcond.php?val=${t}&no=${a}`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          successNotify("با موفقیت انجام شد");
          navigate(`/projects-list`);
        }
      } catch (error) {
        console.log(error);
      }
      // ثدی خب سثدی
      setShowCountDown(true);
    }

  };
  const saleConfirm = async (a) => {
    // successNotify("پروژه تائید شد و روزشمار پروژه آغاز می‌شود");
    let pid = projectDetails[0].id1;
    let t = '0';
    if (a.currentTarget.checked) {
      t = '1';
    }
    // send data to backend
    try {
      const response = await fetch(
        `../../nstd_site/apisvslcf.php?val=${t}&id=${pid}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("تائید فروش انجام گردید.");
      }
    } catch (error) {
      console.log(error);
    }
    // ثدی خب سثدی
    setShowCountDown(true);
  };
  const checkAcc = async (e) => {
    let pid = projectDetails[0].id1;
    if (e.currentTarget.checked) {
      try {
        const response = await fetch(
          `../../nstd_site/apisvacccnf.php?no=${pid}&val=1`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          successNotify("با موفقیت انجام شد");
          navigate(`/projects-list`);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await fetch(
          `../../nstd_site/apisvacccnf.php?no=${pid}&val=0`, {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          console.log("OK");
          successNotify("با موفقیت انجام شد");
          navigate(`/projects-list`);
        }
      } catch (error) {
        console.log(error);
      }
      warningNotify("test");
    }
  }
  const confirmProjectButtCust = async (e, a) => {
    successNotify("پروژه تائید شد و روزشمار پروژه آغاز می‌شود");
    // send data to backend
    try {
      let t = 'اتمام پروژه';
      const response = await fetch(
        `../../nstd_site/apisvcond.php?val=${t}&no=${a}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("با موفقیت انجام شد");
        navigate(`/projects-list`);
      }
    } catch (error) {
      console.log(error);
    }
    // ثدی خب سثدی
    setShowCountDown(true);
  };

  const createUser = async (e) => {
    e.preventDefault();
    let customerName;
    let customerFamilyName;
    let ownerPhoneNumber;
    let ownerKodemelli;
    let naghsh;
    let pass;
    const formData = new FormData(e.currentTarget);
    customerName = formData.get("name");
    customerFamilyName = formData.get("familyName");
    ownerPhoneNumber = formData.get("ownerPhoneNumber");
    ownerKodemelli = formData.get("ownerKodemelli");
    naghsh = 'customer';
    pass = (Math.random() + 1).toString(36).substring(7);
    console.log(customerName, customerFamilyName, ownerPhoneNumber);
    try {
      const response = await fetch(
        `../../nstd_site/apisvusr.php?nm=${customerName + ' ' + customerFamilyName}&cdm=${ownerKodemelli}&mbl=${ownerPhoneNumber}&ngh=${naghsh}&usr=${ownerPhoneNumber}&psv=${pass}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        console.log("OK");
        successNotify("با موفقیت انجام شد");
        // navigate(`/projects-list`);
      }
    } catch (error) {
      console.log(error);
    }
    setShowCustomerAccountForm(false);
  };
  const tickSeen = async (id, code) => {
    try {
      const response = await fetch(
        `../../nstd_site/apisvseen.php?kd=${id}&sn=${code}`,
        {
          method: "GET",
          cash: 'no-store',

        }
      );
      // let data = await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        onClick={handleClickOutside}
        className="mb-40 md:mb-24 relative font-iranYekanRegular justify-center items-center w-full max-w-[1320px] mx-auto"
      >
        {loggedInUserInfo && projectDetails ? (
          loggedInUserInfo[0].naghsh === "sale" ||
            loggedInUserInfo[0].naghsh === "Manager" ? (
            <button
              onClick={handleEditProject}
              className="bg-yellow rounded-md text-center py-2 px-6 sticky top-28 right-[100%] z-[500]"
            >
              ویرایش
            </button>
          ) : null
        ) : null}

        <div className="mt-32">
          {projectDetails ? (
            <div className="flex justify-between">
              <button key="a1" className="item-start rounded bg-white m-4 p-2" onClick={() => {
                let nms = JSON.parse(localStorage.getItem('pdgs'));
                let ind = nms.indexOf(projectDetails[0].id1);
                if (ind === nms.length - 1) {
                  warningNotify("این قدیمی ترین پروژه است.");
                  return;
                }
                tickSeen(localStorage.getItem("lgn").split("=")[1], nms[ind + 1]);
                window.location.href = `/project-details/${parseInt(nms[ind + 1])}`;
                // navigate(`/project-details/${parseInt(projectDetails[0].id1)-1}`);
              }}>
                پروژه قبلی
              </button>
              <button key="a2" className="rounded bg-white m-4 p-2" onClick={() => {
                let nms = JSON.parse(localStorage.getItem('pdgs'));
                let ind = nms.indexOf(projectDetails[0].id1);
                if (ind === 0) {
                  warningNotify("این جدید ترین پروژه است.");
                  return;
                }
                tickSeen(localStorage.getItem("lgn").split("=")[1], nms[ind - 1]);
                window.location.href = `/project-details/${parseInt(nms[ind - 1])}`;
                // navigate(`/project-details/${parseInt(projectDetails[0].id1)+1}`);
              }}>
                پروژه بعدی
              </button>
            </div>
          ) : ''}

          {console.log(loggedInUserInfo)}
          {loggedInUserInfo ? (
            projectDetails ? (
              <>
                <CountDown role={loggedInUserInfo[0].naghsh} endDate={endDate} />
                <CountDown2 role={loggedInUserInfo[0].naghsh} endDate={garantyDate} />
              </>
            ) : null
          ) : null}
          <div className="flex justify-between">
            <h1 className="my-4 p-3">جزئیات پروژه</h1>
          </div>
          <Details
            projectDetails={projectDetails}
            imagesData={imagesData}
            handleImagePopUp={handleImagePopUp}
            imageRef={imageRef}
            setProjectDetails={setProjectDetails}
            carKind={carKind}
            setCarKind={setCarKind}
          />
          {loggedInUserInfo && projectDetails && (projectDetails[0].feval !== "اتمام پروژه") ? (
            loggedInUserInfo[0].naghsh === "installer" ||
              loggedInUserInfo[0].naghsh === "transport" ? (
              <div className="flex flex-col justify-center md:justify-start md:flex-row  rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative">
                {/* {projectDetails[1] ? ((projectDetails[1].trnsfornsbdt === 'transit') ?
                  ('') : '') : (
                  !(projectDetails[0].trnsfornsbdt === 'transit') ?
                    (<div className="ml-5">
                      <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" />
                      پذیرش پروژه
                    </div>) : '')
                }
                {!(projectDetails[0].trnsfornsbdt === 'install' && loggedInUserInfo[0].naghsh === 'installer') ?
                  (<div className="ml-5">
                    <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" />
                    پذیرش پروژه
                  </div>) : ''
                } */}

                {/*  && (projectDetails.length < 2 || projectDetails[0].trnsfornsbdt === null || (projectDetails[1] && projectDetails[1].trnsfornsbdt === null)) */}

                {(loggedInUserInfo[0].naghsh === 'installer' || loggedInUserInfo[0].naghsh === 'transport') ?
                  (
                    projectDetails.length === 1 ? (
                      (projectDetails[0].trnsfornsbdt !== 'transit' && loggedInUserInfo[0].naghsh === 'transport') || (projectDetails[0].trnsfornsbdt !== 'install' && loggedInUserInfo[0].naghsh === 'installer') ? (
                        <div className="ml-5">
                          {/* <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                          <button
                            onClick={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                            className="ml-2 bg-yellow p-2 rounded"
                          >
                            پذیرش پروژه
                          </button>
                        </div>
                      ) : ''
                    ) : projectDetails.length === 2 ? (
                      projectDetails[0].trnsfornsbdt === projectDetails[1].trnsfornsbdt && loggedInUserInfo[0].naghsh === 'installer' ? (
                        <div className="ml-5 bg-yellow">
                          {/* <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                          <button
                            onClick={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                            className="ml-2 bg-yellow p-2 rounded"
                          >
                            پذیرش پروژه
                          </button>
                        </div>
                      ) : (projectDetails[0].trnsfornsbdt !== 'install' && projectDetails[1].trnsfornsbdt !== 'install' && loggedInUserInfo[0].naghsh === 'installer') ? (
                        <div className="ml-5 bg-yellow">
                          {/* <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                          <button
                            onClick={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                            className="ml-2 bg-yellow p-2 rounded"
                          >
                            پذیرش پروژه
                          </button>
                        </div>
                      ) : (projectDetails[0].trnsfornsbdt !== 'transit' && projectDetails[1].trnsfornsbdt !== 'transit') ? (
                        <div className="ml-5">
                          {/* <input onChange={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                          <button
                            onClick={(e) => handleAcceptProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                            className="ml-2 bg-yellow p-2 rounded"
                          >
                            پذیرش پروژه
                          </button>
                        </div>
                      ) : ''
                    ) : ''
                  ) : ''
                }

                {loggedInUserInfo[0].kodemelli === projectDetails[0].trnsfornsb ? (<div className="ml-5">
                  {/* <input onChange={(e) => handleEscapeProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                  <button
                    onClick={(e) => handleEscapeProject(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                    className="ml-2 bg-yellow p-2 rounded"
                  >
                    انصراف
                  </button>

                </div>) : ''
                }

                {loggedInUserInfo[0].naghsh === "installer" ? (
                  projectDetails.length === 1 ? (
                    projectDetails[0].trnsfornsbdt !== null && projectDetails[0].trnsfornsb === loggedInUserInfo[0].kodemelli ? (
                      <div className="ml-5">
                        {/* <input onChange={(e) => handleEndOfInstallation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                        <button
                          onClick={(e) => handleEndOfInstallation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                          className="ml-2 bg-yellow p-2 rounded"
                        >
                          تائید اتمام نصب
                        </button>
                      </div>
                    ) : ''
                  ) : projectDetails.length >= 2 ? (
                    projectDetails[0].trnsfornsb === loggedInUserInfo[0].kodemelli ||
                      projectDetails[1].trnsfornsb === loggedInUserInfo[0].kodemelli ? (
                      <div className="ml-5">
                        {/* <input onChange={(e) => handleEndOfInstallation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                        <button
                          onClick={(e) => handleEndOfInstallation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                          className="ml-2 bg-yellow p-2 rounded"
                        >
                          تائید اتمام نصب
                        </button>
                      </div>
                    ) : ''
                  ) : ''

                ) : null}
                {loggedInUserInfo[0].naghsh === "transport" ? (
                  projectDetails.length === 1 ? (
                    projectDetails[0].trnsfornsbdt !== null && projectDetails[0].trnsfornsb === loggedInUserInfo[0].kodemelli ? (
                      <div className="ml-5">
                        {/* <input onChange={(e) => handleEndOfTranspotation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                        <button
                          onClick={(e) => handleEndOfTranspotation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                          className="ml-2 bg-yellow p-2 rounded"
                        >
                          تائید اتمام حمل
                        </button>
                      </div>
                    ) : ''
                  ) : projectDetails.length === 2 ? (
                    projectDetails[0].trnsfornsb === loggedInUserInfo[0].kodemelli ||
                      projectDetails[1].trnsfornsb === loggedInUserInfo[0].kodemelli ? (
                      <div className="ml-5">
                        {/* <input onChange={(e) => handleEndOfTranspotation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)} className="ml-2" type="checkbox" /> */}
                        <button
                          onClick={(e) => handleEndOfTranspotation(e, loggedInUserInfo[0].naghsh, loggedInUserInfo[0].kodemelli, projectDetails[0].id1)}
                          className="ml-2 bg-yellow p-2 rounded"
                        >
                          تائید اتمام حمل
                        </button>
                      </div>
                    ) : '') : ''
                ) : null}
              </div>
            ) : null
          ) : null}
          {projectDetails && loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh === "customer" && projectDetails[0].feval === 'تحویل به مشتری' ? (
              <div className="flex flex-col items-start-start md:items-center md:flex-row rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative">

                {projectDetails[0].feval !== 'اتمام پروژه' ? (
                  <>
                    <button
                      onClick={handleOpenSurvey}
                      className="my-2 md:my-0 rounded-md bg-white shadow-lg border border-darkBlue px-3 ml-2 py-2 text-darkBlue"
                    >
                      نظرخواهی
                    </button>
                    <button
                      onClick={
                        confirmProject
                          ? (e) => confirmProjectButtCust(e, projectDetails[0].id1)
                          : () =>
                            warningNotify("ابتدا فرم نظرخواهی را تکمیل نمائید")
                      }
                      title={
                        confirmProject
                          ? "تائید پروژه"
                          : "ابتدا فرم نظرخواهی را پر نمائید"
                      }
                      className="my-2 md:my-0 rounded-md border border-green bg-white text-green px-3 ml-2 py-2 shadow-lg"
                    >
                      تائید پروژه
                    </button>
                  </>
                ) : null}

                <button
                  className="my-2 md:my-0 rounded-md bg-white shadow-lg border border-darkBlue px-3 ml-2 py-2 text-darkBlue"
                  onClick={issueReport}
                >
                  ثبت مشکل
                </button>
              </div>
            ) : null
          ) : null}
          {showCountDown ? (
            <div className="flex items-center rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative">
              روزشمار
            </div>
          ) : null}
          {/* <div className="flex items-center rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative">
            <ToggleSwitch enabled={
              projectDetails ? (
                projectDetails[0]['customersendsms'] === '1' ? true : false
              ) : false
            } proj={projectDetails} />
            <p className="text-sm md:text-base mr-3">
              تغییر وضعیت پروژه از طریق پیامک اعلام شود
            </p>
          </div> */}
          {loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh === "sale2" ? (
              <div className=" rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5">
                <div className="flex items-center relative">
                  <button
                    onClick={() => setShowCustomerAccountForm(true)}
                    className="rounded-md w-full md:max-w-[300px] bg-darkBlue text-white px-2 py-2"
                  >
                    ایجاد حساب کاربری برای مشتری
                  </button>
                </div>
                {showCustomerAccountForm && (
                  <form onSubmit={createUser} className="py-2">
                    <input
                      name="name"
                      className="p-1 m-1 rounded-md border border-lightgray focus:outline-none"
                      placeholder="نام"
                    ></input>
                    <input
                      name="familyName"
                      className="p-1 m-1 rounded-md border border-lightgray focus:outline-none"
                      placeholder="نام خانوادگی"
                    ></input>
                    <input
                      name="ownerPhoneNumber"
                      className="p-1 m-1 rounded-md border border-lightgray focus:outline-none"
                      placeholder="شماره موبایل مالک"
                    ></input>
                    <input
                      name="ownerKodemelli"
                      className="p-1 m-1 rounded-md border border-lightgray focus:outline-none"
                      placeholder="کد ملی مالک"
                    ></input>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="bg-darkBlue rounded-md text-white px-2 py-1 m-1"
                      >
                        ارسال اطلاعات
                      </button>
                    </div>
                  </form>
                )}
              </div>
            ) : null
          ) : null}
          {
            loggedInUserInfo && projectDetails ? (
              loggedInUserInfo[0].naghsh !== "installer" ? (
                <div className="bg-lightYellow mb-5 p-5 mt-5 rounded">
                  <AddAlarm projectId={projectDetails[0].id1} id1={loggedInUserInfo[0].kodemelli} mode={true} setAlarmRefresh={setAlarmRefresh} alarmRefresh={alarmRefresh} />
                </div>) : null
            ) : null
          }
          {
            loggedInUserInfo && projectDetails ? (
              <div className="rounded-md mx-auto w-[90%] md:w-[95%] xl:w-full bg-white mt-5 mb-10 p-5 relative">
                <p>آلارم های این پروژه</p>
                <ProjectAlarms pid={projectDetails[0].id1} alarmRefresh={alarmRefresh} />
              </div>
            ) : null
          }
          {
            loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh !== "Visitor" ? (
                <Notes
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  setEndDate={setEndDate}
                  nsbDate={nsbDate}
                  setNsbDate={setNsbDate}
                />
              ) : null
            ) : null
          }
          {loggedInUserInfo && loggedInUserInfo[0].naghsh === "sale" && (
            <div className="bg-darkBlue mb-5 p-5">
              <h1 className="text-white pb-3">ارسال پیامک</h1>
              <form
                onSubmit={handleSendShortMessage}
                className="flex flex-col md:flex-row md:justify-between"
              >
                <input
                  readOnly
                  value={projectDetails ? projectDetails[0].phone : ""}
                  type="text"
                  className="rounded-md border-lightgray p-2 my-2 md:m-0 md:w-1/4"
                  placeholder="موبایل"
                  name="phone"
                />
                <input
                  type="text"
                  className="rounded-md border-lightgray p-2 my-2 md:m-0 md:w-1/4"
                  placeholder="متن پیامک"
                  name="textMSG"
                  id="متن پیام"
                />
                <LoadingButton
                  title={"ارسال"}
                  loadingBtn={msgLoading}
                  type={"submit"}
                  Tstyle={
                    "bg-white flex justify-center items-center rounded-md text-center py-2 my-2 md:m-0 md:w-1/4"
                  }
                />
              </form>
            </div>
          )}
          <div className="w-[95%] p-5 mb-5 bg-white rounded-md mx-auto md:hidden">
            <h1 className="mb-5">تصاویر پروژه</h1>
            {imagesData ? (
              imagesData.length ? (
                <div className="flex flex-wrap md:hidden  items-center">
                  {imagesData.map((img, index) => {
                    return (
                      <div
                        onClick={() => handleImagePopUp(index)}
                        key={index}
                        className="cursor-pointer border flex rounded-md justify-center border-lightgray h-[150px] m-2 w-auto p-2"
                      >
                        <img
                          alt="project"
                          className="mb-2 mx-2"
                          src={`../../nstd_site/uploads/${img.name}`}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>تصویری برای پروژه ثبت نشده‌است</p>
              )
            ) : (
              <TextLoading />
            )}
          </div>
          {loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh !== "customer" ? (
              <div className="confirms w-[95%] p-5 mb-5 bg-white rounded-md mx-auto ">
                <div className="flex flex-wrap">
                  <div className="flex justify-between items-center ml-5 my-2">
                    {projectDetails && loggedInUserInfo &&
                      loggedInUserInfo[0].naghsh === "sale" ? (
                      <input
                        type="checkbox"
                        className="min-w-[16px] ml-3 w-4 h-4"
                        onClick={saleConfirm}
                        defaultChecked={projectDetails[0]['sale_confirm_value'] === '1' ? `true` : null}
                      />
                    ) : (
                      projectDetails && projectDetails[0]['sale_confirm_value'] === '1' ? (
                        <img className="w-8 ml-2" src={checkMark}></img>
                      ) : (
                        <input
                          type="checkbox"
                          className="ml-3 w-4 h-4"
                          disabled='true'
                        />
                      )

                    )}
                    <p>تائید واحد فروش (ساخت پروژه بلامانع می‌باشد)</p>
                  </div>
                  <div className="flex justify-between items-center ml-5 my-2">
                    {acccheck && loggedInUserInfo &&
                      loggedInUserInfo[0].naghsh === "accountant" ? (
                      <input
                        type="checkbox"
                        className="ml-3 w-4 h-4"
                        onChange={checkAcc}
                        defaultChecked={acccheck[0].acc_condition === '1' ? `true` : null}
                      />
                    ) : acccheck && acccheck[0].acc_condition === '1' ? (
                      <img className="w-8 ml-2" src={checkMark}></img>
                    ) : (
                      <input
                        type="checkbox"
                        className="ml-3 w-4 h-4"
                        disabled='true'
                      />
                    )}
                    <p>تائید مدیر مالی</p>
                  </div>
                  <div className="flex justify-between items-center ml-5 my-2">
                    {projectDetails ? (loggedInUserInfo ? (
                      loggedInUserInfo[0] ? (
                        loggedInUserInfo[0].naghsh === "coord" ? (
                          <input
                            type="checkbox"
                            onClick={(e) => { storageOk(projectDetails[0].id1) }}
                            className="min-w-[16px] ml-3 w-4 h-4"
                          />
                        ) : (
                          projectDetails[0]['coordinator_value'] === "1" ? (
                            <img className="w-8 ml-2" src={checkMark}></img>
                          ) : (
                            <input
                              type="checkbox"
                              className="ml-3 w-4 h-4"
                              disabled='true'
                            />
                          )
                        )) : null) : null) : null}
                    <p>تائید واحد هماهنگی (مواد اولیه موجود است)</p>
                  </div>
                </div>
                {loggedInUserInfo && loggedInUserInfo[0] ? (
                  loggedInUserInfo[0].naghsh === "qc" ? (
                    <div className="my-3 flex flex-col">
                      <h1>تایید کنترل کیفی</h1>
                      <div className="flex">
                        <div className="flex items-center ml-5">
                          <input
                            onChange={handleProjectConfirmation}
                            name="qcConfirmation"
                            type="radio"
                            className="ml-3 w-4 h-4"
                            onClick={() => confirmProjectButt(projectDetails[0].id1)}
                          />
                          <p onClick={() => confirmProjectButt(projectDetails[0].id1)}>تائید پروژه</p>
                        </div>
                        <div className="flex items-center ml-5 my-3">
                          <input
                            name="qcConfirmation"
                            onChange={handleProjectRejection}
                            type="radio"
                            className="ml-3 w-4 h-4"
                          />
                          <p>رد پروژه</p>
                        </div>
                      </div>

                      {showRejectionReason && (
                        <>
                          <div className="flex flex-col">
                            <textarea
                              name="problemtxt"
                              id=""
                              rows="5"
                              placeholder="لطفا دلیل رد شدن پروژه را وارد نمائید ..."
                              className="mb-3 rounded-md p-3 border border-lightgray w-full"
                              value={reject}
                              onChange={handleRejectionText}
                            ></textarea>
                          </div>
                          <button onClick={(e) => handleReject(e, reject, projectDetails[0].id1, loggedInUserInfo[0].kodemelli)} className="rounded-md bg-darkBlue px-4 py-1 text-white self-end">
                            ذخیره
                          </button>
                        </>
                      )}
                    </div>
                  ) : null) : (
                  <div className="flex items-center ml-5 my-2">
                    {projectDetails[0]['reject_kid'] !== null && projectDetails[0]['feval'] !== 'تولید'
                      && projectDetails[0]['feval'] !== 'هماهنگی' &&
                      projectDetails[0]['feval'] !== 'قرارداد' ? (
                      <input
                        type="checkbox"
                        className="ml-3 w-4 h-4"
                        disabled='true'
                      />
                    ) : (
                      <img className="w-8 ml-2" src={checkMark}></img>
                    )
                    }
                    <p>تائید کنترل کیفی </p>
                  </div>
                )}
              </div>
            ) : null
          ) : null}
          <div
            className={`${preview3.length ? "flex" : "hidden"
              } rounded-md bg-white flex-wrap mt-5 flex justify-center p-3 mb-5`}
          >
            {preview3.length &&
              preview3.map((image, index) => {
                return (
                  <div
                    key={index}
                    className="w-32 m-2 relative flex  justify-center items-center border border-lightgray rounded-md"
                  >
                    <img
                      alt="preview"
                      key={index}
                      className="h-auto p-4 w-20"
                      src={image.url}
                    ></img>
                    <img
                      onClick={() => handleDeletePreview3(image.name)}
                      className="absolute w-5 h-5 top-1 right-1 cursor-pointer"
                      src={xclose}
                      alt="close"
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col mb-4">
            <label className="py-4" htmlFor="">
              عکس‌های پروژه
            </label>
            <div className="bg-white py-4 rounded-md flex justify-center items-center">
              <input
                ref={uploadInputRef}
                className="my-5 bg-white text-center"
                onChange={handleFiles3}
                type="file"
                accept="image/*"
              />
            </div>
            <LoadingButton
              title={"آپلود عکس‌ها"}
              Tstyle={
                "mt-4 py-2 text-center bg-yellow flex justify-center items-center rounded-md"
              }
              action={uploadImages}
              type={"button"}
              loadingBtn={upLoading}
            />
          </div>

          <div className="flex flex-col items-center md:items-start md:flex-row pb-5 md:just border-y-4 border-y-white">
            <div className="flex flex-col md:w-[48%] md:ml-[1%] w-[95%] mx-auto mt-5">
              {location ? (
                <MapContainer
                  style={{ height: "50vh", width: "100%", zIndex: "0" }}
                  center={location}
                  zoom={13}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    icon={
                      new Icon({
                        iconUrl: markerIconPng,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                      })
                    }
                    position={location}
                  ></Marker>
                </MapContainer>
              ) : (
                <p className="text-center">لوکیشن پروژه مشخص نشده است</p>
              )}
              {location &&
                loggedInUserInfo &&
                (loggedInUserInfo[0].naghsh === "installer" || loggedInUserInfo[0].naghsh === "transport"
                  || loggedInUserInfo[0].naghsh === "Manager"
                ) ? (
                <button
                  onClick={() => openGoogleMap(location)}
                  className="rounded-md self-end bg-darkBlue text-white px-3 py-1 my-2"
                >
                  مسیریاب
                </button>
              ) : null}
            </div>
            {
              loggedInUserInfo ? (
                loggedInUserInfo[0].naghsh !== "Visitor" &&
                  loggedInUserInfo[0].naghsh !== "customer" &&
                  loggedInUserInfo[0].naghsh !== "installer"
                  ? (
                    <div className="w-[95%] mx-auto md:w-[48%] md:mr-[1%] md:mt-5">
                      <h1 className="p-3">پیامک‌ها</h1>
                      <table className="w-full border-solid border-2 border-sky-500 rounded-md">
                        <thead>
                          <div
                            onClick={handleOpenMessage}>
                            <img
                              className={`w-8 h-8 ${openMessages && "rotate-180"
                                } w-4 h-4 transition-transform`}
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          <tr className="bg-lightYellow">
                            <td className="py-2 text-center">ردیف</td>
                            <td className="py-2 text-center">نام و نام‌خانوادگی</td>
                            <td className="py-2 text-center">موبایل</td>
                            <td className="py-2 text-center">تاریخ</td>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {allSMS ? (
                            allSMS.length ? (
                              allSMS.map((msg, index) => {
                                return (
                                  <>
                                    {
                                      index < 2 ? (
                                        <>
                                          <tr className="bg-white" key={index}>
                                            <td className="py-2 text-center">{msg.id1}</td>
                                            <td className="py-2 text-center">
                                              {projectDetails[0].family}
                                            </td>
                                            <td className="py-2 text-center">{msg.phone}</td>
                                            <td className="py-2 text-center flex flex-col">
                                              <p>
                                                {gregorian_to_jalali(
                                                  Number(msg._date.split(" ")[0].split("-")[0]),
                                                  Number(msg._date.split(" ")[0].split("-")[1]),
                                                  Number(msg._date.split(" ")[0].split("-")[2])
                                                ).join("-")}
                                              </p>
                                              <span>{msg._date.split(" ")[1]}</span>
                                            </td>
                                          </tr>
                                          <tr className="bg-gray" key={'a' + index}>
                                            <span className="py-2 text-center w-full">{msg._text}</span>
                                          </tr>
                                        </>
                                      ) : (
                                        openMessages ? (
                                          <>
                                            <tr className="bg-white" key={index}>
                                              <td className="py-2 text-center">{msg.id1}</td>
                                              <td className="py-2 text-center">
                                                {projectDetails[0].family}
                                              </td>
                                              <td className="py-2 text-center">{msg.phone}</td>
                                              <td className="py-2 text-center flex flex-col">
                                                <p>
                                                  {gregorian_to_jalali(
                                                    Number(msg._date.split(" ")[0].split("-")[0]),
                                                    Number(msg._date.split(" ")[0].split("-")[1]),
                                                    Number(msg._date.split(" ")[0].split("-")[2])
                                                  ).join("-")}
                                                </p>
                                                <span>{msg._date.split(" ")[1]}</span>
                                              </td>
                                            </tr>
                                            <tr className="bg-gray" key={'a' + index}>
                                              <span className="py-2 text-center w-full">{msg._text}</span>
                                            </tr>
                                          </>
                                        ) : null
                                      )
                                    }
                                  </>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={4} className="bg-white text-center py-2">
                                  پیامی برای نمایش وجود ندارد
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr className="bg-white w-full rounded-md mx-auto">
                              <td colSpan={4}>
                                <TextLoading />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : null
              ) : null
            }
          </div>
          <div className="md:w-full">
            <div className="mb-5 flex flex-col">
              <h1 className="p-3">سفارشات</h1>
              <div className="flex flex-wrap w-[95%] xl:w-full mx-auto justify-center lg:justify-start">
                {order ? (
                  order.length ? (
                    order.map((ord, index) => {
                      return (
                        <div
                          key={index}
                          className="flex relative flex-col md:ml-5 mb-2"
                        >
                          <div
                            ref={target}
                            className="flex h-16 items-center rounded-md bg-white"
                          >
                            <img
                              className="h-full rounded-md"
                              // src="/images/product.png"
                              src={ord ? (
                                ord.name.split('q')[0] === 'پنجره تک‌حالته' ? ("/images/1.jpg") :
                                  ord.name.split('q')[0] === 'پنجره دوحالته' ? ("/images/2.jpg") :
                                    ord.name.split('q')[0] === 'پنجره فرانسوی' ? ("/images/3.jpg") :
                                      ord.name.split('q')[0] === 'پنجره فرانسوی دوحالته' ? ("/images/4.jpg") :
                                        ord.name.split('q')[0] === 'پنجره کلنگی' ? ("/images/5.jpg") :
                                          ord.name.split('q')[0] === 'پنجره فولکس واگنی' ? ("/images/6.jpg") :
                                            ord.name.split('q')[0] === 'پنجره درب تک حالته' ? ("/images/7.jpg") :
                                              ord.name.split('q')[0] === 'درب فرانسوی' ? ("/images/8.jpg") :
                                                ord.name.split('q')[0] === 'درب فرانسوی دوحالته' ? ("/images/9.jpg") :
                                                  ord.name.split('q')[0] === 'کشویی' ? ("/images/10.jpg") :
                                                    ord.name.split('q')[0] === 'محوری' ? ("/images/11.jpg") :
                                                      ord.name.split('q')[0] === 'درب دوحالته' ? ("/images/12.jpg") :
                                                        ord.name.split('q')[0] === 'آکاردئونی' ? ("/images/13.jpg") : ''

                              ) : ('/images/product.png')}
                              alt="window"
                            />
                            <div
                              onClick={() => handleOpenSub(index)}
                              className="cursor-pointer p-3 w-[300px] h-full flex items-center justify-between"
                            >
                              <p>{ord && ord.name}</p>
                              {/* <img
                                className={` ${currentInx === index && "rotate-180"
                                  } w-4 h-4 transition-transform`}
                                src={arrow}
                                alt="arrow"
                              /> */}
                            </div>
                          </div>
                          <div
                            className="h-40 max-h-fit flex  transition-all overflow-hidden duration-500 my-3 text-sm flex-col self-end w-[300px] bg-white rounded-md"
                          >
                            <div className="flex w-full">
                              <div className="flex mt-2 flex-col items-center w-1/2">
                                <label className="p-1 w-[80%]" htmlFor="">
                                  عرض
                                </label>
                                <span className="p-2 w-[80%] rounded-md bg-darkBlue text-white">
                                  {ord.width}
                                </span>
                              </div>
                              <div className="flex my-2 flex-col items-center w-1/2">
                                <label className="p-1 w-[80%]" htmlFor="">
                                  ارتفاع
                                </label>
                                <span className="p-2 w-[80%] rounded-md bg-darkBlue text-white">
                                  {ord.height}
                                </span>
                              </div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex mb-2 flex-col items-center w-1/2">
                                <label className="p-1 w-[80%]" htmlFor="">
                                  تعداد
                                </label>
                                <span className="p-2 w-[80%] rounded-md bg-darkBlue text-white">
                                  {ord.quantity}
                                </span>
                              </div>
                              <div className="flex mb-2 flex-col items-center w-1/2">
                                <label className="p-1 w-[80%]" htmlFor="">
                                  کد
                                </label>
                                <span className="p-2 w-[80%] rounded-md bg-darkBlue text-white">
                                  {ord.code}
                                </span>
                              </div>
                            </div>
                          </div>
                          <img className="h-40 max-h-fit flex transition-all overflow-hidden duration-500 my-3 text-sm flex-col self-end w-[300px] bg-white rounded-md" src={ord && '../../nstd_site/uploads2/' + ord.name + '.png'}></img>
                        </div>
                      );
                    })
                  ) : (
                    <p>سفارشی جهت نمایش وجود ندارد</p>
                  )
                ) : (
                  <div className="bg-white w-full rounded-md mx-auto">
                    <TextLoading />
                  </div>
                )}
              </div>
            </div>
          </div>
          {loggedInUserInfo ? (
            loggedInUserInfo[0].naghsh !== "transport" &&
              loggedInUserInfo[0].naghsh !== "Visitor" ? (
              <div className="border-t-4 border-t-white">
                <h1 className="p-3">مدارک پروژه</h1>
                <div className="flex flex-wrap flex-col md:flex-row justify-center">
                  {projectDocs ? (
                    projectDocs.length ? (
                      projectDocs.map((image, index) => {
                        return (
                          <div
                            className=" bg-white mx-auto min-w-[90%] max-w-[90%] md:w-[300px] md:min-w-[300px] mb-5 md:mx-5 p-3 rounded-md flex relative"
                            key={index}
                          >
                            {image.name.split(".")[1] && (image.name.split(".")[1].toLowerCase() ===
                              "jpeg" ||
                              image.name.split(".")[1].toLowerCase() === "jpg" ||
                              image.name.split(".")[1].toLowerCase() === "png" ||
                              image.name.split(".")[1].toLowerCase() === "tif" ||
                              image.name.split(".")[1].toLowerCase() === "tiff" ||
                              image.name.split(".")[1].toLowerCase() === "psd" ||
                              image.name.split(".")[1].toLowerCase() === "webp" ||
                              image.name.split(".")[1].toLowerCase() === "eps") ? (
                              <img
                                onClick={() => handleDocPopUp(image.name)}
                                className="h-20 ml-3 w-auto cursor-pointer"
                                src={`../../nstd_site/documents/${image.name}`}
                                alt="doc"
                              />
                            ) : (
                              <a
                                target="_blank"
                                href={`../../nstd_site/documents/${image.name}`}
                                className="p-2 border border-lightgray rounded-md bg-gray bg-opacity-60 flex flex-col justify-center items-center"
                              >
                                {image.name.split(".")[1].toLowerCase() ===
                                  "pdf" ? (
                                  <img
                                    className="h-auto w-8 mb-2"
                                    src={pdf}
                                    alt="doc"
                                  />
                                ) : (
                                  <img
                                    className="h-auto w-10 mb-2"
                                    src={doc}
                                    alt="doc"
                                  />
                                )}
                                <div className="w-[100px] text-center text-ellipsis overflow-hidden text-xs">
                                  {image.name}
                                </div>
                              </a>
                            )}
                            {image.verify === "no" ? (
                              loggedInUserInfo ? (
                                loggedInUserInfo[0].naghsh === "sale" ||
                                  loggedInUserInfo[0].naghsh === "Manager" ? (
                                  <LoadingButton
                                    loadingBtn={verifyLoading[index]}
                                    action={() => approveDoc(image.name, index)}
                                    type="button"
                                    title="تائید مدرک"
                                    Tstyle="min-w-[75px] cursor-pointer min-h-[28px] flex justify-center items-center bg-darkBlue absolute left-8 bottom-3 rounded-md py-1 text-xs px-2 text-white"
                                  />
                                ) : null
                              ) : null
                            ) : (
                              <span className="bg-green items-center flex min-w-[75px] min-h-[28px] absolute left-8 bottom-3 rounded-md py-1 text-xs px-2 text-white">
                                تائید شده
                              </span>
                            )}
                            {image.verify === "no" && (
                              <img
                                onClick={() => handleDocDelete(image.name)}
                                className="absolute left-2 bottom-3 cursor-pointer"
                                src={del}
                                alt="delete"
                              />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <p className="mx-auto w-[95%] xl:m-0 xl:w-full bg-white rounded-md p-3 text-center">
                        مدرکی برای نمایش وجود ندارد
                      </p>
                    )
                  ) : (
                    <div className="bg-white w-[95%] rounded-md mx-auto">
                      <TextLoading />
                    </div>
                  )}
                </div>
                {loggedInUserInfo &&
                  (loggedInUserInfo[0].naghsh === "Manager" ||
                    loggedInUserInfo[0].naghsh === "sale" ||
                    loggedInUserInfo[0].naghsh === "accountant" ||
                    loggedInUserInfo[0].naghsh === "customer") ? (
                  <div className="border-t-4 border-t-white">
                    <div>مدارک مالی پروژه</div>
                    <div className="flex flex-wrap flex-col md:flex-row justify-center">
                      {
                        projectDocs2 ? (
                          projectDocs2.length ? (
                            projectDocs2.map((image, index) => {
                              return (
                                <div
                                  className=" bg-white mx-auto min-w-[90%] max-w-[90%] md:w-[300px] md:min-w-[300px] mb-5 md:mx-5 p-3 rounded-md flex relative"
                                  key={index}
                                >
                                  {image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() ===
                                    "jpeg" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "jpg" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "png" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "tif" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "tiff" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "psd" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "webp" ||
                                    image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() === "eps" ? (
                                    <img
                                      onClick={() => handleDocPopUp(image.name)}
                                      className="h-20 ml-3 w-auto cursor-pointer"
                                      src={`../../nstd_site/documents/${image.name.substring(0, image.name.indexOf("***&&&"))}`}
                                      alt="doc"
                                    />
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`../../nstd_site/documents/${image.name.substring(0, image.name.indexOf("***&&&"))}`}
                                      className="p-2 border border-lightgray rounded-md bg-gray bg-opacity-60 flex flex-col justify-center items-center"
                                    >
                                      {image.name.substring(0, image.name.indexOf("***&&&")).split(".")[1].toLowerCase() ===
                                        "pdf" ? (
                                        <img
                                          className="h-auto w-8 mb-2"
                                          src={pdf}
                                          alt="doc"
                                        />
                                      ) : (
                                        <img
                                          className="h-auto w-10 mb-2"
                                          src={doc}
                                          alt="doc"
                                        />
                                      )}
                                      <div className="w-[100px] text-center text-ellipsis overflow-hidden text-xs">
                                        {image.name.substring(0, image.name.indexOf("***&&&"))}
                                      </div>
                                    </a>
                                  )}
                                  {image.verify === "no" ? (
                                    loggedInUserInfo ? (
                                      loggedInUserInfo[0].naghsh === "sale" ||
                                        loggedInUserInfo[0].naghsh === "Manager" ? (
                                        <LoadingButton
                                          loadingBtn={verifyLoading[index]}
                                          action={() => approveDoc(image.name, index)}
                                          type="button"
                                          title="تائید مدرک"
                                          Tstyle="min-w-[75px] cursor-pointer min-h-[28px] flex justify-center items-center bg-darkBlue absolute left-8 bottom-3 rounded-md py-1 text-xs px-2 text-white"
                                        />
                                      ) : null
                                    ) : null
                                  ) : (
                                    <span className="bg-green items-center flex min-w-[75px] min-h-[28px] absolute left-8 bottom-3 rounded-md py-1 text-xs px-2 text-white">
                                      تائید شده
                                    </span>
                                  )}
                                  {image.verify === "no" && (
                                    <img
                                      onClick={() => handleDocDelete(image.name)}
                                      className="absolute left-2 bottom-3 cursor-pointer"
                                      src={del}
                                      alt="delete"
                                    />
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <p className="mx-auto w-[95%] xl:m-0 xl:w-full bg-white rounded-md p-3 text-center">
                              مدرکی برای نمایش وجود ندارد
                            </p>
                          )
                        ) : (
                          <div className="bg-white w-[95%] rounded-md mx-auto">
                            <TextLoading />
                          </div>
                        )
                      }
                    </div>
                  </div>

                ) : null
                }
              </div>
            ) : null
          ) : null}
          {
            loggedInUserInfo ? (
              loggedInUserInfo[0].naghsh !== "Visitor" &&
                loggedInUserInfo[0].naghsh !== "installer"
                ? (
                  <div className="flex flex-col w-[95%] mx-[2.5%] xl:m-0 xl:w-full">
                    <h1 className="p-3">آپلود مدارک</h1>
                    <div className="bg-white flex items-center justify-center">
                      <input
                        ref={uploadInputRef}
                        className="my-5 bg-white text-center"
                        multiple
                        onChange={handleFiles}
                        type="file"
                      />
                    </div>
                    <div
                      className={`${preview.length ? "flex" : "hidden"
                        } rounded-md bg-white flex flex-wrap justify-center p-3`}
                    >
                      {preview.length &&
                        preview.map((image, index) => {
                          return (
                            <div
                              key={index}
                              className="w-32 max-h-[150px] m-2 relative flex justify-center items-center border border-lightgray rounded-md"
                            >
                              {image.type.startsWith("image") ? (
                                <img
                                  alt={image.name}
                                  key={index}
                                  className="h-auto p-4 w-20"
                                  src={image.url}
                                ></img>
                              ) : (
                                <div className="p-2 w-full flex flex-col justify-center items-center">
                                  <img className="h-auto w-10" src={doc} alt="doc" />
                                  <div className="w-[100px] text-center text-ellipsis overflow-hidden text-xs">
                                    {image.name}
                                  </div>
                                </div>
                              )}
                              <img
                                onClick={() => handleDeletePreview(image.name)}
                                className="absolute w-5 h-5 top-1 right-1 cursor-pointer"
                                src={xclose}
                                alt="close"
                              />
                            </div>
                          );
                        })}
                    </div>
                    <LoadingButton
                      title={"آپلود مدارک"}
                      action={uploadDoc}
                      type={"button"}
                      loadingBtn={docLoading}
                      Tstyle={
                        "bg-darkBlue flex justify-center items-center rounded-md text-white my-5 py-2"
                      }
                    />
                  </div>
                ) : null
            ) : null
          }
          {/* start */}
          {loggedInUserInfo &&
            (loggedInUserInfo[0].naghsh === "Manager" ||
              loggedInUserInfo[0].naghsh === "sale" ||
              loggedInUserInfo[0].naghsh === "accountant" ||
              loggedInUserInfo[0].naghsh === "customer") ?
            (<div className="flex flex-col w-[95%] mx-[2.5%] xl:m-0 xl:w-full">
              <h1 className="p-3">آپلود مدارک مالی</h1>
              <div className="bg-white flex items-center justify-center">
                <input
                  ref={uploadInputRef2}
                  className="my-5 bg-white text-center"
                  multiple
                  onChange={handleFiles2}
                  type="file"
                />
              </div>
              <div
                className={`${preview2.length ? "flex" : "hidden"
                  } rounded-md bg-white flex flex-wrap justify-center p-3`}
              >
                {preview2.length &&
                  preview2.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className="w-32 max-h-[150px] m-2 relative flex justify-center items-center border border-lightgray rounded-md"
                      >
                        {image.type.startsWith("image") ? (
                          <img
                            alt={image.name}
                            key={index}
                            className="h-auto p-4 w-20"
                            src={image.url}
                          ></img>
                        ) : (
                          <div className="p-2 w-full flex flex-col justify-center items-center">
                            <img className="h-auto w-10" src={doc} alt="doc" />
                            <div className="w-[100px] text-center text-ellipsis overflow-hidden text-xs">
                              {image.name}
                            </div>
                          </div>
                        )}
                        <img
                          onClick={() => handleDeletePreview(image.name)}
                          className="absolute w-5 h-5 top-1 right-1 cursor-pointer"
                          src={xclose}
                          alt="close"
                        />
                      </div>
                    );
                  })}
              </div>
              <LoadingButton
                title={"آپلود مدارک مالی"}
                action={uploadDoc2}
                type={"button"}
                loadingBtn={docLoading}
                Tstyle={
                  "bg-darkBlue flex justify-center items-center rounded-md text-white my-5 py-2"
                }
              />
            </div>) : null}
          {/* end */}
        </div>
        <ScrollButton />
      </div>

      {openModal && (
        <Modal
          title={"حذف مدارک"}
          description={"آیا از حذف این مدرک اطمینان دارید؟"}
          deleteAction={deleteDoc}
          setOpenModal={setOpenModal}
        />
      )}
      <ImagePopUp
        content={
          <div className="relative w-full flex justify-center items-center px-5 md:px-20">
            {imagesData && imagesData.length && (
              <>
                <img
                  onClick={() => handleRight("img")}
                  className={`${hideRight && "hidden"
                    } absolute cursor-pointer right-2 sm:right-5 lg:right-10 top-[50%] w-5 h-5 lg:w-8 lg:h-8 -rotate-90`}
                  src={left}
                  alt="right"
                />
                <img
                  onClick={() => handleLeft("img")}
                  className={`${hideLeft && "hidden"
                    } absolute left-2 sm:left-5 lg:left-10 top-[50%] w-5 h-5 lg:w-8 lg:h-8 rotate-90 cursor-pointer`}
                  src={left}
                  alt="left"
                />
              </>
            )}
            <div
              onScroll={listenScrollEventImages}
              ref={slideRef}
              className="h-full flex scrollbar overflow-auto mt-0 min-w-full mx-5 snap-x snap-mandatory scroll-smooth w-full"
            >
              {imagesData ? (
                imagesData.length ? (
                  imagesData.map((img, index) => {
                    return (
                      <div
                        ref={sizeRef}
                        key={index}
                        className="bg-white px-10 snap-start min-w-full flex justify-center items-center"
                      >
                        <img
                          className="max-w-[200px] w-[200px] sm:max-w-[300px] sm:w-[300px] md:max-w-[400px] md:w-[400px] flex-grow-0 flex-shrink-0 overflow-clip mx-5"
                          src={`../../nstd_site/uploads/${img.name}`}
                        />
                      </div>
                    );
                  })
                ) : (
                  <TextLoading />
                )
              ) : (
                <p className="text-center">تصویری برای پروژه ثبت نشده‌است</p>
              )}
            </div>
          </div>
        }
        setShowPopUp={handleCloseImagePopUp}
        showPopUp={showImagePopUp}
        title={""}
      />
      <ImagePopUp
        content={
          <div className="relative w-full flex justify-center items-center px-5 md:px-20">
            <img
              onClick={() => handleRight("doc")}
              className={`${hideRight && "hidden"
                } absolute cursor-pointer right-2 sm:right-5 lg:right-10 top-[50%] w-5 h-5 lg:w-8 lg:h-8 -rotate-90`}
              src={left}
              alt="right"
            />
            <img
              onClick={() => handleLeft("doc")}
              className={`${hideLeft && "hidden"
                } absolute left-2 sm:left-5 lg:left-10 top-[50%] w-5 h-5 lg:w-8 lg:h-8 rotate-90 cursor-pointer`}
              src={left}
              alt="left"
            />
            <div
              onScroll={listenScrollEvent}
              ref={docRef}
              className="h-full flex scrollbar overflow-auto mt-0 min-w-full mx-5 snap-x snap-mandatory scroll-smooth w-full"
            >
              {projectDocs ? (
                projectDocs.length ? (
                  projectDocs
                    .filter(
                      (doc) =>
                        doc.name.split(".")[1].toLowerCase() === "jpeg" ||
                        doc.name.split(".")[1].toLowerCase() === "jpg" ||
                        doc.name.split(".")[1].toLowerCase() === "png" ||
                        doc.name.split(".")[1].toLowerCase() === "tif" ||
                        doc.name.split(".")[1].toLowerCase() === "tiff" ||
                        doc.name.split(".")[1].toLowerCase() === "psd" ||
                        doc.name.split(".")[1].toLowerCase() === "webp" ||
                        doc.name.split(".")[1].toLowerCase() === "eps"
                    )
                    .map((doc, index) => {
                      return (
                        <div
                          ref={docsizeRef}
                          key={index}
                          className="bg-white px-10 snap-start min-w-full flex justify-center items-center"
                        >
                          {
                            <img
                              className="max-w-[200px] w-[200px] sm:max-w-[300px] sm:w-[300px] md:max-w-[400px] md:w-[400px] lg:max-w-[600px] lg:w-[600px] flex-grow-0 flex-shrink-0 overflow-clip mx-5"
                              src={`../../nstd_site/documents/${doc.name}`}
                            />
                          }
                        </div>
                      );
                    })
                ) : (
                  <p className="text-center">مدرکی برای پروژه ثبت نشده‌است</p>
                )
              ) : (
                <div className="w-3/4">
                  <TextLoading />
                </div>
              )}
            </div>
          </div>
        }
        title={""}
        showPopUp={showDocsPopUp}
        setShowPopUp={handleCloseDocPopUp}
      />
      <PopUp
        content={
          <>
            <form onSubmit={handleSurveyCommit} className="m-5 flex flex-col">
              {/* <p>تست</p>
              <input type="radio" name="afk" value="v1"></input>
              <input type="radio" name="afk" value="v2"></input> */}
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <td className="py-2 px-3">بلی</td>
                    <td className="py-2 px-3">خیر</td>
                  </tr>
                </thead>
                <tbody>
                  {survey.map((q, i) => {
                    return (
                      <tr className="border-b border-b-gray " key={i}>
                        <td className="pl-5 py-2">{q.text}</td>
                        <td className="px-3">
                          <input type="radio" name={'r' + i} value={q.text + "بله"}></input>
                        </td>
                        <td className="px-3">
                          <input type="radio" name={'r' + i} value={q.text + "خیر"}></input>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <li className="my-2">
                لطفا نظرات و پیشنهادات خود را بیان فرمائید :
              </li>
              <textarea
                className="rounded-md border border-gray my-2"
                name="mtn"
                id=""
                cols="30"
                rows="5"
              ></textarea>
              <button type="submit" className="bg-darkBlue self-end my-4 rounded-md py-1 px-5 text-white">
                ارسال
              </button>
            </form>
          </>
        }
        setShowPopUp={setOpenSurvey}
        showPopUp={openSurvey}
        title={"لطفا فرم نظرخواهی را تکمیل نمائید"}
      />
      <PopUp
        content={
          <>
            {projectDetails ? (projectDetails[0] ? (projectDetails[0].trnsfornsbdt && projectDetails[0].trnsfornsb ? (
              projectDetails[0].trnsfornsbdt === 'install' ? (
                <form onSubmit={(e) => { sendIssueReport(e, projectDetails[0].trnsfornsb) }} className="px-5 md:px-10 w-full">
                  <textarea
                    className="rounded-md border p-2 border-gray mb-2 w-full"
                    name="issue"
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                  <div className="flex justify-end">
                    <LoadingButton
                      loadingBtn={loadingBtn}
                      type={"submit"}
                      title={"ارسال"}
                      Tstyle={
                        "bg-darkBlue flex justify-center self-end rounded-md py-1 min-w-[150px] text-white"
                      }
                    />
                  </div>
                </form>
              ) : projectDetails.length > 1 && projectDetails[1].trnsfornsbdt === 'install' ? (
                <form onSubmit={(e) => sendIssueReport(e, projectDetails[1].trnsfornsb)} className="px-5 md:px-10 w-full">
                  <textarea
                    className="rounded-md border p-2 border-gray mb-2 w-full"
                    name="issue"
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                  <div className="flex justify-end">
                    <LoadingButton
                      loadingBtn={loadingBtn}
                      type={"submit"}
                      title={"ارسال"}
                      Tstyle={
                        "bg-darkBlue flex justify-center self-end rounded-md py-1 min-w-[150px] text-white"
                      }
                    />
                  </div>
                </form>
              ) : ''
            ) : '') : null) : null}

          </>
        }
        setShowPopUp={setOpenIssueReport}
        showPopUp={openIssueReport}
        title={"ثبت مشکل"}
      />
    </>
  );
};

export default ProjectDetails;
