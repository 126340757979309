import React, { useEffect, useState } from "react";
import Select from "react-select";
import LoadingButton from "./General/LoadingButton";
import { successNotify, warningNotify } from "./General/notifications";
import { dateChange } from "./dateConvertor";

const Discount = () => {
  const [loadingBtn, setLoadingBtn] = useState({ search: false, save: false });
  const [customer, setCustomer] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [customerDiscount, setCustomerDiscount] = useState(null);
  const [optionsTwo, setOptionsTwo] = useState([
    { label: "سپیده خودکاوندی", value: "سپیده خودکاوندی", id: 100 },
    { label: "حسین کارگر", value: "حسین کارگر", id: 101 },
    { label: "علی شمالی", value: "علی شمالی", id: 102 },
    { label: "رضا عبدالملکی", value: "رضا عبدالملکی", id: 103 },
  ]);
  const LOCAL_STORAGE_AUTH_KEY = 'lgn';
  const getDiscounts = async ()=>{
    try {
      const response = await fetch(
        `./nstd_site/apigtdscs.php`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data1 = await response.json();
      setCustomerDiscount(data1);
    } catch (error) {
      console.log(error);
    }    
  }
  useEffect(()=>{ 
    getDiscounts();
  }, [])
  useEffect(() => {
    const getCustomerDetails = async () => {
      let data;
      if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) {
        data = {
          id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
          id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
        };
      }
      try {
        const response = await fetch(
          `./nstd_site/usapgt.php`,
          {
            method: "POST",
            cash: 'no-store',
            body: JSON.stringify(data),
          }
        );
        let data1 = await response.json();
        console.log(data1, 'data of users in grading');
        let d2 = [];
        data1.map((d1, index) => {
          if (d1.naghsh === 'customer') {
            d2.push({
              label: d1.name,
              value: d1.kodemelli,
              id: d1.kodemelli,
            });
          }
        });
        setOptionsTwo(d2);
      } catch (error) {
        console.log(error);
      }
    };
    getCustomerDetails();
  }, []);
  const serachCustomer = (e) => {
    if (e) {
      if (e.value) {
        setCustomer(e);
      } else {
        setCustomer({
          label: "سپیده خودکاوندی",
          value: "سپیده خودکاوندی",
          id: 100,
        });
      }
    } else {
      setCustomer(null);
    }
  };
  const handleCustomerDiscount = (e) => {
    if (e) {
      if (e.value) {
        // console.log(e, 'customer data selected');
        setDiscount(e);
      } else {
        setDiscount(0);
      }
    } else {
      setCustomer(null);
    }
  }
  const saveDiscount = async () => {
    // alert(customer.id + " " + discount.value);
    try {
      const response = await fetch(
        `./nstd_site/apisvdsct.php?cd=${customer.id}&ds=${discount.value}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      // alert(response.body);
      let resssp = await response.json();
      if (resssp) {
        successNotify("با موفقیت ثبت شد.");
        getDiscounts();
        setLoadingBtn((prev) => ({ ...prev, save: false }));
      }
      console.log(resssp, "save discount");
    } catch (error) {
      console.log(error);
      warningNotify("مشکل در ثبت اطلاعات");
    }
    setLoadingBtn((prev) => ({ ...prev, save: true }));
    setTimeout(() => {
      setLoadingBtn((prev) => ({ ...prev, save: false }));
    }, [2000]);
  };

  // const optionsTwo = [
  //   { label: "سپیده خودکاوندی", value: "سپیده خودکاوندی", id: 100 },
  //   { label: "حسین کارگر", value: "حسین کارگر", id: 101 },
  //   { label: "علی شمالی", value: "علی شمالی", id: 102 },
  //   { label: "رضا عبدالملکی", value: "رضا عبدالملکی", id: 103 },
  // ];

  const optionsThree = [{ label: "10%", value: "10", id: 99 },
  { label: "20%", value: "20", id: 100 },
  { label: "30%", value: "30", id: 101 },
  { label: "40%", value: "40", id: 102 },
  { label: "50%", value: "50", id: 103 },
  { label: "60%", value: "60", id: 104 },
  { label: "70%", value: "70", id: 105 },
  { label: "80%", value: "80", id: 106 },
  { label: "90%", value: "90", id: 107 },
  ];
  return (
    <div className="my-32 w-full max-w-[1320px] mx-auto font-iranYekanRegular">
      <h1 className="m-5">اعمال تخفیف برای مشتریان</h1>
      <div className="m-5">
        <div className="flex">
          <input
            placeholder="شماره تماس مشتری ..."
            type="number"
            name=""
            id=""
            className="min-w-[300px] focus:outline-none rounded-md px-2 py-1"
          />
          <LoadingButton
            Tstyle={
              "bg-darkBlue text-white rounded-md px-4 mr-3 py-2 flex justify-center items-center min-w-[170px]"
            }
            loadingBtn={loadingBtn.search}
            title={"جستجو"}
            type={"button"}
            action={serachCustomer}
          />
        </div>

        <div className="flex bg-white rounded-md p-5 mt-10 items-center">
          <Select
            className="ml-5 min-w-[202px] text-sm"
            onChange={serachCustomer}
            isClearable
            name="customerList"
            options={optionsTwo}
            placeholder="انتخاب مشتری..."
            value={customer ? customer : ""}
          />
          <Select
            className="ml-5 min-w-[202px] text-sm"
            isClearable
            name="discount"
            onChange={handleCustomerDiscount}
            options={optionsThree}
            placeholder="میزان تخفیف ..."
          />
          <LoadingButton
            Tstyle={
              "bg-darkBlue min-w-[160px] flex mr-5 justify-center items-center py-2 px-3 text-white rounded-md"
            }
            action={saveDiscount}
            loadingBtn={loadingBtn.save}
            title={"ذخیره"}
          />
        </div>
        {
          customerDiscount ? (
            <div className="flex bg-white rounded-md p-5 mt-10 items-center">
              {
                customerDiscount.map((discount, index) => {
                  return(
                    <div key={index} className="bg-yellow rounded overflow-hidden shadow-md m-4 p-2">
                      <p className="font-bold">نام مشتری: {discount.name}</p>
                      <p className="font-bold">مقدار تخفیف ثبت شده: {discount.discount}%</p>
                      <p className="text-gray-400">تاریخ ثبت: {dateChange(discount.date)}</p><span>{discount.date && discount.date.split(" ")[1]}</span>
                    </div>
                  )
                })
              }
            </div>
          ) : ''
        }

      </div>
    </div>
  );
};

export default Discount;
