import React from 'react';
import Avatar from './Avatar';

const ChatMessage = ({message}) => {
  const isSentByCurrentUser = message.sender.id === localStorage.getItem('lgn').split('=')[1]; // Assuming the current user's ID is 1
  // console.log(isSentByCurrentUser, 'is sent or not');
  return (
    <div className={`flex mb-3 ${!isSentByCurrentUser ? "flex-row-reverse" : ""}`}>
      {isSentByCurrentUser && <Avatar src={message.sender.avatar} size="small" className="mr-2"/>}
      <div className={`mr-1 p-3 rounded-lg max-w-xs break-all ${!isSentByCurrentUser ? "bg-blue text-black" : "bg-gray text-black"}`}>
        <p className="text-xs text-gray-400 mb-2 bg-yellow rounded text-center">فرستنده: {message.sender.name}</p>
        {/* {
          localStorage.getItem('lgn').split("&")[0]==="1111111111111"?(
            <p className="text-xs text-gray-400 mb-2 bg-yellow rounded text-center">گیرنده: {message.sender.name}</p>
          ):null
        } */}
        {
          message.content.includes('.png') ||message.content.includes('.jpeg')?(
            <img src={`../../nstd_site/upload4/${message.content}`}></img>
          ):null
        }
        <p className="text-sm">{message.content}</p>
        <p className="text-xs text-gray-400 mt-1">{new Date(message.timestamp).toLocaleString()}</p>
      </div>
      {!isSentByCurrentUser && <Avatar src={message.sender.avatar} size="small" className="ml-2"/>}
    </div>
  );
}

export default ChatMessage;