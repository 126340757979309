import React from "react";
import { useState } from "react";
import LoadingButton from "./LoadingButton";

const Modal2 = ({ title, description, deleteAction, setOpenModal }) => {
  const [loading, setloading] = useState(false);
  const handleDelete = () => {
    setloading(true);
    deleteAction();
  };

  const handleCancel = () => {
    setloading(false);
    setOpenModal(false);
  };
  return (
    <div className="bg-black bg-opacity-25 w-full min-h-full fixed flex justify-center items-center z-[200000] inset-0">
      <div className="bg-black text-white rounded-lg md:w-[448px] md:mx-auto p-4 mb-4 mx-4 ">
        <div className="md:flex items-center">
          <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-right">
            <p className="font-bold">{title}</p>
            {
              deleteAction ? (
                <p className="text-sm text-white mt-1">{description}</p>
              ):(
                <p className="text-sm font-bold justify-center text-center text-white mt-1">{description}</p>
              )
            }

          </div>
        </div>
        <div className="text-center md:text-right mt-4 md:flex md:justify-start">
          {
            deleteAction ? (
              <button
                type="button"
                onClick={handleDelete}
                className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-lightOrange text-graytext rounded-lg font-semibold text-sm md:ml-2 md:order-2"
              > {loading ? <svg
                className="animate-spin ml-2 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg> : "حذف"}

              </button>
            ) : (
              ''
            )
          }

          <button
            type="button"
            onClick={handleCancel}
            className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-lightgray rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1 md:ml-3"
          >
            بستن
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
