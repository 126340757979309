import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Alarms from "./Alarms/Alarms";
import { useRole } from "./context/roleProvider";
import { dateChange } from "./dateConvertor";
import { useNavigate } from "react-router-dom";
import Modal from "./General/Modal";
import ChatList from "./gram/ChatList";
import Chat from "./gram/Chat";
import UserList from "./gram/UserList";
import newThing from './icons/icons8_create_document.svg';
import backArrow from './icons/icons8_right.svg';
import PopUp from "./General/PopUp";
import { grpChnlOptions } from "./General/Config";
import { successNotify, warningNotify } from "./General/notifications";
import LoadingButton from "./General/LoadingButton";



const Messages = () => {
  const { loggedInUserInfo } = useRole();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState({
    alarms: true,
    chat: false,
    tickets: false,
  });
  const [openTicket, setOpenTicket] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [myMessages, setMyMessages] = useState(null);
  const [text, setText] = useState(null);
  const optionsTwo = [
    { label: "سپیده خودکاوندی", value: "سپیده خودکاوندی", id: 100 },
    { label: "حسین کارگر", value: "حسین کارگر", id: 101 },
    { label: "علی شمالی", value: "علی شمالی", id: 102 },
    { label: "رضا عبدالملکی", value: "رضا عبدالملکی", id: 103 },
  ];
  const [optionsTwo2, setOptionsTwo2] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState({ search: false, save: false });
  const [loadingBtn2, setLoadingBtn2] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [loadedTickets, setloadedTickets] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [textToShow, setTextToShow] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [chatOfUser, setChatOfUser] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [grpChnl, setGrpChnl] = useState(null);
  const [showAllUser, setShowAllUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [subGroups, setSubGroups] = useState(null);
  const [checkedSubGroups, setCheckedSubGroups] = useState([]);
  const [grpName, setGrpName] = useState(null);

  const user = {
    id: 1,
    name: "John Doe",
    avatar: "https://via.placeholder.com/150x150",
  }
  const LOCAL_STORAGE_AUTH_KEY = "lgn";
  let userNationalCode = localStorage
    .getItem(LOCAL_STORAGE_AUTH_KEY)
    .split("=")[1];
  let data = {
    id: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("&")[0],
    id1: localStorage.getItem(LOCAL_STORAGE_AUTH_KEY).split("=")[1],
  };
  useEffect(() => {
    if (grpChnl) {
      setShowAllUser(true);
    } else {
      setShowAllUser(false);
    }
  }, [grpChnl])
  const getSub = async (nationalCode) => {
    try {
      const response = await fetch(
        `./nstd_site/apigtrel.php?code=${nationalCode}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setCheckedSubGroups(
            data[0].sub
              .split(",")
              .splice(
                0,
                data[0].sub.split(",").length - 1,
                data[0].sub.split(",").length
              )
          );
        }
        else {
          return
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getSubGroups = async () => {
      try {
        const response = await fetch(
          `./nstd_site/apigtrel.php?code=${userNationalCode}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        if (response.ok) {
          let data = await response.json();
          console.log(data, "subgroups data");
          setSubGroups(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getUsers = async () => {
      try {
        const response = await fetch(
          "./nstd_site/usapgt.php",
          {
            method: "POST",
            cash: 'no-store',
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          let userData = await response.json();
          console.log(userData, "userData");
          setUsers(userData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
    getSubGroups();
  }, []);
  useEffect(() => {
    let newArray = Array();
    myMessages && myMessages.map((message, index) => {
      let thisUser = localStorage.getItem('lgn').split('=')[1];
      let sender1 = userSelected && userSelected.id;
      if (message.sendercode === sender1 && message.receivercode === thisUser) {
        newArray.push({
          id: message.id1,
          sender: {
            id: message.sendercode,
            name: message.sender,
            avatar: "https://via.placeholder.com/150x150",
          },
          timestamp: message.date,
          type: 'text',
          content: message.text,
        })
      } else if (message.sendercode === thisUser && message.receivercode === sender1) {
        newArray.push({
          id: message.id1,
          sender: {
            id: message.sendercode,
            name: message.sender,
            avatar: "https://via.placeholder.com/150x150",
          },
          timestamp: message.date,
          type: 'text',
          content: message.text,
        })
      }
      ;
    })

    setChatOfUser({
      id: userSelected && userSelected.id,
      name: userSelected && userSelected.name,
      description: '',
      avatar: "https://via.placeholder.com/150x150",
      messages: newArray.reverse(),
    });
  }, [userSelected])
  const chat = {
    id: 1,
    name: "Example Chat Room",
    description: "This is a sample chat room",
    avatar: "https://via.placeholder.com/150x150",
    messages: [
      {
        id: 1,
        sender: {
          id: 2,
          name: "Jane Smith",
          avatar: "https://via.placeholder.com/150x150",
        },
        timestamp: Date.now() - 1000 * 60 * 10,
        type: 'text',
        content: 'Hello!',
      },
      {
        id: 2,
        sender: {
          id: 1,
          name: "John Doe",
          avatar: "https://via.placeholder.com/150x150",
        },
        timestamp: Date.now() - 1000 * 60 * 5,
        type: 'text',
        content: 'Hi there!',
      },
    ]
  }

  useEffect(() => {
    const getTickets = async () => {
      try {
        const response = await fetch(
          `./nstd_site/apigttkt.php?id=${localStorage.getItem('lgn').split("=")[1]}`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let data = await response.json();
        setloadedTickets(data);
      } catch (error) {
        console.log(error);
      }
    }
    getTickets();
  }, [])
  const getMessages = async () => {
    try {
      console.log('the second place');
      const response = await fetch(
        `./nstd_site/apigtmsg.php?id=${localStorage.getItem('lgn').split("=")[1]}&rl=${localStorage.getItem('lgn').split("&")[0]}`,
        {
          method: "GET",
          cash: 'no-store',
          
        }
      );
      let data = await response.json();
      setMyMessages(data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getMessages();
  }, [])
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await fetch(
          `./nstd_site/apigtusch.php`,
          {
            method: "GET",
            cash: 'no-store',
          }
        );
        let data = await response.json();
        let arr = Array();
        data.map((datum, index) => {
          let newVal = '';
          if (datum.label.split("*")[1] === 'installer') {
            newVal = '(نصاب)';
          }
          else if (datum.label.split("*")[1] === 'transport') {
            newVal = '(مسئول حمل)';
          }
          else if (datum.label.split("*")[1] === 'Visitor') {
            newVal = '(ویزیتور)';
          }
          else if (datum.label.split("*")[1] === 'accountant') {
            newVal = '(مالی)';
          }
          else if (datum.label.split("*")[1] === 'sale') {
            newVal = '(مدیر فروش)';
          }
          else if (datum.label.split("*")[1] === 'qc') {
            newVal = '(کنترل کیفی)';
          }
          else if (datum.label.split("*")[1] === 'product_manager') {
            newVal = '(مدیر تولید)';
          }
          else if (datum.label.split("*")[1] === 'coord') {
            newVal = '(هماهنگی)';
          }
          else if (datum.label.split("*")[1] === 'Manager') {
            newVal = '(مدیر)';
          }
          arr.push({
            'label': datum.label.split("*")[0] + " " + newVal,
            'value': datum.value,
            'id': data.id,
          }
          )
        })
        setOptionsTwo2(arr.filter((item) => item.value !== localStorage.getItem('lgn').split('=')[1]));

      } catch (error) {
        console.log(error);
      }
    }
    getUsers();
  }, [])
  const serachCustomer = (e) => {
    if (e) {
      if (e.value) {
        setCustomer(e);
      } else {
        setCustomer({
          label: "سپیده خودکاوندی",
          value: "سپیده خودکاوندی",
          id: 100,
        });
      }
    } else {
      setCustomer(null);
    }
  };
  const changeText = (e) => {
    if (e) {
      setText(e.currentTarget.value);
    } else {
      setCustomer(null);
    }
  };
  const sendChat = () => {
    if (customer.value != null && text != null) {
      const saveChat = async () => {
        try {
          const response = await fetch(
            `./nstd_site/apisvcht.php?tr=${customer.value}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
            {
              method: "GET",
              cash: 'no-store',
            }
          );
          setNewMessage(!newMessage);
          let data = await response.text();
          successNotify('پیام با موفقیت ارسال گردید.');
          setText(null);
          setCustomer(null);
          getMessages();
          setUserSelected(null);
          setChatOfUser(null);
          console.log(data, 'response of the save chat');
        } catch (error) {
          console.log(error);
        }
      }
      saveChat();
    }
  }
  const sendChat2 = () => {
    console.log(userSelected);
    console.log(text);
    if (userSelected.id != null && text != null) {
      const saveChat = async () => {
        try {
          const response = await fetch(
            `./nstd_site/apisvcht.php?tr=${userSelected.id}&ms=${text}&snd=${localStorage.getItem('lgn').split("=")[1]}`,
            {
              method: "GET",
              cash: 'no-store',
            }
          );
          setNewMessage(!newMessage);
          let data = await response.text();
          successNotify('پیام با موفقیت ارسال گردید.');
          setText(null);
          setCustomer(null);
          getMessages();
          setRefresh(!refresh);
          console.log(data, 'response of the save chat');
        } catch (error) {
          console.log(error);
        }
        console.log('sendChat executed');
      }
      saveChat();
    } else {
      console.log('sendChat not executed');
    }
  }
  const handleGrpChnlChange = (evt) => {
    setGrpChnl(evt);
    evt.preventDefault();
  };
  const addSubGroup = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    if (grpName === null || grpName === '') {
      warningNotify('لطفا نام گروه را وارد کنید.');
      return;
    }
    const formData = new FormData(e.currentTarget);
    let data = [];
    users.forEach((user) => {
      if (formData.get(user.kodemelli) !== null) data.push(user.kodemelli);
    });
    let sub = data.join(",");
    if (sub === '') {
      alert(sub);
    }
    try {
      const response = await fetch(
        `./nstd_site/apisvgrp.php?head=${grpChnl.value}&sub=${sub}&cr=${localStorage.getItem('lgn').split("=")[1]}&nm=${grpName}`, {
          method: "GET",
          cash: 'no-store',
        }
      );
      if (response.ok) {
        successNotify("با موفقیت اعمال شد");
        setShowAllUser(false);
        setGrpChnl(null);
      } else {
        warningNotify("لطفا دوباره تلاش کنید");
      }
      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
      warningNotify("لطفا دوباره تلاش کنید");
      setLoadingBtn(false);
    }
  };
  const grpNameHandler = (e) => {
    setGrpName(e.currentTarget.value);
  }
  return (
    <div className="mt-32 p-5 bg-white">
      <h1>پیام‌ها</h1>
      <div>
        <div className="flex mt-5 text-sm md:text-base">
          <div
            onClick={() =>
              setTabs({ alarms: true, chat: false, tickets: false })
            }
            className={`${tabs.alarms ? "bg-darkBlue text-white border-t-darkBlue" : ""
              } py-2 border-t transition-colors cursor-pointer border-x border-lightgray rounded-tr-md px-2 min-w-[70px] md:min-w-[100px] text-center hover:border-t-darkBlue hover:bg-darkBlue hover:text-white`}
          >
            آلارم‌ها
          </div>
          <div
            onClick={() =>
              setTabs({ alarms: false, chat: true, tickets: false })
            }
            className={`${tabs.chat ? "bg-darkBlue text-white border-t-darkBlue" : ""
              } py-2 border-t transition-colors cursor-pointer border-l border-lightgray px-2 min-w-[70px] md:min-w-[100px] text-center hover:bg-darkBlue hover:border-t-darkBlue hover:text-white`}
          >
            چت
          </div>
          <div
            onClick={() =>
              setTabs({ alarms: false, chat: false, tickets: true })
            }
            className={`${tabs.tickets ? "bg-darkBlue text-white border-t-darkBlue" : ""
              } py-2 border-l transition-colors cursor-pointer border-t hover:border-t-darkBlue hover:bg-darkBlue hover:text-white border-lightgray px-2 min-w-[70px] md:min-w-[100px] text-center rounded-tl-md`}
          >
            تیکت‌ها
          </div>
        </div>
        {tabs.tickets && loadedTickets ? (
          <div className="py-5 border rounded-tl-md rounded-b-md border-lightgray flex justify-center">
            <table className="hidden md:inline-table w-[85%]">
              <thead>
                <tr className="bg-darkBlue text-white py-2 rounded-t-md">
                  <td className="text-center p-2 rounded-tr-md">شماره تیکت</td>
                  <td className="text-center p-2">عنوان تیکت</td>
                  <td className="text-center p-2">متن تیکت</td>
                  <td className="text-center p-2 rounded-tl-md">تاریخ ارسال</td>
                </tr>
              </thead>
              <tbody className="bg-white">
                {loadedTickets.length ? (
                  loadedTickets.map((ticket, index) => {
                    return (
                      <tr key={ticket.id1} className="cursor-pointer" onClick={() => {
                        navigate(`/ticket-detail/${ticket.id1}`);
                      }}>
                        <td className="p-2 text-center">{ticket.id1}</td>
                        <td className="p-2 text-center">{ticket.subject}</td>
                        <td className="p-2 text-center">{ticket.text}</td>
                        <td className="p-2 text-center">{ticket.date}</td>
                      </tr>
                    );
                  })
                ) : ''}
                <tr className="">
                  <td className="p-2 text-center"></td>
                  <td className="p-2 text-center"></td>
                  <td className="p-2 text-center"></td>
                </tr>
              </tbody>
            </table>
            <div className="md:hidden text-sm md:text-base flex flex-col w-[90%] mb-40">
              {
                loadedTickets.length ? (
                  loadedTickets.map((ticket, index) => {
                    return (
                      <div className="rounded-md bg-white mb-3">
                        <div className="py-2 border-b-2 border-b-darkBlue text-right flex justify-between">
                          <p>شماره تیکت :</p>
                          <p className="text-left w-2/3">{ticket.id1}</p>
                        </div>
                        <div className="py-2 border-b-2 border-b-darkBlue text-right flex justify-between">
                          <p>عنوان تیکت :</p>
                          <p className="text-left w-2/3">{ticket.subject}</p>
                        </div>
                        <div className="py-2 border-b-2 border-b-darkBlue text-right flex justify-between">
                          <p>متن تیکت :</p>
                          <p className="text-left w-2/3">{ticket.text}</p>
                        </div>
                        <div className="py-2 border-b-2 border-b-darkBlue text-right flex justify-between">
                          <p>تاریخ ارسال :</p>
                          <div className="text-left w-2/3">{ticket.date}</div>
                        </div>
                      </div>
                    );
                  })
                ) : ''
              }
            </div>
          </div>
        ) : tabs.alarms ? (
          <Alarms />
        ) : tabs.chat ? (
          navigate("/chat")
        ) : (
          <div className="py-5 border rounded-tl-md rounded-b-md border-lightgray flex justify-center"></div>
        )}
      </div>
    </div>
  );
};
export default Messages;
