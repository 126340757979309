import React, { useEffect, useRef, useState } from "react";
import close from "../icons/close.svg";
import search from "../icons/search.svg";
import arrow from "../icons/arrow.svg";
import { warningNotify } from "../General/notifications";
import DrawingCanvas from "../DrawingCanvas/DrawingCanvas";
import Compressor from "compressorjs";
import xclose from "../icons/xclose.svg";
import Board from "../Board";
import PopUp2 from "../General/PopUp2";

const AddNewWindow = ({
  openAddNewWin,
  setOpenAddNewWin,
  setWindows,
}) => {
  const [currentIndex, setCurrentIndex] = useState(null);

  const [winProperty, setWinProperty] = useState({
    width: "",
    height: "",
    quantity: "",
    code: "",
    data: "",
  });
  const windowTypes = [
    { name: "پنجره تک‌حالته", src: "./images/1.jpg" },
    { name: "پنجره دوحالته", src: "./images/2.jpg" },
    { name: "پنجره فرانسوی", src: "./images/3.jpg" },
    { name: "پنجره فرانسوی دوحالته", src: "images/4.jpg" },
    { name: "پنجره کلنگی", src: "images/5.jpg" },
    { name: "پنجره فولکس واگنی", src: "images/6.jpg" },
    { name: "پنجره درب تک حالته", src: "images/7.jpg" },
    { name: "درب فرانسوی", src: "images/8.jpg" },
    { name: "درب فرانسوی دوحالته", src: "images/9.jpg" },
    { name: "کشویی", src: "images/10.jpg" },
    { name: "محوری", src: "images/11.jpg" },
    { name: "درب دوحالته", src: "images/12.jpg" },
    { name: "آکاردئونی", src: "images/13.jpg" },
  ];
  const [showppp, setShowppp] = useState(false);
  const uploadInputRef = useRef();
  const [files, setFiles] = useState(['']);
  const [preview, setPreview] = useState([]);
  const [deleteTrigger, setDeleteTrigger]=useState(false);
  const handleFiles = (e) => {
    setPreview([]);
    setFiles([]);

    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 900000) {
        new Compressor(e.target.files[i], {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            let blobToFile = new File(
              [compressedResult],
              compressedResult.name
            );
            setFiles((prev) => [...prev, blobToFile]);
          },
        });
      } else {
        setFiles((prev) => [...prev, e.target.files[i]]);
      }
      // setFiles((prev) => [...prev, e.target.files[i]]);
      setPreview((prev) => [
        ...prev,
        {
          url: URL.createObjectURL(e.target.files[i]),
          name: e.target.files[i].name,
        },
      ]);
    }
  };
  const handleDeletePreview = (name) => {
    // console.log(name);
    setPreview((prev) => [...prev.filter((img) => img.name !== name)]);
    setFiles((prev) => [...prev.filter((img) => img.name !== name)]);
    if (preview.length === 1) {
      uploadInputRef.current.value = "";
      //  setPreview([]);
    }
  };
  const openWinSubMenu = (index) => {
    if (currentIndex === index) {
      setCurrentIndex(null);
    } else {
      setCurrentIndex(index);
    }
  };
  useEffect(() => {
    if (showppp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showppp]);
  const openPop = (e) => {
    e.preventDefault();
    setShowppp(true);
  }
  useEffect(() => {
    setWinProperty((prev) => ({
      ...prev,
      data: files[0],
    }));
    console.log(files[0], 'the data');
  }, [files])
  const registerWindow = (e) => {
    e.preventDefault();
    console.log(e.target[2].value);
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].value.match(/^$|\s +/) && e.target[i].type !== "submit") {
        warningNotify(`لطفا ${e.target[i].id} را به درستی وارد نمایید`);
        return;
      }
    }
    console.log(winProperty, 'the data2');
    console.log(files[0], 'the file of data');
    setWindows((prev) => [...prev, winProperty]);
    e.target.reset();
    setFiles([]);
    setCurrentIndex(null);
    setDeleteTrigger(!deleteTrigger);
  };

  const inputHandleChange = (e, inputName, windowName) => {
    const value = e.target.value;
    setWinProperty((prev) => ({
      ...prev,
      [inputName]: value,
      name: windowName + 'q' + Math.random(),
    }));
  };

  const handleClickOutside = (e) => {
    if (e.target === outerTarget.current) {
      setOpenAddNewWin(false);
    }
  };
  const target = useRef();
  const outerTarget = useRef();

  return (
    <>
      <div
        ref={outerTarget}
        onClick={handleClickOutside}
        className={`inset-0 flex justify-center items-center fixed w-screen h-screen transition-transform duration-500 bg-opacity-25 bg-black z-[500] ${openAddNewWin ? " translate-y-0 " : " translate-y-[100%] "
          }`}
      >
        <div
          ref={target}
          className="bg-white rounded-md scrollbar relative overflow-auto z-[510] flex flex-col items-center w-[85%] h-3/4"
        >
          <div className="px-6 py-4 flex justify-end z-[500] fixed bg-white overflow-x-hidden overflow-y-auto w-[79%] rounded-t-md">
            <div className="w-4 h-4 fill-grayborder cursor-pointer">
              <img
                onClick={() => setOpenAddNewWin(false)}
                src={close}
                alt="close"
              />
            </div>
          </div>

          <div className="bg-white mt-20 py-2 w-[80%] flex justify-between rounded-full border border-grayborder px-2">
            <input
              className="w-full outline-none px-2 border-none"
              type="text"
              placeholder="جستجو..."
            />
            <span className="rounded-full bg-yellow flex justify-center items-center w-10 h-10">
              <img src={search} alt="search" />
            </span>
          </div>
          <div className="flex w-full flex-wrap mt-5 justify-center">
            {windowTypes.map((window, index) => {
              return (
                <div
                  key={index}
                  className={`flex shadow-md rounded-md w-44 my-8 relative flex-col items-center justify-center mx-3`}
                >
                  <img className="w-40 p-3 h-auto" src={window.src} alt="window" />
                  <div
                    onClick={() => openWinSubMenu(index)}
                    className="flex items-center cursor-pointer justify-around"
                  >
                    <p className="text-sm py-4">{window.name}</p>
                    <img
                      className={`${currentIndex === index ? "rotate-180" : ""
                        } h-3 w-3  transition-transform duration-500 mr-3`}
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                  <div
                    className={`${currentIndex === index
                      ? "h-90 max-h-fit"
                      : "h-0 border-none"
                      } duration-500 absolute border border-lightgray rounded-md top-[100%] !z-[550] w-44 transition-all ease my-1 overflow-hidden flex-col bg-white mb-1`}
                  >
                    <form onSubmit={registerWindow}>
                      <div className="flex p-2 bg-white">
                        <label className="text-graytext p-1 min-w-[50px]" htmlFor="width">
                          عرض
                        </label>
                        <input
                          onChange={(e) =>
                            inputHandleChange(e, "width", window.name)
                          }
                          className="w-full outline-none mx-2 border border-lightgray rounded-md px-1"
                          type="number"
                          name="width"
                          id="عرض"
                        />
                      </div>
                      <div className="flex rounded-md p-2 bg-white">
                        <label className="text-graytext p-1 min-w-[50px]" htmlFor="height">
                          ارتفاع
                        </label>
                        <input
                          onChange={(e) =>
                            inputHandleChange(e, "height", window.name)
                          }
                          className="w-full outline-none mx-2 border border-lightgray rounded-md px-1"
                          type="number"
                          name="height"
                          id="ارتفاع"
                        />
                      </div>
                      <div className="flex rounded-md p-2 bg-white">
                        <label className="p-1 min-w-[50px] text-graytext" htmlFor="quantity">
                          تعداد
                        </label>
                        <input
                          onChange={(e) =>
                            inputHandleChange(e, "quantity", window.name)
                          }
                          className="w-full outline-none mx-2 rounded-md px-1 border border-lightgray rounded-md"
                          type="number"
                          name="quantity"
                          id="تعداد"
                        />
                      </div>
                      <div className="flex rounded-md p-2 bg-white">
                        <label className="text-graytext min-w-[50px] p-1" htmlFor="code">
                          کد
                        </label>
                        <input
                          onChange={(e) =>
                            inputHandleChange(e, "code", window.name)
                          }
                          className="w-full outline-none mx-2 border border-lightgray rounded-md px-1"
                          type="text"
                          name="code"
                          id="کد"
                        />
                      </div>

                      <div className="flex rounded-md p-2 bg-white">
                        <label className="text-graytext min-w-[50px] p-1" htmlFor="code">
                          طرح کار
                        </label>

                        <button
                          className="bg-darkBlue w-full py-1 mx-4 rounded-md text-white "
                          onClick={openPop}
                        >
                          طراحی
                        </button>
                      </div>
                      {
                        files ? (
                          files.map((item, index) => {
                            return (
                              <div
                                key={index}
                              >
                                {/* <p>item.name{console.log(item)}</p> */}
                                <img src={item}></img>
                              </div>
                            )
                          })
                        ) : ''
                      }
                      <div className="flex justify-center items-center">
                        <button
                          type="submit"
                          className="bg-darkBlue w-full py-1 mx-4 rounded-md text-white "
                        >
                          ثبت
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* {
        showppp ? (
          <div className="z-50">
            <>
              <div className="px-5 md:px-10 w-full">
                <DrawingCanvas setFiles={setFiles} />
              </div>
            </>
          </div>
        ) : ''
      } */}
      <PopUp2
        content={
          <>
            <div >
              {/* <DrawingCanvas setFiles={setFiles} /> */}
              <Board deleteTrigger={deleteTrigger} setFiles={setFiles} setShowPopUp={setShowppp} />
            </div>
          </>
        }
        setShowPopUp={setShowppp}
        showPopUp={showppp}
        title={"لطفا طرح مورد نظر را رسم کنید:"}
      />
    </>
  );
};

export default AddNewWindow;
