import React, { useEffect, useState } from 'react';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';

const Chat = ({ chat, user, setUser, changeText, sendChat, text, setText }) => {
  const[sendNew,setSendNew]=useState(null);
  // useEffect(()=>{
    
  // }, [sendNew])
  return (
    <div className="flex flex-col justify-between h-screen">
      <ChatHeader chat={chat} user={user} setUser={setUser} />
      <ChatMessages chat={chat} userSelected={user} sendNew={sendNew} setSendNew={setSendNew}/>
      <ChatInput setText={setText} text={text} sendChat={sendChat} changeText={changeText} chat={chat} user={user} setSendNew={setSendNew} sendNew={sendNew} />
    </div>
  );
}

export default Chat;