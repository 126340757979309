import React from 'react';

function UserProfile({ name, avatar, status, idkey, setUserSelected, setOpenModal3 }) {
  // const { name, avatar, status } = props;

  return (
    <div key={idkey} className="flex items-center p-4 hover:bg-gray-100 cursor-pointer" onClick={() => {
      setOpenModal3(false);
      setUserSelected({
        id: idkey,
        name: name,
        avatar: "https://via.placeholder.com/150x150",
      })
    }}>
      <img src={avatar} alt="User avatar" className="rounded-full h-12 w-12 inline-block" />
      <div className="ml-4">
        <div key={idkey + '1'} className="text-telegramText font-bold mr-4">{name}</div>
        <div key={idkey + '2'} className="text-telegramText mr-4">{status}</div>
      </div>
    </div>
  );
}

export default UserProfile;
