import React, { createContext, useContext, useEffect, useState } from "react";

export const AuthProviderContext = createContext();

const LOCAL_STORAGE_AUTH_KEY = "lgn";
const LOCAL_STORAGE_AUTH_KEY_TWO = "lgn-name";

export const AuthProvider = ({ children }) => {
  const [userstate, setUserState] = useState(null);

  //componenetDidMount
  //This one must always be written in higher lines
  // useEffect(() => {
  //   setUserState({
  //     lgn:localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) || false,
  //     lgnName:localStorage.getItem(LOCAL_STORAGE_AUTH_KEY_TWO) || false
  //   })

  // }, []);

  //componentDidUpdate
  useEffect(() => {
    if (userstate) {
     localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(userstate.lgn));
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY_TWO, JSON.stringify(userstate.lgnName));     
    }

  }, [userstate]);

  return (
    <AuthProviderContext.Provider value={{ userstate, setUserState }}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export const useAuth = () => useContext(AuthProviderContext);
