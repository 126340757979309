import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import Loading from "./General/Loading";
import Chart from 'chart.js/auto';
import { dateChange } from "./dateConvertor";
import { CategoryScale } from 'chart.js';

const Report = () => {
    const [historyList, setHistoryList] = useState([]);
    const [date, setDate] = useState(new Date());
    const [addit, setAddit] = useState(0);

    const [chartData, setChartData] = useState(null);
    const [firstData, setFirstData] = useState(null);
    const [secData, setSecData] = useState(null);
    const [chartData1, setChartData1] = useState(null);
    const [firstData1, setFirstData1] = useState(null);
    const [secData1, setSecData1] = useState(null);
    const [chartData2, setChartData2] = useState(null);
    const [firstData2, setFirstData2] = useState(null);
    const [secData2, setSecData2] = useState(null);

    //   useEffect(() => {
    //     const getChartData = async () => {
    //       try {
    //         const response = await fetch("./nstd_site/api9.php");
    //         if (response.ok) {
    //           const data = await response.json();
    //           console.log(data);
    //           setChartData(data);
    //         }
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     };
    //     getChartData();
    //     Chart.register(CategoryScale);
    //   }, []);

    useEffect(() => {
        const getChartData = async () => {
            let separator = '-';
            let newDate = new Date();
            setDate(newDate);
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            try {
                const response = await fetch(`./nstd_site/apigtrpt.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                    method: "GET",
                    cash: 'no-store',
                  });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setChartData(data.ch1);
                    setChartData1(data.ch2);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getChartData();
        Chart.register(CategoryScale);
    }, [])
    const previousDay = async (a) => {
        let separator = '-';
        let newDate = new Date(new Date().getTime() + (a * 24 * 60 * 60 * 1000));
        setDate(newDate);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        try {
            const response = await fetch(`./nstd_site/apigtrpt.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                method: "GET",
                cash: 'no-store',
            });
            let data = await response.json();
            setChartData(data.ch1);
            setChartData1(data.ch2);
        } catch (error) {
            console.log(error);
        }
    }

    const nextDay = async (b) => {
        let separator = '-';
        let newDate = new Date(new Date().getTime() + (b * 24 * 60 * 60 * 1000));
        setDate(newDate);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        try {
            const response = await fetch(`./nstd_site/apigtrpt.php?dt=${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`, {
                method: "GET",
                cash: 'no-store',
            });
            let data = await response.json();
            setChartData(data.ch1);
            setChartData1(data.ch2);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (chartData) {
            let dataOne = chartData && chartData.map((data) => data.name);
            let dataTwo = chartData && chartData.map((data) => data.tedad);
            for (let i = 0; i < dataTwo.length; i++) {
                if (dataTwo[i] === null) {
                    dataTwo[i] = "0"

                }
            }
            setFirstData(dataOne);
            setSecData(dataTwo);

        }
    }, [chartData]);
    useEffect(() => {
        console.log(chartData1, 'chart1');
        if (chartData1) {
            let dataOne = chartData && chartData.map((data) => data.name);
            let dataTwo = chartData1 && chartData1.map((data) => data.tedad);
            for (let i = 0; i < dataTwo.length; i++) {
                if (dataTwo[i] === null) {
                    dataTwo[i] = "0"

                }
            }
            setFirstData1(dataOne);
            setSecData1(dataTwo);

        }
    }, [chartData1]);
    useEffect(() => {
        console.log(chartData2, 'chart2');
        if (chartData2) {
            let dataOne = chartData2 && chartData2.map((data) => data.name);
            let dataTwo = chartData2 && chartData2.map((data) => data.sum);
            for (let i = 0; i < dataTwo.length; i++) {
                if (dataTwo[i] === null) {
                    dataTwo[i] = "0"

                }
            }
            setFirstData2(dataOne);
            setSecData2(dataTwo);

        }
    }, [chartData2]);

    //--------------------------

    const labels = firstData && firstData;
    const data = {
        labels: labels,
        datasets: [
            {
                label: "تعداد پروژه هایی که کاربران باز کرده اند",
                data: secData && secData,
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
        ],
    };
    const config = {
        type: "line",
        data: data,
    };
    const labels1 = firstData1 && firstData1;
    const data1 = {
        labels: labels1,
        datasets: [
            {
                label: "تعداد پروژه هایی که کاربران ایجاد کرده اند",
                data: secData1 && secData1,
                fill: true,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
        ],
    };
    const config1 = {
        type: "line",
        data: data1,
    };
    const labels2 = firstData2 && firstData2;
    const data2 = {
        labels: labels2,
        datasets: [
            {
                label: "نمودار نصاب ها",
                data: secData2 && secData2,
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
        ],
    };
    const config2 = {
        type: "line",
        data: data2,
    };

    return (
        <div className="my-32 max-w-[1320px] mx-auto">
            <div className="flex justify-center">
                <button className="rounded bg-white m-4 p-2" onClick={() => {
                    let a = addit - 1;
                    setAddit(addit - 1);
                    previousDay(a);
                }}>روز قبل</button>
                {date ? (<span className="font-bold mt-6">{dateChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)}</span>) : ''}
                <button className="rounded bg-white m-4 p-2" onClick={() => {
                    let b = addit + 1;
                    setAddit(addit + 1);
                    nextDay(b);
                }}>روز بعد</button>
            </div>
            {chartData ? <Bar options={config} data={data} /> : <Loading />}
            {chartData1 ? <Bar options={config1} data={data1} /> : <Loading />}
            {/* {chartData2 ? <Bar options={config2} data={data2} /> : <Loading />} */}
        </div>
    );
};

export default Report;
