import React, { useState } from "react";
import goUp from "../icons/up.svg";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <button
      onClick={scrollToTop}
      className={`${
        visible ? "sticky" : "none"
      } w-16 h-16 bg-white shadow-lg shadow-[#6082B6] border-2 border-[#6082B6] border-opacity-0 hover:border-opacity-100 hover:shadow-md hover:translate-y-[4px] duration-300 text-[#1e88e5] flex justify-center items-center rounded-full  right-[99%] bottom-28 md:bottom-10`}
    >
      <img src={goUp} alt="up" />
    </button>
  );
};

export default ScrollButton;
