import React from 'react';
import Avatar from './Avatar';
import backArrow from '../icons/icons8_right.svg'

const ChatHeader = ({chat, user, setUser}) => {
  return (
    <div className="fixed left-px w-full flex items-center py-2 px-4 bg-white border-b border-gray-300 bg-amber-400-contrast text-white rounded">
      <img onClick={(e) => { setUser(null) }} src={backArrow} className="h-8 w-8 bg-black rounded cursor-pointer m-2"></img>
      <Avatar src={chat.avatar} size="large" className="mr-3"/>
      <div>
        <p className="text-base text-telegramText font-semibold mr-4">{user.name}</p>
        <p className="text-sm text-telegramText mr-4">{chat.description}</p>
      </div>
    </div>
  );
}

export default ChatHeader;